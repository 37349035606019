import GenericModel from './generic_model/GenericModel';
import axios from "axios";
import Config from "../config/config";
import Utils from "../helpers/utils";

export default class RewardService extends GenericModel {
  API = '/reward-reviews';
  static API = '/reward-reviews';
  
  constructor(props) {
    super(props);
  }
}