import React from "react";
import WhiteContentBox from "../../components/ContentBox/WhiteContentBox";
import {Grid, Hidden} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  container: {
    padding: '70px',
    [theme.breakpoints.down('sm')]: {
      padding: '70px 15px',
    },
  },
  sorry_title: {
    color: '#f83131',
    fontSize: 36,
    marginTop: 15,
    textAlign: 'center'
  },
  sorry_text: {
    color: '#20183a',
    fontSize: 16,
    textAlign: 'center',
    marginTop: 17
  }
}))

export default (props) => {
  
  const [t, i18n] = useTranslation('confirm-email');
  
  const classes = useStyles();
  
  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header history={props.history}/>
      </Grid>
    
      <Grid item xs={1} md={3}/>
    
      <WhiteContentBox item xs={10} md={6} style={{marginTop: 30}}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.sorry_title}>{t('email_sent')}</Grid>
          <Grid item xs={12} className={classes.sorry_text}>{t('email_sent_to_inbox')}</Grid>
        </Grid>
      </WhiteContentBox>
    
      <Grid item xs={12}>
        <Footer history={props.history}/>
      </Grid>
    </Grid>
  );
}