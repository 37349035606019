// Libraries
import React, {useState} from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import Sidebar from 'react-sidebar';
import {BsPencil} from 'react-icons/bs';
import {MdAddAPhoto} from 'react-icons/md';

// Actions (REDUX)
import {updateUser} from "../../redux/actions";

// Components
import ButtonLogin from '../Buttons/ButtonLogin';
import ButtonProfile from '../Buttons/ButtonProfile';

// Services
import UserService from "../../services/UserService";

// Images
import noPicture from '../../assets/no-picture.png';
import wildGoalsLogo from '../../assets/logo.png';
import wildGoalsText from '../../assets/wildgoals.png';
import loadingGif from '../../assets/loading-png-gif.gif';
import ButtonMenu from "../Buttons/ButtonMenu";
import ButtonRedFilledAction from "../Buttons/ButtonRedFilledAction";
import ButtonSidebar from "../Buttons/ButtonSidebar";
import {useTranslation} from "react-i18next";
import CustomSelector from "../Inputs/CustomSelector";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    padding: 56,
    margin: '100px 0px 100px 0px'
  },
  divider: {
    borderRight:'1px solid rgba(255,255,255,0.2)',
    height: '20px',
    marginTop: '18px',
  },
  smallHorizontalDivider: {
    borderBottom:'1px solid rgba(255,255,255,0.2)',
    width: '20px',
    margin: 'auto',
  },
  bigHorizontalDivider: {
    borderBottom:'1px solid rgba(255,255,255,0.2)',
    width: '100%',
    marginBottom: 20
  },
  profilePictureContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    height: '70px',
    width: '70px',
    textAlign:'center',
    margin: 'auto'
  },
  profilePictureMobile: {
    flex: 2,
    overflow: "hidden",
    position: 'relative'
  },
  photoOverlayMobile: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 70,
    height: 70,
    borderRadius: 35,
    backgroundColor: "transparent",
    color: "transparent",
    zIndex: 3,
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.4)',
      color: "white"
    },
    '&.changing': {
      backgroundColor: 'rgba(0,0,0,0.4)',
      color: "white"
    }
  },
  photoMobile: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 'auto',
    width: 70,
    height: 70,
    borderRadius: 35,
    border: 'none'
  },
  invisibleFileInput: {
    opacity: 0.0,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height:'100%',
    zIndex: 1000,
    cursor: "pointer"
  },
  profilePictureContainerPC: {
    display: 'flex',
    flexDirection: 'column',
    height: '130px',
    width: '130px',
    textAlign:'center'
  },
  profilePicturePC: {
    flex: 2,
    overflow: "hidden",
    position: 'relative'
  },
  photoOverlayPC: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 130,
    height: 130,
    borderRadius: 65,
    color: "transparent",
    backgroundColor: "transparent",
    zIndex: 3,
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.4)',
      color: "white"
    },
    '&.changing': {
      backgroundColor: 'rgba(0,0,0,0.4)',
      color: "white"
    }
  },
  photoPC: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 'auto',
    width: 130,
    height: 130,
    borderRadius: 65,
    border: 'none'
  },
  noPhotoMobile: {
    marginTop: 10,
    color: 'white',
    fontSize: 30
  },
  noPhotoPC: {
    marginTop: 10,
    color: 'white',
    fontSize: 60
  }
}));

const sidebarStyle = {
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden"
  },
  sidebar: {
    zIndex: 2000,
    position: "absolute",
    top: 0,
    bottom: 0,
    transition: "transform .3s ease-out",
    WebkitTransition: "-webkit-transform .3s ease-out",
    willChange: "transform",
    overflowY: "auto",
    backgroundColor: '#463871',
    minWidth: '50%'
  },
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    transition: "left .3s ease-out, right .3s ease-out"
  },
  overlay: {
    zIndex: 1000,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    visibility: "hidden",
    transition: "opacity .3s ease-out, visibility .3s ease-out",
    backgroundColor: "rgba(0,0,0,.6)"
  },
  dragHandle: {
    zIndex: 1,
    position: "fixed",
    top: 0,
    bottom: 0
  }
};

const Header = (props) => {
  
  const [t, i18n] =  useTranslation('header');
  
  const languageOptions = [
    {language: 'en-US', language_name: 'EN'},
    {language: 'pt-BR', language_name: 'PT'}
  ];
  const [chosenLanguage, setChosenLanguage] = useState('');
  
  if(chosenLanguage === '' && i18n.language) {
    setTimeout(() => {
      setChosenLanguage(languageOptions.find(obj => obj.language === i18n.language));
    }, 10)
  }
  
  const classes = useStyles();
  
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
  const [lastUser, setLastUser] = useState(undefined);
  const [lastHref, setLastHref] = useState('');
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  
  const [isProjectActive, setIsProjectActive] = useState(false);
  const [isRewardActive, setIsRewardActive] = useState(false);
  const [isAboutUsActive, setIsAboutUsActive] = useState(false);
  
  const [sidebarOpen, setSidebarOpen] = useState(false);

  let initials = '';
  
  if((!props.user || !props.user.id) && !lastUser) {
    props.updateUser();
  } else {
    let name = props.user.user_full_name;
    if(name) {
      initials = name.match(/\b\w/g) || [];
      initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
      initials = initials.substring(0,2);
    }
  }
  
  const redirect = (address) => {
    console.log('redirecting');
    props.history.push(`/${address}`);
  };
  
  console.log(window.location.href);
  
  // Redirects user to the correct page
  if (!UserService.isUserLoggedIn() &&
    !(window.location.href.indexOf('login') >= 0) &&
    !(window.location.href.indexOf('about-us') >= 0) &&
    !(window.location.href.indexOf('signup') >= 0) &&
    !(window.location.href.indexOf('confirm-email') >= 0) &&
    !(window.location.href.indexOf('forgot-password') >= 0) &&
    !(window.location.href.indexOf('reset-password') >= 0) &&
    !(window.location.href.split('/').pop() === '')) {
    redirect('');
  } else if (UserService.isUserLoggedIn() && props.user.id) {
    if(!props.user.has_donated &&
      !(window.location.href.indexOf('about-us') >= 0) &&
      !(window.location.href.indexOf('time-over') >= 0) &&
      !(window.location.href.indexOf('first-donation') >= 0)
    ) {
      redirect('first-donation');
    } else if (UserService.isUserLoggedIn() &&
      props.user.has_donated && !props.user.has_indicated &&
      !(window.location.href.indexOf('about-us') >= 0) &&
      !(window.location.href.indexOf('time-over') >= 0) &&
      !(window.location.href.indexOf('first-indication') >= 0) &&
      !(window.location.href.indexOf('send-indication') >= 0)
    ) {
      redirect('first-indication');
    } else if (UserService.isUserLoggedIn() &&
      props.user.has_donated && props.user.has_indicated &&
      (window.location.href.indexOf('first-donation') >= 0 ||
        window.location.href.indexOf('first-indication') >= 0 ||
        window.location.href.indexOf('send-indication') >= 0 ||
        window.location.href.indexOf('signup') >= 0 ||
        window.location.href.indexOf('confirm-email') >= 0 ||
        window.location.href.indexOf('login') >= 0 ||
        window.location.href.indexOf('forgot-password') >= 0 ||
        window.location.href.indexOf('reset-password') >= 0 ||
        window.location.href.split('/').pop() === '')
    ) {
      redirect('projects');
    }
  }
  
  // Show/Hide Buttons
  if(lastHref !== window.location.href || lastUser.id !== props.user.id) {
    setLastUser(props.user);
    setLastHref(window.location.href);
    
    if(window.location.href.indexOf('projects') >= 0) {
      setIsProjectActive(true);
      setIsRewardActive(false);
      setIsAboutUsActive(false);
    } else if (window.location.href.indexOf('rewards') >= 0) {
      setIsProjectActive(false);
      setIsRewardActive(true);
      setIsAboutUsActive(false);
    } else if (window.location.href.indexOf('about-us') >= 0) {
      setIsProjectActive(false);
      setIsRewardActive(false);
      setIsAboutUsActive(true);
    }
    
    // State of buttons
    if(!props.user.id) {
      if(window.location.href.indexOf('login') >= 0) {
        setShowLogin(false);
        setShowSignup(true);
        setShowLogout(false);
        setShowProfile(false);
        setShowMenu(false);
      } else {
        setShowLogin(true);
        setShowSignup(false);
        setShowLogout(false);
        setShowProfile(false);
        setShowMenu(false);
      }
    } else {
      setShowLogin(false);
      setShowSignup(false);
      setShowLogout(true);
      setShowMenu(true);
      
      if(
        !(window.location.href.indexOf('first-donation') >= 0) &&
        !(window.location.href.indexOf('first-indication') >= 0) &&
        !(window.location.href.indexOf('time-over') >= 0) &&
        !(window.location.href.indexOf('send-indication') >= 0)) {
        setShowProfile(true);
      } else {
        setShowProfile(false);
      }
    }
  }
  
  const logout = () => {
    UserService.Logout();
    props.updateUser();
    setLastHref(undefined);
    setLastUser(undefined);
    redirect('login');
  };
  
  const editProfile = () => {
    props.history.push('/edit-profile');
  }
  
  const changeProfileImage = async (e) => {
    setIsLoadingPhoto(true);
    const file = e.target.files[0];
    try {
      await UserService.changeProfileImage(props.user, file);
      props.updateUser();
      setIsLoadingPhoto(false);
    } catch (err) {
      console.error(err);
      setIsLoadingPhoto(false);
    }
    
  }
  
  return (
    <>
      {/*MOBILE HEADER*/}
      <Hidden smUp>
        <Grid container style={{padding: 10}}>
          <Grid item xs={4}>
            <ButtonProfile onClick={() => {redirect('profile')}} style={{
              float: 'left',
              display: (showProfile ? 'inherit' : 'none')
            }}>
              {initials}
            </ButtonProfile>
          </Grid>
          <Grid item xs={4} style={{textAlign:'center'}}>
            {
              props.isProfile ?
                <div className={classes.profilePictureContainerMobile}>
                  <div className={classes.profilePictureMobile}>
                    <div className={`${classes.photoOverlayMobile} ${isLoadingPhoto ? t('changing') : ''}`}>
                      <input type="file" onChange={changeProfileImage} className={classes.invisibleFileInput} />
                      <div style={{
                        flex: 2,
                        lineHeight: '67px'
                      }}>{isLoadingPhoto ? <img style={{margin: 'auto'}} width={30} height={30} src={loadingGif}/> : t('change')}</div>
                      <div style={{
                        flex: 1,
                        position: 'relative',
                        display: (isLoadingPhoto ? 'none' : 'block')
                      }}>
                        <BsPencil style={{
                          fontSize: '18px',
                          position:'absolute',
                          top:'50%',
                          bottom:'50%',
                          transform:'translate(-50%, -50%)'
                        }}/>
                      </div>
                    </div>
                    {props.user && props.user.profile_image && props.user.profile_image.url ?
                      <img className={classes.photoMobile}
                           src={props.user.profile_image.url}
                           alt={'logo'}/> :
                      <MdAddAPhoto className={classes.noPhotoMobile}/>
                    }
                  </div>
                  <div style={{flex: 1}}>
                    <img src={wildGoalsText} alt={'logo'} style={{objectFit: 'contain', maxHeight: '21px'}}/>
                  </div>
                </div> :
                <img src={wildGoalsLogo} alt={'logo'} style={{objectFit: 'contain', width: '100%', maxWidth:'100px', maxHeight: '70px', cursor: 'pointer'}} onClick={() => {
                  redirect('');
                }}/>
            }
          </Grid>
          <Grid item xs={4} style={{display: 'flex', flexDirection: 'row'}}>
            <Sidebar
              sidebar={<Grid container style={{textAlign: 'center'}}>
                <Grid item xs={12} style={{height: '70px'}}/>
                <Grid item xs={12} style={{display: (showMenu ? 'inherit' : 'none')}}>
                  <ButtonMenu
                    className={(isProjectActive ? 'active' : null)}
                    onClick={() => {redirect('projects')}}
                  >{t('projects')}</ButtonMenu>
                </Grid>
                <Grid className={classes.smallHorizontalDivider} style={{display: (showMenu ? 'inherit' : 'none')}}/>
                <Grid item xs={12} style={{display: (showMenu ? 'inherit' : 'none')}}>
                  <ButtonMenu
                    className={(isRewardActive ? 'active' : null)}
                    onClick={() => {redirect('rewards')}}
                  >{t('rewards')}</ButtonMenu>
                </Grid>
                <Grid className={classes.smallHorizontalDivider} style={{display: (showMenu ? 'inherit' : 'none')}}/>
                <Grid item xs={12}>
                  <ButtonMenu
                    className={(isAboutUsActive ? 'active' : null)}
                    onClick={() => {redirect('about-us')}}
                  >{t('about_us')}</ButtonMenu>
                </Grid>
                <Grid className={classes.bigHorizontalDivider}/>
                <Grid item xs={12}>
                  <ButtonLogin onClick={logout}>
                    {t('logout')}
                  </ButtonLogin>
                </Grid>
              </Grid>}
              open={sidebarOpen}
              onSetOpen={setSidebarOpen}
              pullRight={true}
              styles={{...sidebarStyle,root: {display:(sidebarOpen ? 'inherit' : 'none')}}}
            />
            <div style={{flex: 8}}/>
            <div style={{paddingLeft: 10, paddingRight: 10, display:(showLogout ? 'inherit' : 'none')}}>
              <CustomSelector
                options={languageOptions}
                id_field={'language'}
                name_field={'language_name'}
                onChange={(languageObj) => {
                  i18n.changeLanguage(languageObj.language);
                  setChosenLanguage(languageObj);
                }}
                value={chosenLanguage}
              />
            </div>
            <ButtonSidebar
              style={{display:(showLogout ? 'inherit' : 'none')}}
              onClick={() => {
                setSidebarOpen(!sidebarOpen)
              }}>
            </ButtonSidebar>
            
            <ButtonLogin onClick={() => {redirect()}} style={{
              float: 'right',
              display: (showSignup ? 'inherit' : 'none')
            }}>
              {t('sign_up')}
            </ButtonLogin>
            <ButtonLogin onClick={() => {redirect('login')}} style={{
              float: 'right',
              display: (showLogin ? 'inherit' : 'none')
            }}>
              {t('login')}
            </ButtonLogin>
          </Grid>
        </Grid>
      </Hidden>

      {/*PC HEADER*/}
      <Hidden only={'xs'}>
        <Grid container style={{padding: 26}}>
          <Grid item xs={3} style={{display: 'flex', flexDirection: 'row'}}>
            {
              props.isProfile ?
                <div className={classes.profilePictureContainerPC}>
                  <div className={classes.profilePicturePC}>
                    <div className={`${classes.photoOverlayPC} ${isLoadingPhoto ? t('changing') : ''}`}>
                      <input type="file" onChange={changeProfileImage} className={classes.invisibleFileInput} />
                      <div style={{flex:1}}/>
                      <div style={{
                        flex: 1,
                        lineHeight: '130px',
                        textAlign: 'center'
                      }}>{isLoadingPhoto ? <img style={{margin: 'auto'}} width={50} height={50} src={loadingGif}/> : t('change')}</div>
                      <div style={{
                        flex: 1,
                        position: 'relative',
                        display: (isLoadingPhoto ? 'none' : 'block')
                      }}>
                        <BsPencil style={{
                          fontSize: '18px',
                          position:'absolute',
                          top:'50%',
                          bottom:'50%',
                          transform:'translate(-50%, -50%)'
                        }}/>
                      </div>
                      <div style={{flex:1}}/>
                    </div>
                    {props.user && props.user.profile_image && props.user.profile_image.url ?
                      <img className={classes.photoPC}
                           src={props.user.profile_image.url}
                           alt={'logo'}/> :
                      <MdAddAPhoto className={classes.noPhotoPC}/>
                    }
                  </div>
                  <div style={{flex: 1}}>
                    <img src={wildGoalsText} alt={'logo'} style={{objectFit: 'contain', maxHeight: '40px'}}/>
                  </div>
                </div> :
                <img src={wildGoalsLogo} alt={'logo'} style={{cursor: 'pointer'}} onClick={() => {
                  redirect('');
                }}/>
            }
          </Grid>
          <Grid item xs={9} style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{flex: 8}}></div>
            <div style={{flex: 2, display: (showMenu ? 'inherit' : 'none')}}>
              <ButtonMenu
                className={(isProjectActive ? 'active' : null)}
                onClick={() => {redirect('projects')}}
              >{t('projects')}</ButtonMenu>
            </div>
            <div className={classes.divider} style={{display: (showMenu ? 'inherit' : 'none')}}/>
            <div style={{flex: 2, display: (showMenu ? 'inherit' : 'none')}}>
              <ButtonMenu
                className={(isRewardActive ? 'active' : null)}
                onClick={() => {redirect('rewards')}}
              >{t('rewards')}</ButtonMenu>
            </div>
            <div className={classes.divider} style={{display: (showMenu ? 'inherit' : 'none')}}/>
            <div style={{flex: 2}}>
              <ButtonMenu
                className={(isAboutUsActive ? 'active' : null)}
                onClick={() => {redirect('about-us')}}
              >{t('about_us')}</ButtonMenu>
            </div>
            <div style={{flex: 1, display: (showProfile ? 'inherit' : 'none')}}>
              <ButtonProfile onClick={() => {redirect('profile')}}>
                {initials}
              </ButtonProfile>
            </div>
            <div>
              <CustomSelector
                options={languageOptions}
                id_field={'language'}
                name_field={'language_name'}
                onChange={(languageObj) => {
                  i18n.changeLanguage(languageObj.language);
                  setChosenLanguage(languageObj);
                }}
                value={chosenLanguage}
              />
            </div>
            <div style={{flex: 2, display: (showSignup ? 'inherit' : 'none')}}>
              <ButtonLogin onClick={() => {redirect()}}>{t('sign_up')}</ButtonLogin>
            </div>
            <div style={{flex: 2, display: (showLogin ? 'inherit' : 'none')}}>
              <ButtonLogin onClick={() => {redirect('login')}}>{t('login')}</ButtonLogin>
            </div>
            <div style={{flex: 2, display: (showLogout ? 'inherit' : 'none')}}>
              <ButtonLogin onClick={logout}>
                {t('logout')}
              </ButtonLogin>
            </div>
          </Grid>
        </Grid>
      </Hidden>
    </>
  )
}

export default connect(
  state => {
    return state.user
  },
  { updateUser }
)(Header);
