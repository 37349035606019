import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(theme => ({
  root: {
    color: '#857d9c',
    borderRadius: 2,
    backgroundColor: 'none',
    textTransform: 'none',
    padding: '14px 5px',
    fontSize: 12,
    height: '56px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(133,125,156,0.2)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'none',
      },
    },
    '&.active': {
      backgroundColor: 'rgba(70,56,113,0.5)',
      color: '#d5c0ff'
    }
  }
}))(Button);