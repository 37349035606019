import React, {useEffect, useState} from "react";
import {ButtonBack, ButtonNext, CarouselProvider, DotGroup, Slide, Slider} from "pure-react-carousel";
import ButtonCarousel from "../Buttons/ButtonCarousel";

//Images
import carouselLb from '../../assets/carousel_la.png';
import carouselRb from '../../assets/carousel_ra.png';

// Hook
function useWindowSize() {
  const isClient = typeof window === 'object';
  
  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }
  
  const [windowSize, setWindowSize] = useState(getSize);
  
  useEffect(() => {
    if (!isClient) {
      return false;
    }
    
    function handleResize() {
      setWindowSize(getSize());
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount
  
  return windowSize;
}

export default (props) => {
  const totalSlides = props.children ? props.children.length : 0;
  
  const {width} = useWindowSize();
  
  const visibleSlides = props.forceSingleSlide ? 1 : width > 1160 ? 3 : width > 800 ? 2 : 1;
  
  const mobileButtons = width < 800;
  
  return (
    <CarouselProvider
      isPlaying={visibleSlides === 1}
      infinite={visibleSlides === 1}
      isIntrinsicHeight={true}
      naturalSlideWidth={100}
      naturalSlideHeight={150}
      totalSlides={totalSlides}
      visibleSlides={visibleSlides}
    >
      <Slider>
        {props.children ? props.children.map((obj, idx) => (
          <Slide index={idx}>
            {obj}
          </Slide>
        )) : null}
      </Slider>
      <div style={{display: (mobileButtons ? 'none' : 'flex'), width: '100%', flexDirection: 'row', marginTop: 10, position: 'relative'}}>
        <div style={{flex: 8, borderBottom: '1px solid rgba(255,255,255,0.2)', transform: 'translate(0,-50%)'}}/>
        <div><ButtonCarousel back={1}/><ButtonCarousel next={1}/></div>
        <div style={{flex: 8, borderBottom: '1px solid rgba(255,255,255,0.2)', transform: 'translate(0,-50%)'}}/>
      </div>
      <div style={{display: (mobileButtons ? 'flex' : 'none'), flexDirection: 'row', textAlign: 'center', marginTop: 5}}>
        <div style={{flex: 1}}/>
        <div style={{textAlign: 'right'}}>
          <ButtonBack className={'no-outline-on-focus'} style={{
            width: 20,
            height: 30,
            backgroundColor: 'transparent',
            backgroundImage: `url(${carouselLb})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: "contain",
            border: 'none'
          }}/>
        </div>
        <div>
          <DotGroup style={{textAlign: 'center'}}/>
        </div>
        <div style={{textAlign: 'left'}}>
          <ButtonNext className={'no-outline-on-focus'} style={{
            width: 20,
            height: 30,
            backgroundColor: 'transparent',
            backgroundImage: `url(${carouselRb})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: "contain",
            border: 'none',
            '&:focus': {
              outline: 'none'
            }
          }}/>
        </div>
        <div style={{flex: 1}}/>
      </div>
    </CarouselProvider>
  )
}