import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: 'row'
  },
  colorContainer: {
    flex: 1
  },
  colorSquare: {
    width:22,
    height: 22,
    borderRadius: 2,
    border: 'solid 2px #ffffff',
    cursor: 'pointer',
    '&.active': {
      border: 'solid 2px #463871',
    }
  }
}))

const COLORS = {
  WHITE: 'white',
  GRAY: 'gray',
  GREEN: 'green',
  DARK_BLUE: 'dark_blue',
  LIGHT_BLUE: 'light_blue',
  RED: 'red',
  PURPLE: 'purple',
  YELLOW: 'yellow',
  BROWN: 'brown',
  BLACK: 'black'
}

export default (props) => {
  
  const classes = useStyles();
  
  const chosenColor = props.chosenColor;
  const onColorChange = props.onColorChange ? props.onColorChange : () => {};
  
  return (
    <div className={classes.root}>
      <div className={classes.colorContainer} onClick={() => {
        onColorChange(COLORS.WHITE)
      }}>
        <div
          className={`${classes.colorSquare} ${(chosenColor === COLORS.WHITE ? 'active' : null)}`}
          style={{backgroundColor: '#ffffff'}}
        />
      </div>
      <div className={classes.colorContainer} onClick={() => {
        onColorChange(COLORS.GRAY)
      }}>
        <div
          className={`${classes.colorSquare} ${(chosenColor === COLORS.GRAY ? 'active' : null)}`}
          style={{backgroundColor: '#78786f'}}
        />
      </div>
      <div className={classes.colorContainer} onClick={() => {
        onColorChange(COLORS.GREEN)
      }}>
        <div
          className={`${classes.colorSquare} ${(chosenColor === COLORS.GREEN ? 'active' : null)}`}
          style={{backgroundColor: '#13be6c'}}
        />
      </div>
      <div className={classes.colorContainer} onClick={() => {
        onColorChange(COLORS.DARK_BLUE)
      }}>
        <div
          className={`${classes.colorSquare} ${(chosenColor === COLORS.DARK_BLUE ? 'active' : null)}`}
          style={{backgroundColor: '#105dd2'}}
        />
      </div>
      <div className={classes.colorContainer} onClick={() => {
        onColorChange(COLORS.LIGHT_BLUE)
      }}>
        <div
          className={`${classes.colorSquare} ${(chosenColor === COLORS.LIGHT_BLUE ? 'active' : null)}`}
          style={{backgroundColor: '#10cbf7'}}
        />
      </div>
      <div className={classes.colorContainer} onClick={() => {
        onColorChange(COLORS.RED)
      }}>
        <div
          className={`${classes.colorSquare} ${(chosenColor === COLORS.RED ? 'active' : null)}`}
          style={{backgroundColor: '#e4411d'}}
        />
      </div>
      <div className={classes.colorContainer} onClick={() => {
        onColorChange(COLORS.PURPLE)
      }}>
        <div
          className={`${classes.colorSquare} ${(chosenColor === COLORS.PURPLE ? 'active' : null)}`}
          style={{backgroundColor: '#864cfd'}}
        />
      </div>
      <div className={classes.colorContainer} onClick={() => {
        onColorChange(COLORS.YELLOW)
      }}>
        <div
          className={`${classes.colorSquare} ${(chosenColor === COLORS.YELLOW ? 'active' : null)}`}
          style={{backgroundColor: '#ff9c11'}}
        />
      </div>
      <div className={classes.colorContainer} onClick={() => {
        onColorChange(COLORS.BROWN)
      }}>
        <div
          className={`${classes.colorSquare} ${(chosenColor === COLORS.BROWN ? 'active' : null)}`}
          style={{backgroundColor: '#8b572a'}}
        />
      </div>
      <div className={classes.colorContainer} onClick={() => {
        onColorChange(COLORS.BLACK)
      }}>
        <div
          className={`${classes.colorSquare} ${(chosenColor === COLORS.BLACK ? 'active' : null)}`}
          style={{backgroundColor: '#252525'}}
        />
      </div>
    </div>
  );
}