import React from "react";

export default ({centerX, centerY, radius, progress, color, width}) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" style={{height: '100%', width: '100%'}}>
      <circle style={{
        stroke: `${color || '#ffc100'}`,
        strokeDasharray: radius*2*3.14,
        strokeDashoffset: (radius*2*3.14*(1-progress)),
        strokeLinecap: 'round',
        strokeWidth: `${width || 6}px`,
        fill: 'none'
      }} cx={centerX} cy={centerY} r={radius}/>
    </svg>
  );
}