import React from 'react';
import { Button,Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    color: 'rgba(255,255,255,0.4)',
    border: '1px solid rgba(255,255,255,0.4)',
    borderRadius: 2,
    backgroundColor: 'none',
    textTransform: 'none',
    padding: '6px 0',
    width: '100%',
    minWidth: 0,
    maxWidth: 1000,
    fontSize: 13,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
      border: '1px solid rgba(255,255,255,0.6)',
      color: 'rgba(255,255,255,0.6)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'none',
      },
    },
    '&.active': {
      border: '1px solid rgba(255,255,255,1)',
      color: 'rgba(255,255,255,1)',
    },
    '&.active:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'none',
      },
    }
  }
}))

export default (props) => {
  const classes = useStyles();
  
  return (
    <Button className={[classes.root,props.active ? 'active' : undefined]} {...props}>
      {props.children}
    </Button>
  );
}