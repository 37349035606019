import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: 5,
  },
  label: {
    color: '#FFFFFF',
    textAlign: 'left'
  },
  checkbox: {
    color: '#FFFFFF',
    textAlign: 'left'
  }
}));

export default function CheckboxCustom(props) {

  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.root}
      control={
        <Checkbox
          color="primary"
          className={classes.checkbox}
          {...props}
        />
      }
      label={<Typography className={classes.label}>{props.label}</Typography>}
    />
  )
}