import { UPDATE_USER, UPDATE_USER_ERROR } from "../actionTypes";

const initialState = {
  user: {},
  userError: undefined,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER: {
      const userObj = action.payload;
      return {
        ...state,
        user: userObj
      };
    }
    case UPDATE_USER_ERROR: {
      const err = action.error;
      return {
        ...state,
        userError: err
      };
    }
    default:
      return state;
  }
}
