import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    border: '1px solid #e6e4ed',
    textAlign: 'center',
    alignContents: 'center',
    '& > span': {
      display: 'inline-block',
      height: '100%',
      verticalAlign: 'middle'
    },
    '& > img': {
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: 'auto',
      maxWidth: '80%',
      maxHeight: '80%'
    }
  }
}))

export default function ImageContentBox(props) {
  
  const classes = useStyles();
  
  return (
    <div className={classes.root} style={{...props.style, minHeight: props.minHeight || '170px'}}>
      <span/>
      <img src={props.src}/>
    </div>
  );
}