import React from "react";
import {Grid} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Components
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import WhiteContentBox from "../../components/ContentBox/WhiteContentBox";

// Images
import openedGift from '../../assets/opened_gift.svg';
import ButtonRedAction from "../../components/Buttons/ButtonRedAction";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  container: {
    padding: '70px',
    [theme.breakpoints.down('sm')]: {
      padding: '70px 15px',
    },
  },
  opened_gift: {
    textAlign: 'center'
  },
  thanks_title: {
    color: '#20183a',
    fontSize: 36,
    marginTop: 15,
    textAlign: 'center'
  },
  thanks_text: {
    color: '#595959',
    fontSize: 16,
    textAlign: 'center',
    marginTop: 17
  },
  browse_button: {
    textAlign: 'center',
    marginTop: 30
  }
}))

export default (props) => {
  
  const [t, i18n] = useTranslation('reward-order-completed');
  
  const classes = useStyles();
  
  const browseProjects = () => {
    props.history.push('/projects');
  }
  
  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header history={props.history}/>
      </Grid>
  
      <Grid item xs={1} md={3}/>
  
      <WhiteContentBox item xs={10} md={6}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.opened_gift}>
            <img src={openedGift}/>
          </Grid>
          <Grid item xs={12} className={classes.thanks_title}>{t('thanks_title')}</Grid>
          <Grid item xs={12} className={classes.thanks_text}>{t('thanks_text')}</Grid>
          <Grid item xs={12} className={classes.browse_button}>
            <ButtonRedAction onClick={browseProjects}>
              {t('browse_projects')}
            </ButtonRedAction>
          </Grid>
        </Grid>
      </WhiteContentBox>
      
      <Grid item xs={12}>
        <Footer history={props.history}/>
      </Grid>
    </Grid>
  )
}