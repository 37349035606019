import React from "react";
import { Grid } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import WhiteContentBox from "./WhiteContentBox";
import {makeStyles} from "@material-ui/core/styles";
import {AiOutlineHeart, AiFillHeart} from "react-icons/ai";
import ImageContentBox from "./ImageContentBox";
import ButtonRedAction from "../Buttons/ButtonRedAction";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  cardContainer: {
    padding: 20
  },
  favoriteHeart: {
    position: 'absolute',
    top: '9px',
    right: '9px',
    textAlign: 'center',
    fontSize: '25px',
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    },
    '&.favorited': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  titleGrid: {
    marginTop: '4px',
    marginBottom: '20px',
    fontSize: '18px',
    color: '#503e89',
    fontWeight: 'bold'
  },
  imageGrid: {
    textAlign: 'center',
    marginBottom: '17px',
    height: '160px',
    lineHeight: '160px',
    display: 'table-cell',
    verticalAlign: 'middle',
    '& > img': {
      margin: '0 auto',
      maxWidth: '80%',
      maxHeight: '80%',
      contentFit: 'contain'
    }
  },
  pointsGrid: {
    textAlign: 'left',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#857d9c'
  },
  ratingGrid: {
    textAlign: 'right',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#857d9c'
  },
  greenSpan: {
    color: '#63a461',
    fontSize: 22
  },
  actionButtonGrid: {
    textAlign: 'center'
  },
}));

export default (props) => {
  
  const [t, i18n] = useTranslation('product-box');
  
  const classes = useStyles();
  
  const onClickReward = props.onClickReward ? () => {
    props.onClickReward(props.id_reward);
  } :  () => {};
  
  return (
    <WhiteContentBox className={classes.root} {...props}>
      <Grid container className={classes.cardContainer}>
        <Grid item xs={12} className={classes.titleGrid}>
          {props.reward_name}
        </Grid>
        <Grid item xs={12} className={classes.imageGrid}>
          <ImageContentBox src={props.reward_image} minHeight={'170px'}/>
        </Grid>
        <Grid item xs={6} className={classes.pointsGrid} style={{paddingTop: 18}}>
          <span className={classes.greenSpan}>{props.reward_points}</span> {t('points')}
        </Grid>
        <Grid item xs={6} className={classes.ratingGrid} style={{paddingTop: 18}}>
          <Rating
            value={props.reward_stars}
            precision={0.5}
            emptyIcon={<StarBorderIcon fontSize="inherit"/>}
            readOnly
          />
        </Grid>
        <Grid item xs={12} className={classes.ratingGrid} style={{fontSize: 16, color: '#20183a', paddingBottom: '25px'}}>
          <span style={{fontWeight: "bold"}}>{props.reward_bought_number}</span> {t('bought')}
        </Grid>
        <Grid item xs={12} className={classes.actionButtonGrid}>
          <ButtonRedAction disabled={props.disabled} onClick={onClickReward}>
            {props.disabled ? t('need_points') : t('purchase_now')}
          </ButtonRedAction>
        </Grid>
      </Grid>
      {
        props.onFavorite ?
          <div
            className={`${classes.favoriteHeart} ${props.is_favorite ? 'favorited' : null}`}
            onClick={(event) => {
              event.stopPropagation();
              props.onFavorite(props.id_reward);
            }}
          >
            {props.is_favorite ? <AiFillHeart/> : <AiOutlineHeart/>}
          </div> :
          null
      }
    </WhiteContentBox>
  )
}