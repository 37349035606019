import React from "react";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import WhiteRadioButton from "./WhiteRadioButton";

const useStyles = makeStyles({
  radioButton: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
  formControlLabel: {
    color: 'white'
  }
});

export default (props) => {
  
  const classes = useStyles();
  
  return (
    <FormControlLabel
      className={classes.formControlLabel}
      control={<WhiteRadioButton
        className={classes.radioButton}
        name="radio-button-contact"
        {...props}
      />}
      labelPlacement="start"
      {...props}
    />
  );
}

