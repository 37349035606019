import React, {useState} from "react";
import {Grid, Hidden} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

// REDUX
import {connect} from "react-redux";
import {updateUser} from "../../redux/actions";

// Components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import WhiteContentBox from "../../components/ContentBox/WhiteContentBox";
import RewardPointsAndStars from "../../components/Displays/RewardPointsAndStars";
import PointsBox from "../../components/Displays/PointsBox";
import ShippingTextBox from "../../components/Inputs/ShippingTextBox";

// Services
import RewardService from "../../services/RewardService";

// Images
import bulletPoint from '../../assets/bullet-point.png';
import giftRewards from "../../assets/gift_rewards.svg";
import ButtonRedFilledAction from "../../components/Buttons/ButtonRedFilledAction";
import ImageContentBox from "../../components/ContentBox/ImageContentBox";
import CustomCarousel from "../../components/Displays/CustomCarousel";
import UserEvaluationBox from "../../components/ContentBox/UserEvaluationBox";
import LoadingOverlay from "react-loading-overlay";
import RewardPurchaseService from "../../services/RewardPurchaseService";
import {useTranslation} from "react-i18next";
import Utils from "../../helpers/utils";
import ProjectService from "../../services/ProjectService";
import CustomSelector from "../../components/Inputs/CustomSelector";
import PayNowBox from "../../components/Displays/PayNowBox";
import {PayPalButton} from "react-paypal-button-v2";
import ActivityService from "../../services/ActivityService";
import RewardReviewService from "../../services/RewardReviewService";
import TextBox from "../../components/Inputs/TextBox";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import {Rating} from "@material-ui/lab";
import TextArea from "../../components/Inputs/TextArea";

const useStyles = makeStyles(theme => ({
  pageContainer: {
    position: 'relative',
    zIndex: 100,
    maxWidth: '90%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  page_title: {
    fontSize:36,
    color: 'white',
    textAlign: 'center',
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize:30
    }
  },
  reward_container_desktop: {
    padding: '39px 67px',
    [theme.breakpoints.down('sm')]: {
      padding: '39px 10px'
    },
  },
  reward_title: {
    fontSize: '36px',
    color: '#20183a',
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  reward_points_and_stars: {
    marginBottom: 27
  },
  reward_points_inquiry: {
    color: '#20183a',
    fontSize: 22,
    marginBottom: 23
  },
  reward_points_deduction: {
    marginBottom: 37
  },
  reward_shipping_title: {
    fontSize: '22px',
    color: '#20183a',
    marginBottom: 23
  },
  reward_shipping_form: {
    marginBottom: 15
  },
  reward_send_button: {
  
  },
  reward_product_image: {
  
  },
  reward_product_image_selector: {
    marginBottom: 29
  },
  reward_user_evaluation: {
  
  },
  alert: {
    color: '#e21818',
    fontWeight: 'bold'
  },
  reward_description_container: {
    color: '#595959',
    fontSize: 16,
    marginBottom: 29,
    fontWeight: 'lighter',
    '& > h2': {
      color: '#503e89',
      fontWeight: 'normal',
      fontSize: 36,
      margin: 0
    },
    '& figure': {
      margin: '10px 0 10px 0',
      position: 'relative'
    },
    '& video': {
      width: '100%'
    },
    '& strong': {
      color: '#63a461',
      fontWeight: 'bold'
    },
    '& .image-style-align-right': {
      width: '50%',
      float: 'right'
    },
    '& .image-style-align-left': {
      width: '50%',
      float: 'left'
    },
    '& img': {
      width: '100%'
    },
    '& figcaption': {
      color: 'white',
      position: 'absolute',
      bottom: '0',
      left: '0',
      padding: '5px',
      fontSize: '15px',
      backgroundColor: 'rgba(0,0,0,0.6)'
    },
    '& ul': {
      listStyleImage: `url('${bulletPoint}')`
    },
    '& li': {
      paddingLeft: '15px',
      marginBottom: '20px'
    }
  }
}))

const SingleReward = (props) => {
  
  const [t, i18n] = useTranslation('single-reward');
  
  const classes = useStyles();
  
  const {id_reward} = props.match.params;
  
  const [isLoadingActive, setIsLoadingActive] = useState(false);
  const [searchReward, setSearchReward] = useState(false);
  const [userReviewed, setUserReviewed] = useState(false);
  const [rewardObj, setRewardObj] = useState({});
  const [userComments, setUserComments] = useState([]);
  const [reward_review_text, setRewardReviewText] = useState('');
  const [reward_review_stars, setRewardReviewStars] = useState([]);
  
  const [listProjects, setListProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState({});
  const [searchProject, setSearchProject] = useState(0);
  if(!searchProject) {
    setSearchProject(1);
    ProjectService.query({is_active: 1}).then(projects => {
      setListProjects(projects);
      if(projects.length > 0) {
        setCurrentProject(projects[0]);
      }
    })
  }
  
  const [shipping_first_name, setShippingFirstName] = useState('');
  const [shipping_last_name, setShippingLastName] = useState('');
  const [shipping_address, setShippingAddress] = useState('');
  const [shipping_apartment, setShippingApartment] = useState('');
  const [shipping_country, setShippingCountry] = useState('');
  const [shipping_state, setShippingState] = useState('');
  const [shipping_city, setShippingCity] = useState('');
  const [shipping_zip, setShippingZip] = useState('');
  
  const [idxImage, setIdxImage] = useState(0);
  
  const loadReward = (id_reward) => {
    RewardService.get(id_reward).then(setRewardObj).catch(err => {
      console.error(err);
    });
    RewardReviewService.query({'reward.id':id_reward}).then(list => {
      setUserComments(list.map(obj => ({
        user_stars: obj.reward_review_stars,
        user_comment: obj.reward_review_text,
        user_image: obj.user && obj.user.profile_image ? obj.user.profile_image.url : null,
        username: obj.user ? obj.user.user_full_name : null
      })));
      
      if(list.filter(obj => (obj.user ? obj.user.id : null) === props.user.id).length > 0) {
        setUserReviewed(true);
      }
    }).catch(err => {
      console.error(err);
    })
  }
  
  const onFavorite = (id_reward) => {
    RewardService.favorite(id_reward).then(() => {
      loadReward(id_reward);
    }).catch(err => {
      console.error(err);
    });
  }
  
  const purchaseReward = async (e, purchased_with_cash = false) => {
    
    if(e && e.preventDefault) {
      e.preventDefault();
    }
    
    setIsLoadingActive(true);
    
    let shippingInfo = {
      shipping_first_name,
      shipping_last_name,
      shipping_address,
      shipping_apartment,
      shipping_country,
      shipping_state,
      shipping_city,
      shipping_zip,
      purchased_with_cash
    }
    
    try {
      await RewardPurchaseService.purchaseReward(id_reward, props.user.id, shippingInfo);
      props.updateUser();
      props.history.push('/reward-order-completed');
    } catch(err) {
      console.error(err);
      alert(t('error_processing_order'))
    }
    
    setIsLoadingActive(false);
    
  }
  
  // Initialize page
  if(!searchReward && props.user.id) {
    setSearchReward(true);
    loadReward(id_reward);
  }
  
  const paypalSuccessFunction = async (details, data) => {
    
    ProjectService.donate(props.user.id, currentProject.id, rewardObj.reward_price).then(() => {
      purchaseReward({}, true).catch(err => {
        console.error(err);
      });
    }).catch(err => {
      setIsLoadingActive(false);
      console.error(err);
    });
  }
  
  const sendReview = async () => {
    await (new RewardReviewService({
      reward_review_stars,
      reward_review_text,
      reward: {
        id: id_reward
      },
      user: {
        id: props.user.id
      }
    })).save();
  
    loadReward(id_reward);
  }
  
  return (
    <Grid container style={{height: '100vh'}}>
  
      {
        isLoadingActive ?
          <LoadingOverlay
            styles={{
              wrapper: {
                position: 'fixed',
                height: '100vh',
                width: '100vw',
                zIndex: 10000
              }
            }}
            active={isLoadingActive}
            spinner
            text={t('processing_order')}
          >
          </LoadingOverlay> : null
      }
      
      <Grid item xs={12}>
        <Header history={props.history}/>
      </Grid>
  
      <Grid item xs={12} style={{textAlign:'center', marginBottom: 10}}>
        <img src={giftRewards}/>
      </Grid>
  
      <Grid item xs={12} className={classes.page_title}>
        {t('title')}
      </Grid>
      
      <Grid item xs={12} style={{position: 'relative', marginBottom: 50}}>
        <Grid container spacing={3} className={classes.pageContainer}>
          <Grid item xs={12}>
            <WhiteContentBox container spacing={3} className={`${classes.reward_container_desktop}`}>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={12} className={classes.reward_title}>
                    {rewardObj[`reward_name${Utils.getI18nSuffix(i18n)}`]}
                  </Grid>
                  <Grid item xs={12} className={classes.reward_points_and_stars}>
                    <RewardPointsAndStars
                      points={rewardObj.reward_points}
                      stars={rewardObj.reward_stars}
                      purchases={rewardObj.reward_bought_number}
                    />
                  </Grid>
                  <Hidden smUp>
                    <Grid item xs={12} className={classes.reward_product_image_selector}>
                      <CustomCarousel>
                        {rewardObj.reward_image ?
                          rewardObj.reward_image.map((obj, idx) => (
                            <div style={{padding: '10px'}}>
                              <ImageContentBox
                                minHeight={'100px'}
                                src={obj.url}
                                onClick={() => {
                                  setIdxImage(idx);
                                }}
                              />
                            </div>
                          )) : null
                        }
                      </CustomCarousel>
                    </Grid>
                  </Hidden>
                  <Grid item xs={12} className={classes.reward_description_container}>
                    {ReactHtmlParser(rewardObj[`reward_text${Utils.getI18nSuffix(i18n)}`])}
                  </Grid>
                  <Grid item xs={12} className={classes.reward_points_inquiry}>
                    {ReactHtmlParser(t('points_inquiry'))}{
                    (props.user.user_redeemable_points - rewardObj.reward_points) < 0 ?
                      <span className={classes.alert}>({t('not_enough_points')})</span> : null
                  }
                  </Grid>
                  <Grid item xs={12} className={classes.reward_points_deduction}>
                    <Grid container spacing={2}>
                      <PointsBox item xs={4}
                        points={props.user ? props.user.user_redeemable_points : 0}
                        title={t('total_points')}
                        highlight={false}
                      />
                      <PointsBox item xs={4}
                        points={rewardObj.reward_points}
                        title={t('we_use')}
                        highlight={true}
                      />
                      <PointsBox item xs={4}
                        points={props.user ? (props.user.user_redeemable_points - rewardObj.reward_points) : 0}
                        title={t('balance')}
                        highlight={false}
                        alert={(props.user.user_redeemable_points - rewardObj.reward_points) < 0}
                      />
                    </Grid>
                  </Grid>
  
                  {/*Only Show Purchase option if there are not enough points and purchase option is enabled*/}
                  {
                    (
                      (props.user.user_redeemable_points - rewardObj.reward_points) < 0 &&
                      rewardObj.enable_purchase
                    ) ?
                      <>
                        <Grid xs={12}>
                          <Grid container spacing={2}>
                            <PayNowBox
                              item xs={6}
                              value={rewardObj.reward_price}
                              title={t('pay_now')}
                              highlight={true}
                            />
                            <PayNowBox
                              item xs={6}
                              value={rewardObj.reward_retail_price}
                              title={t('retail_price')}
                              highlight={false}
                            />
                          </Grid>
                        </Grid>
                        <Grid xs={12} style={{marginTop: '26px', marginLeft: '26px', marginRight: '26px'}}>
                          <CustomSelector
                            options={listProjects}
                            id_field={'id'}
                            name_field={`project_name${Utils.getI18nSuffix(i18n)}`}
                            value={currentProject}
                            onChange={setCurrentProject}
                          />
                        </Grid>
                      </> : null
                  }
                  
                  {/*Only Show Shipping Information if there are enough points*/}
                  {
                    (props.user.user_redeemable_points - rewardObj.reward_points) >= 0 || rewardObj.enable_purchase ?
                    <form onSubmit={purchaseReward} style={{width: '100%', marginTop: 20}}>
                      <Grid item xs={12} className={classes.reward_shipping_title}>
                        {ReactHtmlParser(t('shipping_details'))}
                      </Grid>
                      <Grid item xs={12} className={classes.reward_shipping_form}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <ShippingTextBox
                              required
                              placeholder={t('first_name')}
                              value={shipping_first_name}
                              onChange={(e) => {
                                setShippingFirstName(e.target.value)
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ShippingTextBox
                              required
                              placeholder={t('last_name')}
                              value={shipping_last_name}
                              onChange={(e) => {
                                setShippingLastName(e.target.value)
                              }}
                            />
                          </Grid>
                          <Grid item xs={9}>
                            <ShippingTextBox
                              required
                              placeholder={t('address')}
                              value={shipping_address}
                              onChange={(e) => {
                                setShippingAddress(e.target.value)
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <ShippingTextBox
                              placeholder={t('apartment')}
                              value={shipping_apartment}
                              onChange={(e) => {
                                setShippingApartment(e.target.value)
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ShippingTextBox
                              placeholder={t('country')}
                              value={shipping_country}
                              onChange={(e) => {
                                setShippingCountry(e.target.value)
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ShippingTextBox
                              placeholder={t('state')}
                              value={shipping_state}
                              onChange={(e) => {
                                setShippingState(e.target.value)
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ShippingTextBox
                              placeholder={t('city')}
                              value={shipping_city}
                              onChange={(e) => {
                                setShippingCity(e.target.value)
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ShippingTextBox
                              required
                              placeholder={t('zip_code')}
                              value={shipping_zip}
                              onChange={(e) => {
                                setShippingZip(e.target.value)
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.reward_send_button}>
                        {
                          (
                            (props.user.user_redeemable_points - rewardObj.reward_points) < 0 &&
                            rewardObj.enable_purchase
                          ) ?
                            <PayPalButton
                              style={{
                                size: 'responsive',
                                shape: 'rect',
                                color: 'silver',
                                layout: 'vertical',
                                label: 'paypal'
                              }}
                              amount={rewardObj.reward_price}
                              onCancel={() => {
                                alert(t('payment_cancelled'));
                                setIsLoadingActive(false);
                              }}
                              onError={() => {
                                alert(t('error_payment'));
                                setIsLoadingActive(false);
                              }}
                              createOrder={(data, actions) => {
                                setIsLoadingActive(true);
                                return actions.order.create({
                                  purchase_units: [{
                                    amount: {
                                      value: rewardObj.reward_price
                                    }
                                  }]
                                });
                              }}
                              onSuccess={paypalSuccessFunction}
                              options={{
                                clientId: process.env.REACT_APP_PAYPAL_TOKEN,
                                currency: process.env.REACT_APP_PAYPAL_CURRENCY
                              }}
                            /> :
                            <ButtonRedFilledAction
                              style={{width: '100%'}}
                              type={'submit'}
                            >
                              {t('send_reward_button')}
                            </ButtonRedFilledAction>
                        }
                      </Grid>
                    </form> : null
                }
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Hidden xsDown>
                    <Grid item xs={12} className={classes.reward_product_image}>
                      <ImageContentBox src={rewardObj.reward_image && rewardObj.reward_image.length > 0 ?
                        rewardObj.reward_image[idxImage].url :
                        null
                      }/>
                    </Grid>
                    <Grid item xs={12} className={classes.reward_product_image_selector}>
                      <CustomCarousel>
                        {rewardObj.reward_image ?
                          rewardObj.reward_image.map((obj, idx) => (
                            <div style={{padding: '10px'}}>
                              <ImageContentBox
                                minHeight={'100px'}
                                src={obj.url}
                                onClick={() => {
                                  setIdxImage(idx);
                                }}
                              />
                            </div>
                          )) : null
                        }
                      </CustomCarousel>
                    </Grid>
                  </Hidden>
                  
                  <Grid item xs={12} className={classes.reward_user_evaluation}>
                    <CustomCarousel forceSingleSlide={true}>
                      {
                        userComments.map(obj => (
                          <UserEvaluationBox
                            user_stars={obj.user_stars}
                            user_image={obj.user_image}
                            user_comment={obj.user_comment}
                            username={obj.username}
                          />
                        ))
                      }
                    </CustomCarousel>
                  </Grid>
                  {!userReviewed ?
                    <>
                      <Grid item xs={12} className={classes.reward_shipping_title} style={{marginTop: 20}}>
                        {ReactHtmlParser(t('write_review'))}
                      </Grid>
                      <Grid item xs={12} className={classes.reward_user_evaluation}>
                        <Grid container>
                          <Grid item xs={12} style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{lineHeight: '20px'}}>
                              {t('grade')}:
                            </div>
                            <Rating
                              value={reward_review_stars}
                              precision={0.5}
                              emptyIcon={<StarBorderIcon fontSize="inherit"/>}
                              onChange={(event, value) => {
                                setRewardReviewStars(value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextArea
                              placeholder={t('your_review')}
                              value={reward_review_text}
                              onChange={(event) => {
                                setRewardReviewText(event.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ButtonRedFilledAction
                              style={{width: '100%'}}
                              onClick={sendReview}
                            >
                              {t('send_review')}
                            </ButtonRedFilledAction>
                          </Grid>
                        </Grid>
                      </Grid>
                    </> : null
                  }
                </Grid>
              </Grid>
            </WhiteContentBox>
          </Grid>
        </Grid>
        
        {/*PURPLE BACKGROUND*/}
        <div style={{height: '100%', width: '100%', position: 'absolute', top: 50 , backgroundColor: '#463871', zIndex: 1}}/>
      </Grid>
      
      <Grid item xs={12}>
        <Footer history={props.history}/>
      </Grid>
    </Grid>
  )
}

export default connect(
  state => {
    return {
      user: state.user.user
    }
  },
  { updateUser }
)(SingleReward);