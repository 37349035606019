// Libraries
import React, {useEffect, useRef, useState} from 'react';
import {Grid, Hidden} from '@material-ui/core';
import { FaRegPaperPlane } from 'react-icons/fa'
import {connect} from "react-redux";

// Actions (REDUX)
import {updateInvitedMembers, updateUser} from "../../redux/actions";

// Components
import ButtonConfirm from "../../components/Buttons/ButtonConfirm";
import GreenContentBox from "../../components/ContentBox/GreenContentBox";
import GreenTimer from "../../components/Displays/GreenTimer";
import Header from '../../components/Header/Header';
import InvitedEmails from "../../components/Displays/InvitedEmails";
import TextBox from "../../components/Inputs/TextBox";
import TextArea from "../../components/Inputs/TextArea";

// Services
import InviteService from "../../services/InviteService";

// Helpers
import Utils from '../../helpers/utils'
import Footer from "../../components/Footer/Footer";
import CustomPhoneInput from "../../components/Inputs/CustomPhoneInput";
import WhiteRadioWithLabel from "../../components/Inputs/WhiteRadioWithLabel";
import moment from "moment";
import {useTranslation} from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import ButtonRedFilledAction from "../../components/Buttons/ButtonRedFilledAction";
import copy from "clipboard-copy";
import makeStyles from "@material-ui/core/styles/makeStyles";

function useInterval(callback, delay) {
  const savedCallback = useRef();
  
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: '11%',
    fontSize: 26,
    fontWeight: "lighter",
    color: "white",
    marginBottom: 28,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
  },
  buttonContainer: {
    marginBottom: 60,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
      textAlign: 'center'
    },
  },
  button: {
    width: '80%',
    minWidth: '270px',
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      minWidth: '270px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: '270px'
    },
  },
  inviteListContainer: {
    marginBottom: 100,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30
    },
  },
  inviteListTitle: {
    fontSize: 26,
    fontWeight: "lighter",
    color: "white",
    marginBottom: 18,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
  }
}));

const SendIndication = (props) => {
  
  const classes = useStyles();
  const [t, i18n] = useTranslation('send-indication');
  
  const [error_message, setErrorMessage] = useState('');
  const [invite_email, setInviteEmail] = useState('');
  const [invite_phone_number, setInvitePhoneNumber] = useState('');
  const [invite_message, setInviteMessage] = useState('');
  const [selectedContact, setSelectedContact] = useState('email');
  const [shareableLink, setShareableLink] = useState('');
  
  const [invitedSearch, setInvitedSearch] = useState(0);
  
  if(props.user && props.user.id && !invitedSearch) {
    setInvitedSearch(1);
    props.updateInvitedMembers();
  }
  
  const invitedList = props.invited_list ? props.invited_list : [];
  
  const sendInvitation = async (event) => {
    
    event.preventDefault();
    
    setErrorMessage('');
  
    if(selectedContact === 'email') {
      setInvitePhoneNumber('');
      // validate email
      if(!Utils.validateEmail(invite_email)) {
        return setErrorMessage(t('invalid_email'));
      }
    } else if(selectedContact === 'mobile') {
      setInviteEmail('');
    }
    
    try {
      let invite = await (new InviteService({
        user: {
          id: props.user.id
        },
        invite_email,
        invite_phone_number,
        invite_message
      })).save();
  
      props.updateInvitedMembers();
      
      setInviteEmail('');
      setInviteMessage('');
  
      if(selectedContact === 'link') {
        setShareableLink(invite.link);
        await copy(invite.link);
        alert(t('copied_clipboard'))
        return;
      }
      
      props.updateUser();
    } catch(err) {
      if(err && err.response && err.response.data) {
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage(t('unknown_error'));
      }
    }
  }
  
  const handleRadioChange = (event) => {
    setSelectedContact(event.target.value);
  }
  
  useInterval(() => {
    if(props.user && props.user.add_member_expiration) {
      let addExpirationTime = moment(props.user.add_member_expiration, 'YYYY-MM-DDTHH:mm:ss').valueOf();
      if(addExpirationTime < (new Date()).getTime()) {
        props.history.push({
          pathname: '/time-over',
          state: {
            action: t('add_new_member'),
            hours: '48'
          }
        });
      }
    }
  }, 1000);
  
  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header
          history={props.history}
        />
      </Grid>
      <Grid item xs={false} md={1}/>
      <Grid item xs={11} className={classes.title}>
        {t('add_a')} <span style={{fontWeight: "bolder"}}>{t('new_member')}</span>
      </Grid>
      <Grid item xs={false} md={1}/>
      <Grid item xs={12} md={5}>
        <Grid container spacing={1} style={{width: '90%', margin: "auto"}}>
          <form onSubmit={sendInvitation} style={{width: '100%'}}>
            <Grid item xs={12}>
              <WhiteRadioWithLabel
                checked={selectedContact === 'email'}
                onChange={handleRadioChange}
                value="email"
                name="radio-button-contact"
                label={t('email')}
              />
              <WhiteRadioWithLabel
                checked={selectedContact === 'mobile'}
                onChange={handleRadioChange}
                value="mobile"
                name="radio-button-contact"
                label={t('mobile')}
              />
              <WhiteRadioWithLabel
                checked={selectedContact === 'link'}
                onChange={handleRadioChange}
                value="link"
                name="radio-button-contact"
                label={t('link')}
              />
            </Grid>
            {
              selectedContact === 'link' ?
              <Grid item xs={12} style={{minHeight: '100px', textAlign: 'center'}}>
                {
                  shareableLink === '' ?
                    <ButtonRedFilledAction
                      style={{marginTop: '40px'}}
                      type={"submit"}
                    >
                      {t('button_shareable_link')}
                    </ButtonRedFilledAction> :
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', marginTop: '40px'}}>
                      <TextBox
                        disabled
                        value={shareableLink}
                      >
                        {shareableLink}
                      </TextBox>
                      <ButtonRedFilledAction
                        style={{minWidth: '200px'}}
                        onClick={async () => {
                          await copy(shareableLink);
                          alert(t('copied_clipboard'));
                        }}
                      >
                        {t('copy_link')}
                      </ButtonRedFilledAction>
                    </div>
                }
              </Grid> : selectedContact === 'mobile' ?
              <Grid item xs={12} style={{
                backgroundColor: '#463871'
              }}>
                <CustomPhoneInput
                  placeholder={t('member_mobile')}
                  value={invite_phone_number}
                  onChange={setInvitePhoneNumber}
                />
              </Grid> :
              <Grid item xs={12}>
                <TextBox
                  placeholder={t('member_email')}
                  type={"email"}
                  value={invite_email}
                  onChange={(event) => {
                    setInviteEmail(event.target.value);
                  }}
                />
              </Grid>
            }
            {selectedContact !== 'link' ?
              <>
                <Grid item xs={12}>
                  <TextArea
                    placeholder={t('your_comments')}
                    value={invite_message}
                    onChange={(event) => {
                      setInviteMessage(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  <span style={{margin: 'auto', fontSize: 20, color: '#FF0000'}}>
                    {error_message}
                  </span>
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                  <ButtonConfirm
                    icon={FaRegPaperPlane}
                    className={classes.button}
                    type={'submit'}
                  >{t('send_invitation')}</ButtonConfirm>
                </Grid>
              </> : null
            }
            {
              invitedList && invitedList.length > 0 ?
                <Grid container spacing={1} className={classes.inviteListContainer}>
                  <Grid item xs={12} className={classes.inviteListTitle}>
                    {ReactHtmlParser(t('invited_members'))} ({props.invited_total})
                  </Grid>
                  {invitedList.map(obj => (
                    <Grid item xs={12}>
                      <InvitedEmails invite={obj}/>
                    </Grid>
                  ))}
                </Grid> : null
            }
          </form>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid item xs={5}>
          <Grid container style={{textAlign: 'center'}}>
            <GreenContentBox item xs={12} style={{padding: 50, maxWidth: '80%', margin: '0 auto 40px auto'}}>
              <Grid container>
                <Grid item xs={12} style={{fontSize:36, fontWeight: 'lighter', paddingBottom: 21}}>
                  {t('add_a')} <span style={{fontWeight: 'bolder'}}>{t('new_member')}</span> {t('in')}
                </Grid>
                <Grid item xs={12}>
                  <GreenTimer deadline={props.user.add_member_expiration}/>
                </Grid>
              </Grid>
            </GreenContentBox>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12}>
          <Grid container style={{width: '90%', margin: "auto", textAlign: 'center', marginBottom: 20}}>
            <GreenContentBox item xs={12} style={{padding: 20, width: '100%'}}>
              <Grid container>
                <Grid item xs={12} style={{fontSize:36, fontWeight: 'lighter', paddingBottom: 21}}>
                  {t('add_a')} <span style={{fontWeight: 'bolder'}}>{t('new_member')}</span> {t('in')}
                </Grid>
                <Grid item xs={12}>
                  <GreenTimer deadline={props.user.add_member_expiration}/>
                </Grid>
              </Grid>
            </GreenContentBox>
          </Grid>
        </Grid>
      </Hidden>
      <Grid item xs={12}>
        <Footer history={props.history} just_copyright={true}/>
      </Grid>
    </Grid>
  );
}

export default connect(
  state => {
    return {
      user: state.user.user,
      invited_list: state.invitedMembers.invited_list,
      invited_total: state.invitedMembers.invited_total,
    }
  },
  { updateInvitedMembers, updateUser }
)(SendIndication);