// Libraries
import React, {useState} from 'react';
import { FormControl, Grid } from '@material-ui/core';

// Components
import ButtonConfirm from '../../components/Buttons/ButtonConfirm';
import ContentBox from '../../components/ContentBox/MainContentBox';
import TextBox from '../../components/Inputs/TextBox';
import Header from '../../components/Header/Header';

// Services
import UserService from '../../services/UserService';

// Helpers
import Utils from '../../helpers/utils';
import Footer from "../../components/Footer/Footer";
import CustomPhoneInput from "../../components/Inputs/CustomPhoneInput";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {updateInvitedMembers, updateUser} from "../../redux/actions";
import CustomDatepicker from "../../components/Inputs/CustomDatepicker";

const EditProfile = (props) => {
  
  const [t, i18n] = useTranslation('edit-profile');

  // ERROR HANDLING
  const [error_message, setErrorMessage] = useState(undefined);
  const [error_required_missing, setErrorRequiredMissing] = useState(false);
  const [error_email_invalid, setErrorEmailInvalid] = useState(false);
  const [error_phone_invalid, setErrorPhoneInvalid] = useState(false);
  
  const [userObj, setUserObj] = useState({});
  const [birth_date, setBirthDate] = useState('');
  const [block_birth_date, setBlockBirthDate] = useState(false);
  
  if(!userObj.id && props.user && props.user.id) {
    setUserObj(props.user);
    if(props.user.birth_date) {
      setBirthDate(props.user.birth_date);
      setBlockBirthDate(true);
    }
  }

  const editProfile = async (event) => {

    event.preventDefault();
    
    // Reset all errors before check form
    setErrorRequiredMissing(false);
    setErrorEmailInvalid(false);
    setErrorPhoneInvalid(false);
    setErrorMessage(undefined);

    // check all fields are filled
    if(
      !userObj.user_full_name ||
      !userObj.email ||
      !userObj.user_phone
    ) {
      setErrorRequiredMissing(true);
      return setErrorMessage(t('missing_required'));
    }

    // validate email
    if(!Utils.validateEmail(userObj.email)) {
      setErrorEmailInvalid(true);
      return setErrorMessage(t('invalid_email'));
    }

    userObj.birth_date = birth_date;
    
    // Call API
    try {
      await UserService.update(userObj.id, {
        user_full_name: userObj.user_full_name,
        email: userObj.email,
        user_phone: userObj.user_phone,
        birth_date: userObj.birth_date
      });
    } catch (err) {
      setErrorMessage(t('error_sending_user'));
      // TODO Treat errors
      console.error(err);
      return;
    }

    props.updateUser();
    
    // Redirects to profile page
    props.history.push("/profile");
  };

  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header
          history={props.history} isProfile={true}
        />
      </Grid>
      <Grid item xs={false} sm={2}/>
      <ContentBox item xs={12} sm={8} style={{
        margin: '0px 15px 100px 15px',
        padding: 26
      }}>
        <Grid container>
          <Grid item xs={12} style={{marginTop: 30, marginBottom: 36, textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 30, color: '#FFFFFF'}}>
              {t('edit_profile')}
            </span>
          </Grid>
          <Grid item xs={12} style={{marginBottom: 20, textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 20, color: '#FF0000'}}>
              {error_message}
            </span>
          </Grid>
          <form onSubmit={editProfile} style={{width: '100%'}}>
            <Grid item xs={12} style={{marginBottom: 56}}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextBox
                    placeholder={t('username')}
                    value={userObj.username}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextBox
                    placeholder={t('full_name')}
                    required
                    error={(error_required_missing && (!userObj.user_full_name || userObj.user_full_name === ''))}
                    tabIndex={1}
                    value={userObj.user_full_name}
                    onChange={event => {
                      setUserObj({...userObj, user_full_name: event.target.value});
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomPhoneInput
                    placeholder={t('phone')}
                    required
                    error={(error_required_missing && (!userObj.user_phone || userObj.user_phone === '')) || error_phone_invalid}
                    value={userObj.user_phone}
                    onChange={value => {
                      setUserObj({...userObj, user_phone: value});
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextBox
                    placeholder={t('email')}
                    type={"email"}
                    tabIndex={3}
                    required
                    error={(error_required_missing && (!userObj.email || userObj.email === '')) || error_email_invalid}
                    value={userObj.email}
                    onChange={event => {
                      setUserObj({...userObj, email: event.target.value});
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomDatepicker
                    disabled={block_birth_date}
                    placeholder={t('birth_date')}
                    tabIndex={4}
                    value={birth_date}
                    onChange={date => {
                      setBirthDate(date);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}}>
              <span style={{margin: 'auto', fontSize: 20, color: '#FF0000'}}>
                {error_message}
              </span>
            </Grid>
            <Grid item xs={12}>
              <ButtonConfirm
                style={{width: '100%'}}
                type={'submit'}>
                {t('edit_profile')}
              </ButtonConfirm>
            </Grid>
          </form>
        </Grid>
      </ContentBox>
      <Grid item xs={false} sm={2}/>
      <Grid item xs={12}/>
      <Grid item xs={12}>
        <Footer history={props.history}/>
      </Grid>
    </Grid>
  );
}

export default connect(
  state => {
    return {
      user: state.user.user
    }
  },
  { updateUser }
)(EditProfile);