import React, {useState} from "react";
import {Grid, Hidden} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ProjectService from "../../services/ProjectService";
import WhiteContentBox from "../../components/ContentBox/WhiteContentBox";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import {makeStyles} from "@material-ui/core/styles";
import bulletPoint from '../../assets/bullet-point.png';
import SingleProjectsBox from "../../components/ContentBox/SingleProjectsBox";
import RecentDonatorsBox from "../../components/ContentBox/RecentDonatorsBox";
import LoadingOverlay from "react-loading-overlay";
import {useTranslation} from "react-i18next";
import Utils from "../../helpers/utils";

const useStyles = makeStyles(theme => ({
  project_container_desktop: {
    padding: '39px 67px',
    
  },
  project_container_mobile: {
    padding: '39px 20px',
    
  },
  project_container: {
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 'lighter',
    '& > h2': {
      color: '#503e89',
      fontWeight: 'normal',
      fontSize: 36,
      margin: 0
    },
    '& figure': {
      margin: '10px 0 10px 0',
      position: 'relative'
    },
    '& video': {
      width: '100%'
    },
    '& strong': {
      color: '#63a461',
      fontWeight: 'bold'
    },
    '& .image-style-align-right': {
      width: '50%',
      float: 'right'
    },
    '& .image-style-align-left': {
      width: '50%',
      float: 'left'
    },
    '& img': {
      width: '100%'
    },
    '& figcaption': {
      color: 'white',
      position: 'absolute',
      bottom: '0',
      left: '0',
      padding: '5px',
      fontSize: '15px',
      backgroundColor: 'rgba(0,0,0,0.6)'
    },
    '& ul': {
      listStyleImage: `url('${bulletPoint}')`
    },
    '& li': {
      paddingLeft: '15px',
      marginBottom: '20px'
    }
  }
}))

export default (props) => {
  
  const [t, i18n] = useTranslation('single-project');
  
  const classes = useStyles();
  
  const {id_project} = props.match.params;
  
  const [isLoadingActive, setIsLoadingActive] = useState(false);
  
  const [searchProjects, setSearchProjects] = useState(false);
  const [projectObj, setProjectObj] = useState({});
  
  const loadProject = (id_project) => {
    ProjectService.get(id_project).then(setProjectObj).catch(err => {
      console.error(err);
    });
  }
  
  if(!searchProjects) {
    setSearchProjects(true);
    loadProject(id_project);
  }
  
  const onFavorite = (id_project) => {
    ProjectService.favorite(id_project).then(() => {
      loadProject(id_project);
    }).catch(err => {
      console.error(err);
    });
  }
  
  return (
    <Grid container style={{height: '100vh'}}>
      <LoadingOverlay
        styles={{
          wrapper: {
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            zIndex: (isLoadingActive ? 10000 : 0)
          },
          overlay: (defaultTheme) => ({
            ...defaultTheme,
            position: 'fixed',
          })
        }}
        active={isLoadingActive}
        spinner
        text={t('processing_payment')}
      >
      </LoadingOverlay>
      
      <Grid item xs={12}>
        <Header history={props.history}/>
      </Grid>
    
      <Grid item xs={12} style={{fontSize: 36, color: 'white', textAlign: 'center', marginBottom: 44}}>
        {t('title')}
      </Grid>
    
      <Grid item xs={12} style={{position: 'relative', marginBottom: 150}}>
        <Hidden smDown>
          <Grid container spacing={3} style={{position: 'relative', zIndex: 100, maxWidth: '90%', margin: 'auto'}}>
            <Grid item xs={9}>
              <WhiteContentBox className={`${classes.project_container} ${classes.project_container_desktop}`}>
                {ReactHtmlParser(projectObj[`project_text${Utils.getI18nSuffix(i18n)}`])}
              </WhiteContentBox>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SingleProjectsBox
                    projectImg={projectObj.project_image ? projectObj.project_image.url : null}
                    projectName={projectObj[`project_name${Utils.getI18nSuffix(i18n)}`]}
                    completed={projectObj.value_raised/projectObj.project_goal}
                    funders={projectObj.funders}
                    id_project={projectObj.id}
                    setIsLoadingActive={setIsLoadingActive}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RecentDonatorsBox
                    elements={projectObj.recent_donators}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid container spacing={3} style={{position: 'relative', zIndex: 100, maxWidth: '100%', margin: 'auto'}}>
            <Grid item xs={12}>
              <SingleProjectsBox
                history={props.history}
                projectImg={projectObj.project_image ? projectObj.project_image.url : null}
                projectName={projectObj[`project_name${Utils.getI18nSuffix(i18n)}`]}
                completed={projectObj.value_raised/projectObj.project_goal}
                funders={projectObj.funders}
                id_project={projectObj.id}
              />
            </Grid>
            <Grid item xs={12}>
              <WhiteContentBox className={`${classes.project_container} ${classes.project_container_mobile}`}>
                {ReactHtmlParser(projectObj[`project_text${Utils.getI18nSuffix(i18n)}`])}
              </WhiteContentBox>
            </Grid>
            <Grid item xs={12}>
              <RecentDonatorsBox
                elements={projectObj.recent_donators}
                mobile={true}
              />
            </Grid>
          </Grid>
        </Hidden>
        
        {/*PURPLE BACKGROUND*/}
        <div style={{height: '100%', width: '100%', position: 'absolute', top: 150, backgroundColor: '#463871', zIndex: 1}}/>
      </Grid>
    
      <Grid item xs={12}>
        <Footer history={props.history}/>
      </Grid>
    </Grid>
  )
}
