import GenericModel from './generic_model/GenericModel';
import axios from "axios";
import Config from "../config/config";

export default class InviteService extends GenericModel {
  API = '/invites';
  static API = '/invites';

  constructor(props) {
    super(props);
  }
  
  static async acceptInvite() {
    const {data} = await axios.get(Config.API_ADDRESS + '/invites/accept',{
      headers: GenericModel.insertAuthHeader()
    });
  
    return data;
  }
  
  static async resendInvite(id_invite) {
    const {data} = await axios.get(`${Config.API_ADDRESS}/invites/${id_invite}/resend`,{
      headers: GenericModel.insertAuthHeader()
    });
  
    return data;
  }
}