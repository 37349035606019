import React, {useState} from 'react';
import ButtonConfirm from '../../components/Buttons/ButtonConfirm';
import ContentBox from '../../components/ContentBox/MainContentBox';
import TextBox from '../../components/Inputs/TextBox';
import Header from '../../components/Header/Header';
import { Grid } from '@material-ui/core';
import UserService from '../../services/UserService';
import hands from '../../assets/hands_reference_code.png'
import InviteService from "../../services/InviteService";
import Footer from "../../components/Footer/Footer";
import {useTranslation} from "react-i18next";

export default function ReferenceCode(props) {
  
  const [t, i18n] = useTranslation('reference-code');

  // ERROR HANDLING
  const [error_message, setErrorMessage] = useState(undefined);
  const [reference_code, setReferenceCode] = useState('');

  const sendReferenceCodeFunction = async (event) => {

    event.preventDefault();
    
    // Reset all errors before check form
    setErrorMessage(undefined);

    // check all fields are filled
    if(!reference_code) {
      return setErrorMessage(t('missing_required'));
    }

    // validate reference_code
    try {
      const inviteObj = await InviteService.query({invite_code:reference_code});
  
      let is_valid = 0;
      if(inviteObj.length > 0) {
        is_valid = 1;
      }
      
      if (!is_valid) {
        return setErrorMessage(t('reference_not_exist'));
      }
    } catch (err) {
      return setErrorMessage(err.message);
    }

    return props.history.push('/signup?reference_code=' + reference_code);
  };

  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header
          history={props.history}
          enableLogin
        />
      </Grid>
      <Grid item xs={false} sm={2} md={3}/>
      <ContentBox item xs={12} sm={8} md={6} style={{
        margin: '0px 15px 100px 15px',
        padding: '26px'
      }}>
        <Grid container>
          <Grid item xs={12} style={{marginBottom: 23, textAlign: 'center'}}>
            <img src={hands} style={{height: '80px'}}/>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 30, lineHeight: '50px', color: '#857d9c', fontWeight: 'lighter'}}>
              {t('just_step')}
            </span>
          </Grid>
          <Grid item xs={12} style={{marginBottom: 36, textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 36, lineHeight: '50px', color: '#FFFFFF'}}>
              {t('our_new_member')}
            </span>
          </Grid>
          <Grid item xs={12} style={{marginBottom: 20, textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 20, color: '#FF0000'}}>
              {error_message}
            </span>
          </Grid>
          <form onSubmit={sendReferenceCodeFunction} style={{width: '100%'}}>
            <Grid item xs={12} style={{marginBottom: 20}}>
              <TextBox
                placeholder={t('reference_code')}
                required
                error={error_message !== undefined}
                tabIndex={1}
                value={reference_code}
                onChange={event => {
                  setReferenceCode(event.target.value);
                }}/>
            </Grid>
            <Grid item xs={12}>
              <ButtonConfirm
                style={{width: '100%'}}
                type={'submit'}
              >
                {t('submit')}
              </ButtonConfirm>
            </Grid>
          </form>
        </Grid>
      </ContentBox>
      <Grid item xs={false} sm={2} md={3}/>
      <Grid item xs={12}/>
      <Grid item xs={12}>
        <Footer history={props.history} just_copyright={true}/>
      </Grid>
    </Grid>
  );
}