import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    borderRadius: 2,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.05)'
  }
}))(Grid)