import React from "react";
import {Grid} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import {Rating} from "@material-ui/lab";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 2,
    border: 'solid 1px #e5e5e5',
    backgroundColor: '#f8f8f8'
  },
  points_value: {
    lineHeight: '96px',
    fontSize: '36px',
    color: '#63a461',
    marginLeft: '25px',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '85px',
      fontSize: '26px',
      marginLeft: '10px',
    },
  },
  points_name: {
    fontSize: 18,
    lineHeight: '96px',
    color: '#9b9b9b',
    marginLeft: '8px',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '85px'
    },
  },
  spacer: {
    flex: 1
  },
  star_container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    marginRight: '46px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '10px'
    },
  },
  stars: {
  
  },
  purchases_number: {
    color: '#20183a',
    fontSize: 16
  }
}));

export default (props) => {
  
  const [t, i18n] = useTranslation('reward-points-and-stars');
  
  const classes = useStyles();
  
  return (
    <Grid className={classes.container}>
      <div className={classes.points_value}>{props.points}</div>
      <div className={classes.points_name}>{t('points')}</div>
      <div className={classes.spacer}/>
      <div className={classes.star_container}>
        <div className={classes.spacer}/>
        <div className={classes.stars}>
          <Rating
            value={!isNaN(props.stars) ? Number(props.stars) : null}
            precision={0.5}
            emptyIcon={<StarBorderIcon fontSize="inherit"/>}
            readOnly
          />
        </div>
        <div className={classes.purchases_number}><b>{props.purchases}</b> {t('bought')}</div>
        <div className={classes.spacer}/>
      </div>
    </Grid>
  )
}