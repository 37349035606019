import React, {useRef, useEffect, useState} from 'react';
import {Grid, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import hurryUpImage from '../../assets/hurryUp.svg';
import GreenTimer from "../Displays/GreenTimer";
import moment from "moment";
import YellowLoadingCircle from "../Displays/YellowLoadingCircle";
import {useTranslation} from "react-i18next";

let targetRef = null;

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 2,
    boxShadow: '0 6px 15px 0 rgba(0, 0, 0, 0.32)',
    backgroundImage: 'linear-gradient(335deg, #487a46 37%, #89bc65)',
    textAlign: 'center',
    color: 'white'
  }
}));

function useCurrentDimensions() {
  // save current window width in the state object
  let [dimensions, setDimensions] = useState({width: 0, height: 0});
  let [dimensionInitizalized, setDimensionInitizalized] = useState(0);
  
  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth,
          height: targetRef.current.offsetHeight
        });
      } else {
        setTimeout(resizeListener, 100);
      }
    };
    
    if (!dimensionInitizalized) {
      setDimensionInitizalized(1);
      resizeListener();
    }
    
    // set resize listener
    window.addEventListener('resize', resizeListener);
    
    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [])
  
  return dimensions;
}

export default function DonationTimerBox(props) {
  
  const [t, i18n] = useTranslation('donation-timer-box');
  
  const classes = useStyles();
  targetRef = useRef();
  let dimensions = useCurrentDimensions();
  
  const [timeleft, setTimeleft] = useState({totalMinutes: 0});
  
  let deadlineTime = props.deadline ? moment(props.deadline, 'YYYY-MM-DDTHH:mm:ss').valueOf() : 0;
  
  setTimeout(() => {
    let nowTime = (new Date()).getTime();
    let diff = Math.max(deadlineTime - nowTime, 0);
    
    let newTimeleft = {
      totalMinutes: Math.floor(diff / (1000 * 60))
    }
    
    setTimeleft(newTimeleft);
  }, 1000);
  
  return (
    <>
      <Hidden smDown>
        <Grid className={classes.root} {...props} ref={targetRef} {...props}>
          <Grid container>
            <Grid item xs={12} style={{marginTop: 29}}>
              <img src={hurryUpImage}/>
            </Grid>
            <Grid item xs={12}>
              <span style={{fontSize: 26, fontWeight: 'bold'}}>{t('hurry_up')}</span>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 29}}>
              <span style={{fontSize: 18, lineHeight: '32px'}}>{t('dont_have_time')}</span>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 29}}>
              <div style={{
                backgroundColor: 'rgba(255,255,255,0.1)',
                width: 0.8 * dimensions.width,
                height: 0.8 * dimensions.width,
                borderRadius: '100%',
                margin: 'auto',
                position: 'relative'
              }}>
                <div style={{
                  backgroundColor: 'rgba(255,255,255,0.2)',
                  width: 0.65 * dimensions.width,
                  height: 0.65 * dimensions.width,
                  borderRadius: '100%',
                  position: 'absolute',
                  top: 0.075 * dimensions.width,
                  left: 0.075 * dimensions.width
                }}>
                  <div style={{
                    backgroundImage: 'linear-gradient(to bottom, #ffffff 5%, #cecece)',
                    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.17)',
                    width: 0.5 * dimensions.width,
                    height: 0.5 * dimensions.width,
                    borderRadius: '100%',
                    position: 'absolute',
                    top: 0.075 * dimensions.width,
                    left: 0.075 * dimensions.width
                  }}>
                    <YellowLoadingCircle
                      centerX={0.25 * dimensions.width}
                      centerY={0.25 * dimensions.width}
                      radius={0.22 * dimensions.width}
                      progress={(timeleft.totalMinutes)/120}
                      width={8}
                      color={'#f98c06'}
                    />
                    <div style={{
                      backgroundImage: 'linear-gradient(331deg, #20183a 5%, #503e89)',
                      width: 0.38 * dimensions.width,
                      height: 0.38 * dimensions.width,
                      borderRadius: '100%',
                      position: 'absolute',
                      top: 0.06 * dimensions.width,
                      left: 0.06 * dimensions.width
                    }}>
                      <div style={{
                        position: 'absolute',
                        margin: 'auto',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)'
                      }}>
                        <span style={{fontSize: 0.12 * dimensions.width}}>{timeleft.totalMinutes}</span><br/>
                        <span style={{fontSize: 0.05 * dimensions.width}}>{t('min')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 19}}>
              <span style={{fontSize: 20}}>{t('time_remaining')}</span>
            </Grid>
            <Grid item xs={12}>
              <GreenTimer
                style={{padding: '0 25px 25px 25px'}}
                deadline={props.deadline}
              />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid className={classes.root} {...props} ref={targetRef}>
          <Grid container style={{padding: '10px'}}>
            <Grid item xs={7} style={{marginBottom: 29, position: 'relative'}}>
              <Grid container style={{
                margin: 'auto',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)'
              }}>
                <Grid item xs={12} style={{marginTop: 29, marginBottom: 14}}>
                  <img src={hurryUpImage}/>
                </Grid>
                <Grid item xs={12}>
                  <span style={{fontSize: 26, fontWeight: 'bold'}}>{t('hurry_up')}</span>
                </Grid>
                <Grid item xs={12}>
                  <span style={{fontSize: 18, lineHeight: '32px'}}>{t('dont_have_time')}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} style={{marginBottom: 29}}>
              <Grid item xs={12} style={{marginTop: 29, marginBottom: 34}}>
                <div style={{
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  width: 0.3 * dimensions.width,
                  height: 0.3 * dimensions.width,
                  borderRadius: '100%',
                  margin: 'auto',
                  position: 'relative'
                }}>
                  <div style={{
                    backgroundImage: 'linear-gradient(to bottom, #ffffff 5%, #cecece)',
                    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.17)',
                    width: 0.25 * dimensions.width,
                    height: 0.25 * dimensions.width,
                    borderRadius: '100%',
                    position: 'absolute',
                    top: 0.025 * dimensions.width,
                    left: 0.025 * dimensions.width
                  }}>
                    <YellowLoadingCircle
                      centerX={0.125 * dimensions.width}
                      centerY={0.125 * dimensions.width}
                      radius={0.113 * dimensions.width}
                      progress={(timeleft.totalMinutes)/120}
                      color={'#f98c06'}
                    />
                    <div style={{
                      backgroundImage: 'linear-gradient(331deg, #20183a 5%, #503e89)',
                      width: 0.2 * dimensions.width,
                      height: 0.2 * dimensions.width,
                      borderRadius: '100%',
                      position: 'absolute',
                      top: 0.025 * dimensions.width,
                      left: 0.025 * dimensions.width
                    }}>
                      <div style={{
                        position: 'absolute',
                        margin: 'auto',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)'
                      }}>
                        <span style={{fontSize: 0.08 * dimensions.width}}>{timeleft.totalMinutes}</span><br/>
                        <span style={{fontSize: 0.04 * dimensions.width}}>{t('min')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            
            <Grid item xs={12} style={{marginBottom: 19}}>
              <span style={{fontSize: 20}}>{t('time_remaining')}</span>
            </Grid>
            <Grid item xs={12}>
              <GreenTimer
                style={{paddingBottom: '8px'}}
                deadline={props.deadline}
              />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  )
}