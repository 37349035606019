// Libraries
import React, {useState} from 'react';
import {Grid, Hidden} from '@material-ui/core';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { makeStyles } from "@material-ui/core/styles";

// Components
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ActionPointsBox from "../../components/ContentBox/ActionPointsBox";
import ButtonRedFilledAction from "../../components/Buttons/ButtonRedFilledAction";
import InvitedEmails from "../../components/Displays/InvitedEmails";
import ButtonFilterWithNumber from "../../components/Buttons/ButtonFilterWithNumber";
import ProjectsProfileBox from "../../components/ContentBox/ProjectsProfileBox";
import ProductBox from "../../components/ContentBox/ProductBox";
import CustomCarousel from "../../components/Displays/CustomCarousel";
import TotalPointsBox from "../../components/ContentBox/TotalPointsBox";
import MyTotalDonations from "../../components/ContentBox/TotalDonationsBox";

// Images
import addMemberImg from '../../assets/addMember.svg';
import donateHandsImg from '../../assets/donateHands.svg';
import greenBirthCakeImg from '../../assets/greenBirthCake.svg';
import thirdEyesImg from '../../assets/thirdEyes.png';
import rewardClock from '../../assets/rewardClock.jpg';
import profilePicImg from '../../assets/user_picture.jpg';
import TotalNetworkBox from "../../components/ContentBox/TotalNetworkBox";
import MobileTotalPointsBox from "../../components/ContentBox/MobileTotalPointsBox";
import {connect} from "react-redux";
import {updateInvitedMembers, updateUser} from "../../redux/actions";
import UserService from "../../services/UserService";
import ActivityService from "../../services/ActivityService";
import ProjectService from "../../services/ProjectService";
import RewardService from "../../services/RewardService";
import RewardPurchaseService from "../../services/RewardPurchaseService";
import WhiteContentBox from "../../components/ContentBox/WhiteContentBox";
import ReactHtmlParser from "react-html-parser";
import TransitionsModal from "../../components/Modal/TransitionsModal";
import TextBox from "../../components/Inputs/TextBox";
import TextArea from "../../components/Inputs/TextArea";
import ButtonConfirm from "../../components/Buttons/ButtonConfirm";
import {FaRegPaperPlane} from "react-icons/fa";
import InviteService from "../../services/InviteService";
import Utils from "../../helpers/utils";
import {useTranslation} from "react-i18next";
import WhiteRadioWithLabel from "../../components/Inputs/WhiteRadioWithLabel";
import CustomPhoneInput from "../../components/Inputs/CustomPhoneInput";
import copy from 'clipboard-copy';

const useStyles = makeStyles(theme => ({
  modalInvite: {
    width: '60vw',
    [theme.breakpoints.down('sm')]: {
      width: '70vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80vw',
    }
  },
  leftColumn: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '10px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '50px',
    },
  }
}));

const Profile = (props) => {
  
  const [t, i18n] = useTranslation('profile');
  
  const classes = useStyles();
  
  /**
   *  Load redux variables
   */
  const invitedList = props.invited_list ? props.invited_list : [];
  const userObj = props.user ? props.user : {};
  
  /**
   *  Create states
   */
  // Flags
  const [initialSearches, setInitialSearches] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  
  // Lists
  const [myTotalNetworkList, setMyTotalNetworkList] = useState(0);
  
  // Projects Filter Variables
  const [projectsList, setProjectsList] = useState([]);
  const [projectsFilter, setProjectsFilter] = useState('favorite');
  const [favoriteCounter, setFavoriteCounter] = useState(0);
  const [donatedCounter, setDonatedCounter] = useState(0);
  
  // Rewards Filter Variables
  const [rewardsList, setRewardsList] = useState([]);
  const [rewardsFilter, setRewardsFilter] = useState('favorite');
  const [favoriteRewardCounter, setFavoriteRewardCounter] = useState(0);
  const [purchasedRewardCounter, setPurchasedRewardCounter] = useState(0);
  
  // Invitation variables
  const [error_message, setErrorMessage] = useState('');
  const [invite_email, setInviteEmail] = useState('');
  const [invite_phone_number, setInvitePhoneNumber] = useState('');
  const [invite_message, setInviteMessage] = useState('');
  const [selectedContact, setSelectedContact] = useState('email');
  const [shareableLink, setShareableLink] = useState('');
  
  /**
   *  Functions to reload DB information
   */
  const refreshProjects = (selectedFilter) => () => {
    setProjectsFilter(selectedFilter);
    
    let user_parameter = selectedFilter === 'favorite' ? 'favorited_users' : 'donated_users';
    let query = {};
    query[`${user_parameter}.id`] = props.user.id;
  
    ProjectService.count({'favorited_users.id':props.user.id}).then(setFavoriteCounter);
    ProjectService.count({'donated_users.id':props.user.id}).then(setDonatedCounter);
    
    ProjectService.query(query).then(ans => {
      setProjectsList(ans);
    });
  }
  
  const refreshRewards = (selectedFilter) => () => {
    setRewardsFilter(selectedFilter);
  
    RewardService.count({'favorited_users.id':props.user.id}).then(setFavoriteRewardCounter);
    RewardPurchaseService.count({'user.id':props.user.id}).then(setPurchasedRewardCounter);
  
    let query = {};
    
    if(selectedFilter === 'favorite') {
      query[`favorited_users.id`] = props.user.id;
      RewardService.query(query).then(ans => {
        setRewardsList(ans);
      });
    } else {
      query[`user.id`] = props.user.id;
      RewardPurchaseService.query(query).then(ans => {
        setRewardsList(ans.map(obj => obj.reward));
      });
    }
  }
  
  // Sends invitation to user
  const sendInvitation = async (event) => {
    event.preventDefault();
  
    setErrorMessage('');
  
    if(selectedContact === 'email') {
      setInvitePhoneNumber('');
      // validate email
      if(!Utils.validateEmail(invite_email)) {
        return setErrorMessage(t('error_invalid_email'));
      }
    } else if(selectedContact === 'mobile') {
      setInviteEmail('');
    }
    
    try {
      let invite = await (new InviteService({
        user: {
          id: props.user.id
        },
        selectedContact,
        invite_email,
        invite_phone_number,
        invite_message
      })).save();
      
      props.updateInvitedMembers();
    
      setInviteEmail('');
      setInviteMessage('');
  
      if(selectedContact === 'link') {
        setShareableLink(invite.link);
        await copy(invite.link);
        alert(t('copied_clipboard'))
        return;
      }
      
      setOpenModal(false);
    } catch(err) {
      if(err && err.response && err.response.data) {
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage(t('error_unknown'));
      }
    }
  }
  
  // Function to redirect to other page
  const redirect = (path) => () => {
    props.history.push(`/${path}`);
  }
  
  /**
   * Starting script
   */
  if(props.user && props.user.id && !initialSearches) {
    setInitialSearches(1);
    props.updateInvitedMembers();
    
    UserService.getUserNetwork(props.user.id).then(setMyTotalNetworkList);
    
    refreshProjects(projectsFilter)();
    refreshRewards(rewardsFilter)();
  }
  
  const onClickOpenModal = () => {
    setInviteMessage(t('default_invite_message'));
    setOpenModal(true);
  };
  
  const handleRadioChange = (event) => {
    setSelectedContact(event.target.value);
  }
  
  return (
    <Grid container style={{height: '100vh'}}>
  
      <TransitionsModal
        forceBackColor={'#716891'}
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}>
        <form onSubmit={sendInvitation} className={classes.modalInvite}>
          <Grid item xs={12}>
            <WhiteRadioWithLabel
              checked={selectedContact === 'email'}
              onChange={handleRadioChange}
              value="email"
              name="radio-button-contact"
              label={t('email')}
            />
            <WhiteRadioWithLabel
              checked={selectedContact === 'mobile'}
              onChange={handleRadioChange}
              value="mobile"
              name="radio-button-contact"
              label={t('mobile')}
            />
            <WhiteRadioWithLabel
              checked={selectedContact === 'link'}
              onChange={handleRadioChange}
              value="link"
              name="radio-button-contact"
              label={t('link')}
            />
          </Grid>
          {
            selectedContact === 'link' ?
            <Grid item xs={12} style={{minHeight: '100px', textAlign: 'center'}}>
              {
                shareableLink === '' ?
                  <ButtonRedFilledAction
                    style={{marginTop: '40px'}}
                    type={"submit"}
                  >
                    {t('button_shareable_link')}
                  </ButtonRedFilledAction> :
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', marginTop: '40px'}}>
                    <TextBox
                      disabled
                      value={shareableLink}
                    >
                      {shareableLink}
                    </TextBox>
                    <ButtonRedFilledAction
                      style={{minWidth: '200px'}}
                      onClick={async () => {
                        await copy(shareableLink);
                        alert(t('copied_clipboard'));
                      }}
                    >
                      {t('copy_link')}
                    </ButtonRedFilledAction>
                  </div>
              }
            </Grid> : selectedContact === 'mobile' ?
            <Grid item xs={12} style={{
              backgroundColor: 'rgb(113, 104, 145)'
            }}>
              <CustomPhoneInput
                placeholder={t('phone_placeholder')}
                value={invite_phone_number}
                onChange={setInvitePhoneNumber}
              />
            </Grid> :
            
            <Grid item xs={12}>
              <TextBox
                placeholder={t('email_address_placeholder')}
                type={"email"}
                value={invite_email}
                onChange={(event) => {
                  setInviteEmail(event.target.value);
                }}
              />
            </Grid>
          }
          {selectedContact !== 'link' ?
            <>
              <Grid item xs={12}>
                <TextArea
                  required
                  placeholder={t('comments_placeholder')}
                  value={invite_message}
                  onChange={(event) => {
                    setInviteMessage(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <span style={{margin: 'auto', fontSize: 20, color: '#FF0000'}}>
                  {error_message}
                </span>
              </Grid>
              <Grid item xs={12} style={{textAlign: 'center', marginTop: 50}}>
                <ButtonConfirm
                  icon={FaRegPaperPlane}
                  style={{width: '80%', minWidth: '270px'}}
                  type={'submit'}
                >{t('button_send_invitation')}</ButtonConfirm>
              </Grid>
            </> : null
          }
        </form>
      </TransitionsModal>
      
      <Grid item xs={12}>
        <Header history={props.history} isProfile={true}/>
      </Grid>
  
      {/*PC SCREEN*/}
      <Hidden smDown>
        <Grid item lg={1}/>
  
        <Grid item xs={12} lg={11} style={{fontSize: 20, color: 'white', paddingLeft: 20, marginBottom: 44}}>
          {t('greeting')}<span style={{color: '#63a461'}}>
            {userObj.user_full_name ? userObj.user_full_name.split(" ")[0] : userObj.username}
          </span>
        </Grid>
      
        <Grid item xs={12} style={{position: 'relative', marginBottom: 150}}>
          <Grid container style={{position: 'relative', zIndex: 100, paddingLeft: 20, paddingRight: 20}}>
            <Grid item lg={1}/>
            <Grid item xs={7}>
              <Grid container>
                <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', marginBottom: 52}}>
                  <CustomCarousel>
                    <ActionPointsBox
                      style={{flex: 1, marginRight: 10}}
                      points={100}
                      title={t('action_title_1')}
                      action={t('action_button_1')}
                      onClick={onClickOpenModal}
                      img={addMemberImg}
                    />
                    <ActionPointsBox
                      style={{flex: 1, marginRight: 10, marginLeft: 10}}
                      points={'+'}
                      customText={t('action_custom_2')}
                      title={t('action_title_2')}
                      action={t('action_button_2')}
                      onClick={redirect('projects')}
                      img={donateHandsImg}
                    />
                    <ActionPointsBox
                      style={{flex: 1, marginLeft: 10}}
                      points={200}
                      title={t('action_title_3')}
                      action={t('action_button_3')}
                      onClick={redirect('edit-profile')}
                      img={greenBirthCakeImg}
                    />
                    
                  </CustomCarousel>
                </Grid>
                <Grid item xs={12} style={{marginBottom: 27}}>
                  <div style={{float: 'left', fontSize: 26, color: 'white', fontWeight: 'lighter', lineHeight: '62px'}}>
                    {ReactHtmlParser(t('invited_members_title'))}
                  </div>
                  <div style={{float: 'right'}}>
                    <ButtonRedFilledAction
                      onClick={onClickOpenModal}
                    >
                      {t('button_invite_new_members')}
                    </ButtonRedFilledAction>
                  </div>
                </Grid>
                <Grid item xs={12} style={{marginBottom:58}}>
                  <Grid container spacing={1}>
                    {invitedList.map(obj => (
                      <Grid item xs={12}>
                        <InvitedEmails invite={obj}/>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', marginBottom: 28}}>
                  <div style={{flex: 1, marginRight: 20}}>
                    <ButtonFilterWithNumber
                      active={projectsFilter === 'favorite' ? 1 : 0}
                      number={favoriteCounter}
                      onClick={refreshProjects('favorite')}
                    >
                      {t('favorite_projects_filter')}
                    </ButtonFilterWithNumber>
                  </div>
                  <div style={{flex: 1}}>
                    <ButtonFilterWithNumber
                      active={projectsFilter === 'donated' ? 1 : 0}
                      number={donatedCounter}
                      onClick={refreshProjects('donated')}
                    >
                      {t('donated_projects_filter')}
                    </ButtonFilterWithNumber>
                  </div>
                </Grid>
                <Grid item xs={12} style={{marginBottom: 58}}>
                  <CustomCarousel>
                    {projectsList.map(obj => (
                      <ProjectsProfileBox
                        project_image={obj.project_image ? obj.project_image.url : null}
                        project_name={obj[`project_name${Utils.getI18nSuffix(i18n)}`]}
                        funders={obj.funders}
                        donated_view={rewardsFilter === 'donated'}
                        style={{marginLeft: 10, marginRight: 10}}
                        onClickProject={redirect(`projects/${obj.id}`)}
                      />
                    ))}
                  </CustomCarousel>
                </Grid>
                <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', marginBottom: 28}}>
                  <div style={{flex: 1, marginRight: 20}}>
                    <ButtonFilterWithNumber
                      active={rewardsFilter === 'favorite' ? 1 : 0}
                      number={favoriteRewardCounter}
                      onClick={refreshRewards('favorite')}
                    >
                      {t('favorite_rewards_filter')}
                    </ButtonFilterWithNumber>
                  </div>
                  <div style={{flex: 1}}>
                    <ButtonFilterWithNumber
                      active={rewardsFilter === 'purchased' ? 1 : 0}
                      number={purchasedRewardCounter}
                      onClick={refreshRewards('purchased')}
                    >
                      {t('purchased_rewards_filter')}
                    </ButtonFilterWithNumber>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <CustomCarousel>
                    {rewardsList.map(obj => (
                      <ProductBox
                        reward_image={obj.reward_image && obj.reward_image.length > 0 ? obj.reward_image[0].url : null}
                        reward_name={obj[`reward_name${Utils.getI18nSuffix(i18n)}`]}
                        reward_points={obj.reward_points}
                        disabled={obj.disabled}
                        reward_stars={obj.reward_stars}
                        reward_bought_number={obj.reward_bought_number}
                        purchased_view={rewardsFilter === 'purchased'}
                        style={{marginLeft: 10, marginRight: 10}}
                        onClickReward={redirect(`rewards/${obj.id}`)}
                      />
                    ))}
                  </CustomCarousel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} lg={3} className={classes.leftColumn}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TotalPointsBox
                    totalPoints={props.user ? props.user.user_points : 0}
                    availablePoints={props.user ? props.user.user_redeemable_points : 0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyTotalDonations
                    donations={props.user ? props.user.user_total_donations/100 : 0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TotalNetworkBox
                    totalNetworkList={myTotalNetworkList}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*PURPLE BACKGROUND*/}
          <div style={{height: '100%', width: '100%', position: 'absolute', top: 150, backgroundColor: '#463871', zIndex: 1}}/>
        </Grid>
      </Hidden>
  
      {/*MOBILE SCREEN*/}
      <Hidden mdUp>
        <Grid item xs={12} style={{position: 'relative'}}>
          <Grid container style={{position: 'relative', zIndex: 100, paddingLeft: 20, paddingRight: 20}}>
            <Grid item xs={12}>
              <MobileTotalPointsBox
                totalPoints={props.user ? props.user.user_points : 0}
                availablePoints={props.user ? props.user.user_redeemable_points : 0}
              />
            </Grid>
            <Grid item xs={12} style={{marginBottom: '8px'}}>
              <MyTotalDonations
                donations={props.user ? props.user.user_total_donations/100 : 0}
              />
            </Grid>
            <Grid item xs={12} style={{marginBottom: '34px'}}>
              <TotalNetworkBox
                totalNetworkList={myTotalNetworkList}
              />
            </Grid>
  
            <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', marginBottom: 25}}>
              <CustomCarousel>
                <ActionPointsBox
                  style={{flex: 1, marginRight: 10}}
                  points={100}
                  title={t('action_title_1')}
                  action={t('action_button_1')}
                  onClick={onClickOpenModal}
                  img={addMemberImg}
                />
                <ActionPointsBox
                  style={{flex: 1, marginRight: 10, marginLeft: 10}}
                  points={'+'}
                  customText={t('action_custom_2')}
                  title={t('action_title_2')}
                  action={t('action_button_2')}
                  onClick={redirect('projects')}
                  img={donateHandsImg}
                />
                <ActionPointsBox
                  style={{flex: 1, marginLeft: 10}}
                  points={200}
                  title={t('action_title_3')}
                  action={t('action_button_3')}
                  onClick={redirect('edit-profile')}
                  img={greenBirthCakeImg}
                />
              </CustomCarousel>
            </Grid>
  
            <Grid item xs={12} style={{
              marginBottom: 12, fontSize: 26, color: 'white', fontWeight: 'lighter', lineHeight: '62px', textAlign: 'center'
            }}>
              {ReactHtmlParser(t('invited_members_title'))}
            </Grid>
            <Grid item xs={12} style={{marginBottom: 27, textAlign: 'center'}}>
              <ButtonRedFilledAction
                onClick={onClickOpenModal}
              >
                {t('button_invite_new_members')}
              </ButtonRedFilledAction>
            </Grid>
            <Grid item xs={12} style={{marginBottom:28}}>
              <Grid container spacing={1}>
                {invitedList.map(obj => (
                  <Grid item xs={12}>
                    <InvitedEmails invite={obj}/>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{marginBottom:13}}>
              <ButtonFilterWithNumber
                active={projectsFilter === 'favorite' ? 1 : 0}
                number={favoriteCounter}
                onClick={refreshProjects('favorite')}
              >
                {t('favorite_projects_filter')}
              </ButtonFilterWithNumber>
            </Grid>
            <Grid item xs={12} style={{marginBottom:22}}>
              <ButtonFilterWithNumber
                active={projectsFilter === 'donated' ? 1 : 0}
                number={donatedCounter}
                onClick={refreshProjects('donated')}
              >
                {t('donated_projects_filter')}
              </ButtonFilterWithNumber>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 29}}>
              <CustomCarousel>
                {projectsList.map(obj => (
                  <ProjectsProfileBox
                    project_image={obj.project_image ? obj.project_image.url : null}
                    project_name={obj[`project_name${Utils.getI18nSuffix(i18n)}`]}
                    funders={obj.funders}
                    donated_view={rewardsFilter === 'donated'}
                    style={{marginLeft: 10, marginRight: 10}}
                    onClickProject={redirect(`projects/${obj.id}`)}
                  />
                ))}
              </CustomCarousel>
            </Grid>
            
            <Grid item xs={12} style={{marginBottom:13}}>
              <ButtonFilterWithNumber
                active={rewardsFilter === 'favorite' ? 1 : 0}
                number={favoriteRewardCounter}
                onClick={refreshRewards('favorite')}
              >
                {t('favorite_rewards_filter')}
              </ButtonFilterWithNumber>
            </Grid>
            <Grid item xs={12} style={{marginBottom:22}}>
              <ButtonFilterWithNumber
                active={rewardsFilter === 'purchased' ? 1 : 0}
                number={purchasedRewardCounter}
                onClick={refreshRewards('purchased')}
              >
                {t('purchased_rewards_filter')}
              </ButtonFilterWithNumber>
            </Grid>
            
            <Grid item xs={12} style={{marginBottom: 29}}>
              <CustomCarousel>
                {rewardsList.map(obj => (
                  <ProductBox
                    reward_image={obj.reward_image && obj.reward_image.length > 0 ? obj.reward_image[0].url : null}
                    reward_name={obj[`reward_name${Utils.getI18nSuffix(i18n)}`]}
                    reward_points={obj.reward_points}
                    disabled={obj.disabled}
                    reward_stars={obj.reward_stars}
                    reward_bought_number={obj.reward_bought_number}
                    purchased_view={rewardsFilter === 'purchased'}
                    style={{marginLeft: 10, marginRight: 10}}
                    onClickReward={redirect(`rewards/${obj.id}`)}
                  />
                ))}
              </CustomCarousel>
            </Grid>
          </Grid>
          
          {/*PURPLE BACKGROUND*/}
          <div style={{height: '100%', width: '100%', position: 'absolute', top: 0, backgroundColor: '#463871', zIndex: 1}}/>
        </Grid>
      </Hidden>
      
      <Grid item xs={12}>
        <Footer history={props.history}/>
      </Grid>
    </Grid>
  );
}

export default connect(
  state => {
    return {
      user: state.user.user,
      invited_list: state.invitedMembers.invited_list,
      invited_total: state.invitedMembers.invited_total
    }
  },
  { updateInvitedMembers, updateUser }
)(Profile);