import React, {useRef, useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import moment from "moment";
import {useTranslation} from "react-i18next";

let targetRef = null;

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 2,
    boxShadow: '0 3px 10px 0 rgba(32, 24, 58, 0.47)',
    backgroundImage: 'linear-gradient(321deg, #20183a 10%, #503e89)',
    textAlign: 'center',
    color: 'white'
  }
}));

function useCurrentDimensions() {
  
  // save current window width in the state object
  let [dimensions, setDimensions] = useState({width: 0, height: 0});
  let [dimensionInitizalized, setDimensionInitizalized] = useState(0);
  
  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth,
          height: targetRef.current.offsetHeight
        });
      } else {
        setTimeout(resizeListener, 100);
      }
    };
    
    if (!dimensionInitizalized) {
      setDimensionInitizalized(1);
      resizeListener();
    }
    
    // set resize listener
    window.addEventListener('resize', resizeListener);
    
    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [])
  
  return dimensions;
}

export default function DonationTimerBox(props) {
  
  const [t, i18n] = useTranslation('total-points-box');
  
  const classes = useStyles();
  targetRef = useRef();
  let dimensions = useCurrentDimensions();
  
  return (
    <Grid className={classes.root} {...props} ref={targetRef} {...props}>
      <Grid container>
        <Grid item xs={12} style={{marginTop: 30, marginBottom: 14}}>
          <span style={{fontSize: 24, fontWeight: 'lighter'}}>{t('my_total_points')}</span>
        </Grid>
        <Grid item xs={12} style={{marginBottom: 21}}>
          <div style={{
            backgroundColor: 'rgba(255,255,255,0.1)',
            width: 0.66 * dimensions.width,
            height: 0.66 * dimensions.width,
            borderRadius: '100%',
            margin: 'auto',
            position: 'relative'
          }}>
            <div style={{
              backgroundColor: 'rgba(255,255,255,0.2)',
              width: 0.55 * dimensions.width,
              height: 0.55 * dimensions.width,
              borderRadius: '100%',
              position: 'absolute',
              top: 0.055 * dimensions.width,
              left: 0.055 * dimensions.width
            }}>
              <div style={{
                backgroundImage: 'linear-gradient(to bottom, #ffffff 5%, #cecece)',
                boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.17)',
                width: 0.45 * dimensions.width,
                height: 0.45 * dimensions.width,
                borderRadius: '100%',
                position: 'absolute',
                top: 0.05 * dimensions.width,
                left: 0.05 * dimensions.width
              }}>
                <div style={{
                  backgroundImage: 'linear-gradient(331deg, #20183a 5%, #503e89)',
                  width: 0.35 * dimensions.width,
                  height: 0.35 * dimensions.width,
                  borderRadius: '100%',
                  position: 'absolute',
                  top: 0.05 * dimensions.width,
                  left: 0.05 * dimensions.width
                }}>
                  <div style={{
                    position: 'absolute',
                    margin: 'auto',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                  }}>
                    <span style={{fontSize: 0.12 * dimensions.width, lineHeight: `${0.12 * dimensions.width}px`}}>{props.totalPoints}</span><br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} style={{marginBottom: 25, fontSize: 20, fontWeight: 'lighter'}}>
          {t('redeemable')}: <span style={{color: '#86b964'}}>{props.availablePoints}</span>
        </Grid>
      </Grid>
    </Grid>
  )
}