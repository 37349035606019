import React from "react";
import WhiteContentBox from "../../components/ContentBox/WhiteContentBox";
import {Grid, Hidden} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {FaRegSadTear} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  container: {
    padding: '50px 70px 50px 70px'
  },
  sorry_title: {
    color: '#f83131',
    fontSize: 36,
    marginTop: 15,
    textAlign: 'center'
  },
  sorry_text: {
    color: '#20183a',
    fontSize: 16,
    textAlign: 'center',
    marginTop: 17
  },
  sorry_sad_face: {
    color: '#20183a',
    fontSize: '100px',
    textAlign: 'center',
    marginTop: 17
  }
}))

export default (props) => {
  
  const [t, i18n] = useTranslation('time-over');
  
  const classes = useStyles();
  
  const action = props.location.state ? props.location.state.action : t('add_member');
  const hours = props.location.state ? props.location.state.hours : '48';
  
  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header history={props.history}/>
      </Grid>
    
      <Grid item xs={3}/>
    
      <WhiteContentBox item xs={6}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.sorry_title}>{t('sorry')}</Grid>
          <Grid item xs={12} className={classes.sorry_text}>{t('you_failed',{action, hours})}</Grid>
          <Grid item xs={12} className={classes.sorry_sad_face}>
            <FaRegSadTear />
          </Grid>
        </Grid>
      </WhiteContentBox>
    
      <Grid item xs={3}/>
    
      <Grid item xs={12}>
        <Footer history={props.history}/>
      </Grid>
    </Grid>
  );
}
