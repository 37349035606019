import React from "react";
import Radio from "@material-ui/core/Radio";
import withStyles from "@material-ui/core/styles/withStyles";

export default withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);

