import React, {useState} from 'react';
import { Grid } from '@material-ui/core';
import {connect} from "react-redux";

// Actions (REDUX)
import {updateUser} from "../../redux/actions";

//Components
import ButtonConfirm from '../../components/Buttons/ButtonConfirm';
import ContentBox from '../../components/ContentBox/MainContentBox';
import TextBox from '../../components/Inputs/TextBox';
import Header from '../../components/Header/Header';

// Services
import UserService from '../../services/UserService';

//Images
import hands from "../../assets/hands_reference_code.png";
import Footer from "../../components/Footer/Footer";
import {useTranslation} from "react-i18next";

const Login = (props) => {
  
  const [t, i18n] = useTranslation('login');

  // ERROR HANDLING
  const [error_message, setErrorMessage] = useState(undefined);

  const [email, setUserEmail] = useState('');
  const [user_password, setUserPassword] = useState('');

  const loginFunction = async (event) => {

    event.preventDefault();
    
    setErrorMessage(undefined);

    // Login (with user and password)
    try {
      await UserService.Login(email, user_password);
      props.updateUser();
    } catch (err) {
      setErrorMessage(t('error_message_login'));
      console.error(err);
    }
  };
  
  const forgotPasswordClick = (event) => {
    event.preventDefault();
    props.history.push('/forgot-password');
  }
  
  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header
          history={props.history}
          disableLogin
          enableSignup
        />
      </Grid>
      <Grid item xs={false} sm={2} md={3}/>
      <ContentBox item xs={12} sm={8} md={6} style={{
        margin: '0px 15px 100px 15px',
        padding: 26
      }}>
        <Grid container>
          <Grid item xs={12} style={{marginTop: 20, marginBottom: 23, textAlign: 'center'}}>
            <img src={hands} style={{height: '80px'}}/>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 30, lineHeight: '50px', color: '#857d9c', fontWeight: 'lighter'}}>
              {t('header_msg_1')}
            </span>
          </Grid>
          <Grid item xs={12} style={{marginBottom: 36, textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 36, lineHeight: '50px', color: '#FFFFFF'}}>
              {t('header_msg_2')}
            </span>
          </Grid>
          <Grid item xs={12} style={{marginBottom: 20, textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 20, color: '#FF0000'}}>
              {error_message}
            </span>
          </Grid>
          <form onSubmit={loginFunction} style={{width: '100%'}}>
            <Grid item xs={12} style={{marginBottom: 19}}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextBox
                    placeholder={t('email')}
                    tabIndex={3}
                    required
                    value={email}
                    onChange={event => {
                      setUserEmail(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    placeholder={t('password_placeholder')}
                    type={'password'}
                    tabIndex={7}
                    required
                    value={user_password}
                    onChange={event => {
                      setUserPassword(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonConfirm
                style={{width: '100%'}}
                type={'submit'}
              >
                {t('button_login')}
              </ButtonConfirm>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center', marginTop: 30}}>
              <a href={'#'} style={{paddingLeft: '10px', color: 'white'}} onClick={forgotPasswordClick}>
                {t('forgot_password')}
              </a>
            </Grid>
          </form>
        </Grid>
      </ContentBox>
      <Grid item xs={false} sm={2} md={3}/>
      <Grid item xs={12}/>
      <Grid item xs={12}>
        <Footer history={props.history} just_copyright={true}/>
      </Grid>
    </Grid>
  );
}

export default connect(
  null,
  { updateUser }
)(Login);
