import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0 6px 15px 0 rgba(0, 0, 0, 0.32)',
    backgroundImage: 'linear-gradient(316deg, #487a46 40%, #89bc65)',
    color: '#FFFFFF',
    textTransform: 'none',
    borderRadius: 2,
    fontSize: 16,
    width: 54,
    height: 55,
    padding: 0,
    minWidth: 54,
    maxWidth: 54,
    marginRight: 5,
    marginLeft: 11,
    marginTop: 1,
    zIndex: 3000,
    '&:hover': {
      backgroundImage: 'linear-gradient(316deg, #487a46 0%, #89bc65)',
    }
  }
}))

export default (props) => {
  
  const classes = useStyles();
  
  return (
    <Button className={classes.root} {...props}>
      {props.children}
    </Button>
  )
}