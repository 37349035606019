import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid, Hidden} from '@material-ui/core';
import {AiFillCaretUp, AiOutlineSearch} from 'react-icons/ai'
import Expand from "react-expand-animated";

// Components
import ButtonFilter from "../Buttons/ButtonFilter";

// Image
import noPicture from '../../assets/no-picture.png';
import zebra from '../../assets/background-zebra.svg';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url('${zebra}')`,
    backgroundColor: '#18122b',
    color: 'white',
    boxShadow: '0 3px 10px 0 rgba(7, 7, 7, 0.3)',
    borderRadius: 2,
    padding: 16
  },
  myTotalDiv: {
    flex: 1,
    fontSize: 18,
    lineHeight: '30px'
  },
  donationsDiv: {
    flex: 1,
    fontSize: 24,
    lineHeight: '30px'
  },
  amountDiv: {
    flex: 1,
    fontSize: 30,
    lineHeight: '30px',
    textAlign: 'right'
  },
  divider: {
    borderBottom: '1px solid rgba(255,255,255,0.2)',
    width: '100%',
    marginBottom: 40
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20
  },
  listRow: {
    backgroundColor: '#FFFFFF',
    borderRadius: 2,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.15)',
    fontSize: 15,
    color: '#20183a',
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 15px 4px 4px'
  },
  userName: {
    lineHeight: '40px',
    flex: 1
  },
  greenSpan: {
    color: '#63a461',
    lineHeight: '40px'
  },
  profileImg: {
    width: 40,
    height: 40,
    objectFit: 'contain',
    borderRadius: '100%',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '10px'
  },
  openButton: {
    position: 'absolute',
    bottom: '-40px',
    right: '10px',
    color: 'white',
    height: 42,
    maxWidth: 42,
    minWidth: 42,
    borderRadius: 2,
    boxShadow: '0 6px 15px 0 rgba(0, 0, 0, 0.32)',
    backgroundImage: 'linear-gradient(316deg, #487a46 30%, #89bc65)'
  }
}))

export default (props) => {
  
  const [t, i18n] = useTranslation('total-network-box');
  
  const classes = useStyles();
  
  const networkList = props.totalNetworkList ? props.totalNetworkList : [];
  const [open, setOpen] = useState(true);
  const transitions = ["height", "opacity", "background"];
  const [typeFilter, setTypeFilter] = useState(undefined);
  
  let rowsCounter = 0;
  const MAX_ROWS = 10;
  
  const totalMembers = networkList.filter(obj => {
    let returnObj = !typeFilter ||
      (obj.is_new && typeFilter === 'new') ||
      (obj.is_active && typeFilter === 'active');
    return (returnObj);
  }).length;
  
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} style={{marginBottom: 16, position: 'relative'}}>
        <Grid container style={{padding: 9}}>
          <Grid item xs={8} style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{flex: 1}}/>
            <div className={classes.myTotalDiv}>{t('my_total_network')}</div>
            <div className={classes.donationsDiv}>{t('members')}</div>
            <div style={{flex: 1}}/>
          </Grid>
          <Grid item xs={4} style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{flex: 1}}/>
            <div className={classes.amountDiv}>{totalMembers}</div>
            <div style={{flex: 1}}/>
          </Grid>
        </Grid>
        <Hidden mdUp>
          <Button className={classes.openButton} onClick={() => {
            setOpen(!open);
          }}> <AiFillCaretUp style={{
            transition: `transform 0.3s linear`,
            transform: `rotate(${open ? 0 : 180}deg)`
          }} />
          </Button>
        </Hidden>
      </Grid>
      <Expand
        styles={{open: {width: '100%'}}}
        open={open}
        duration={300}
        transitions={transitions}
      >
        <Grid item xs={12} className={classes.divider}/>
        <Grid item xs={12} className={classes.buttonsContainer}>
          <div style={{flex: 1, marginRight: '10px'}}>
            <ButtonFilter active={typeFilter==='new'} onClick={() => {
              if(typeFilter === 'new') {
                return setTypeFilter(undefined);
              }
              setTypeFilter('new');
            }}>
              {t('new')}
            </ButtonFilter>
          </div>
          <div style={{flex: 1, marginRight: '10px'}}>
            <ButtonFilter active={typeFilter==='active'} onClick={() => {
              if(typeFilter === 'active') {
                return setTypeFilter(undefined);
              }
              setTypeFilter('active');
            }}>
              {t('active')}
            </ButtonFilter>
          </div>
          <div>
            <ButtonFilter style={{paddingLeft: '10px',paddingRight: '10px'}}>
              <AiOutlineSearch style={{fontSize: 23}}/>
            </ButtonFilter>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {networkList.filter(obj => {
              let returnObj = !typeFilter ||
                (obj.is_new && typeFilter === 'new') ||
                (obj.is_active && typeFilter === 'active');
              if(returnObj) {
                rowsCounter++;
              }
              if(rowsCounter > MAX_ROWS) {
                return false;
              }
              return (returnObj)
            }).map(obj => (
              <Grid item xs={12}>
                <div className={classes.listRow}>
                  <div>
                    <span style={{display: 'inline-block', height: '100%', verticalAlign: 'middle'}}/>
                    <img className={classes.profileImg} src={obj.profile_image ? obj.profile_image.url : noPicture}/>
                  </div>
                  <div className={classes.userName}>
                    {obj.username}
                  </div>
                  <div>
                    <span className={classes.greenSpan}>{obj.users_added}</span>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Expand>
    </Grid>
  )
}