import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

// Pages
// LOGIN PAGES
import Login from './domains/Login/Login';
import ForgotPassword from "./domains/Login/ForgotPassword";
import ResetPassword from "./domains/Login/ResetPassword";

// SIGN-UP PAGES
import FirstDonation from './domains/SignUp/FirstDonation'
import FirstIndication from './domains/SignUp/FirstIndication'
import ReferenceCode from './domains/SignUp/ReferenceCode'
import SendIndication from './domains/SignUp/SendIndication'
import SignUp from './domains/SignUp/SignUp'
import TimeOver from "./domains/SignUp/TimeOver";

// PROJECTS PAGES
import Projects from "./domains/Projects/Projects";
import SingleProject from "./domains/Projects/SingleProject";

// PROFILE PAGES
import Profile from "./domains/Profile/Profile";

// SUPPORT PAGE
import AboutUs from "./domains/AboutUs/AboutUs";
import ContactUs from "./domains/ContactUs/ContactUs";

// REWARDS PAGES
import Rewards from "./domains/Rewards/Rewards";
import SingleReward from "./domains/Rewards/SingleReward";
import RewardOrderCompleted from "./domains/Rewards/RewardOrderCompleted";
import ConfirmEmail from "./domains/SignUp/ConfirmEmail";
import EditProfile from "./domains/Profile/EditProfile";
import PaymentCompleted from "./domains/Projects/PaymentCompleted";

const theme = createMuiTheme({
  palette: {
    default: {
      main: '#857d9c'
    },
    primary: {
      main: '#f83031'
    },
    secondary: {
      main: '#49387F'
    }
  }
});

const CorrectRedirect = (props) => {
  props.history.push(`/`)
  
  const redirectToTarget = () => {
    props.history.push(`/`)
  }
  
  return (
    <div>
      Wrong Page
      <button onClick={redirectToTarget}>Redirect</button>
    </div>
  )
}

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Switch>
          {/*LOGIN*/}
          <Route path={"/login"} component={Login}/>
          <Route path={"/forgot-password"} component={ForgotPassword}/>
          <Route path={"/reset-password"} component={ResetPassword}/>
          {/*SIGN-UP FLOW*/}
          <Route path={"/confirm-email"} component={ConfirmEmail}/>
          <Route path={"/first-donation"} component={FirstDonation}/>
          <Route path={"/first-indication"} component={FirstIndication}/>
          <Route path={"/send-indication"} component={SendIndication}/>
          <Route path={"/signup"} component={SignUp}/>
          <Route path={"/time-over"} component={TimeOver}/>
          {/*PROJECTS PAGES*/}
          <Route path={"/projects/:id_project"} component={SingleProject}/>
          <Route path={"/projects"} component={Projects}/>
          <Route path={"/payment-completed"} component={PaymentCompleted}/>
          {/*PROFILE PAGES*/}
          <Route path={"/profile"} component={Profile}/>
          <Route path={"/edit-profile"} component={EditProfile}/>
          {/*REWARDS PAGES*/}
          <Route path={"/rewards/:id_reward"} component={SingleReward}/>
          <Route path={"/rewards"} component={Rewards}/>
          <Route path={"/reward-order-completed"} component={RewardOrderCompleted}/>
          {/*SUPPORT PAGES*/}
          <Route path={"/about-us"} component={AboutUs}/>
          <Route path={"/contact-us"} component={ContactUs}/>
          {/*INITIAL PAGE*/}
          <Route exact path={"/"} component={ReferenceCode}/>
          <Route path={"*"} component={CorrectRedirect}/>
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
