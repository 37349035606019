import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.19)',
    color: '#FFFFFF',
    textTransform: 'none',
    borderRadius: 2,
    fontSize: 26,
    padding: '30px 10px',
    lineHeight: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '& > span > div': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    }
  }
}))

export default (props) => {
  
  const classes = useStyles();
  
  return (
    <Button {...props} className={`${classes.root} ${props.className ? props.className : ''}`}>
      <div>
        <div style={{flex: 3, lineHeight: '26px'}}>
          {props.children}
        </div>
        {
          props.icon ?
            <div style={{flex: 1}}>
              <props.icon/>
            </div>
            : null
        }
      </div>
    </Button>
  )
}