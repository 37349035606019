import React, {useRef} from "react";
import { Grid } from '@material-ui/core';

import chain from '../../assets/chain.png';
import WhiteContentBox from "./WhiteContentBox";
import YellowLoadingCircle from "../Displays/YellowLoadingCircle";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

let targetRef = null;

const useStyles = makeStyles(theme => ({
  favoriteHeart: {
    position: 'absolute',
    top: '9px',
    right: '9px',
    textAlign: 'center',
    fontSize: '25px',
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    },
    '&.favorited': {
      color: theme.palette.primary.main,
        cursor: 'pointer'
    }
  },
  projectsBoxContainer: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(70,56,113,0.2)'
    }
  }
}))

export default (props) => {
  
  const [t, i18n] = useTranslation('projects-box');
  
  const classes = useStyles();
  
  targetRef = useRef();
  
  const FIXED_WIDTH = 250;
  
  const onClickProject = props.blocked ? () => {} : () => {
    props.onClickProject(props.id_project);
  }
  
  return (
    <WhiteContentBox ref={targetRef} {...props} onClick={onClickProject}>
      <Grid container className={classes.projectsBoxContainer}>
        <Grid item xs={12} style={{padding: '25px 0'}}>
          <div style={{
            backgroundColor: props.blocked ? '#d8d8d8' : '#503e89',
            width: 0.7 * FIXED_WIDTH,
            height: 0.7 * FIXED_WIDTH,
            borderRadius: '100%',
            margin: 'auto',
            position: 'relative',
            boxShadow: 'inset 0 0 8px 0 rgba(0, 0, 0, 0.5)'
          }}>
            {!props.blocked ?
              <YellowLoadingCircle
                centerX={0.35*FIXED_WIDTH}
                centerY={0.35*FIXED_WIDTH}
                radius={0.313*FIXED_WIDTH}
                progress={props.completed}
                width={7}
              />
               : null }
            <div style={{
              backgroundColor: 'white',
              boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.52)',
              width: 0.55 * FIXED_WIDTH,
              height: 0.55 * FIXED_WIDTH,
              borderRadius: '100%',
              position: 'absolute',
              top: 0.075 * FIXED_WIDTH,
              left: 0.075 * FIXED_WIDTH
            }}>
              <img src={props.projectImg} style={{
                maxWidth: '80%',
                maxHeight: '60%',
                objectFit: 'contain',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                filter: props.blocked ? 'grayscale(100%)' : undefined
              }}/>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'center', color: '#e21818', fontSize: 24, marginBottom: 23}}>
          {props.projectName}
        </Grid>
        <Grid item xs={12}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{flex:1, display: 'flex', flexDirection: 'column', textAlign: 'center', paddingBottom: 24}}>
              <div style={{flex: 1, fontSize: 26, color: props.blocked ? '#9b9b9b' : '#503e89'}}>{Math.round(props.completed*1000)/10}%</div>
              <div style={{flex: 1, fontSize: 18, color: '#9b9b9b'}}>{t('completed')}</div>
            </div>
            <div style={{borderLeft: 'solid 1px #e5e5e5'}}/>
            <div style={{flex:1, display: 'flex', flexDirection: 'column', textAlign: 'center', paddingBottom: 24}}>
              <div style={{flex: 1, fontSize: 26, color: props.blocked ? '#9b9b9b' : '#63a461'}}>{props.funders}</div>
              <div style={{flex: 1, fontSize: 18, color: '#9b9b9b'}}>{t('funders')}</div>
            </div>
          </div>
        </Grid>
        {props.blocked ?
          <img style={{position: "absolute", height: '100%', width: '100%'}} src={chain}/> :
          null
        }
        {
          props.onFavorite && !props.blocked ?
            <div
              className={`${classes.favoriteHeart} ${props.is_favorite ? 'favorited' : null}`}
              onClick={(event) => {
                event.stopPropagation();
                props.onFavorite(props.id_project);
              }}
            >
              {props.is_favorite ? <AiFillHeart/> : <AiOutlineHeart/>}
            </div> :
            null
        }
      </Grid>
    </WhiteContentBox>
  )
}