import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Hidden} from '@material-ui/core';
import {RiTruckLine} from "react-icons/ri";

const useStyles = makeStyles(themes => ({
  root: {
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffc100',
    fontSize: 24,
    fontWeight: "bold",
    lineHeight: '64px',
  }
}))

export default (props) => {
  const classes = useStyles();
  
  const text = props.text;
  const IconClass = props.icon;
  
  return (
    <Grid className={classes.root}>
      <Hidden xsDown>
        <IconClass style={{fontSize: 40, marginRight: 10}}/>
        {text}
      </Hidden>
      <Hidden smUp>
        <div style={{flex: 1}}>
          <IconClass style={{fontSize: 40, marginRight: 10}}/>
        </div>
        <div style={{flex: 3}}>
          <p style={{lineHeight: '35px', marginRight: '10px'}}>{text}</p>
        </div>
      </Hidden>
      
    </Grid>
  )
}