import GenericModel from './generic_model/GenericModel';
import axios from "axios";
import Config from "../config/config";
import ActivityService from "./ActivityService";
import UserService from "./UserService";
import moment from "moment";

export default class ProjectService extends GenericModel {
  API = '/projects';
  static API = '/projects';
  
  constructor(props) {
    super(props);
  }
  
  static async donate(id_user, id_project, donation_value, isFirst) {
    let promisesList = [];
    promisesList.push((new ActivityService({
      user: {
        id: id_user
      },
      project: {
        id: id_project
      },
      activity_value: donation_value*100,
      activity_type: 'donation',
      activity_points: donation_value*100,
      first_donation: isFirst
    })).save());
  
    if(isFirst) {
      promisesList.push(UserService.update(id_user, {
        has_donated: 1,
        add_member_expiration: moment().add(48,'hours').format('YYYY-MM-DDTHH:mm:ss.ZZZ')
      }));
    }
  
    promisesList.push(new Promise((resolve, reject) => {
      ProjectService.get(id_project).then(projectObj => {
        let donated_users = projectObj.donated_users;
        donated_users.push({
          id: id_user
        });
        ProjectService.update(id_project, {donated_users: donated_users}).then((response) => {
          return resolve();
        }).catch(err => {
          reject(err);
        })
      }).catch(err => {
        reject(err);
      })
    }))
    
    return Promise.all(promisesList);
  }
  
  static async update(id_project, fields) {
    
    const {data} = await axios.put(Config.API_ADDRESS + '/projects/' + id_project,fields,{
      headers: GenericModel.insertAuthHeader()
    });
    
    return data;
  }
  
  static async favorite(id_project) {
    
    await axios.get(Config.API_ADDRESS + '/projects/' + id_project + '/favorite',{
      headers: GenericModel.insertAuthHeader()
    });
    
    return;
  }
}