import React from 'react';
import { Button,Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    border: '1px solid rgba(255,255,255,0.2)',
    borderRadius: 2,
    backgroundColor: 'none',
    textTransform: 'none',
    padding: '14px 15px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(99,164,97,0.2)',
      border: '1px solid #63a461',
      color: '#C6DEC6',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'none',
      },
    },
    '&.active': {
      backgroundColor: '#63a461',
      boxShadow: '0 5px 10px 0 rgba(32, 24, 58, 0.34)'
    },
    '&.active:hover': {
      color: '#C6DEC6',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'none',
      },
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '0 10px',
    fontSize: 18,
    fontWeight: 'lighter'
  },
  filterTitle: {
    textAlign: 'left',
    flex: 7
  },
  filterNumber: {
    textAlign: 'right',
    flex: 1
  }
}))

export default (props) => {
  const classes = useStyles();
  
  return (
    <Button className={[classes.root,props.active ? 'active' : undefined]} {...props}>
      <Grid className={classes.container}>
        <Grid className={classes.filterTitle}>
          {props.children}
        </Grid>
        <Grid className={classes.filterNumber}>
          {props.number}
        </Grid>
      </Grid>
    </Button>
  );
}