import GenericModel from './generic_model/GenericModel';
import axios from "axios";
import Config from "../config/config";
import Utils from "../helpers/utils";

export default class RewardService extends GenericModel {
  API = '/rewards';
  static API = '/rewards';
  
  constructor(props) {
    super(props);
  }
  
  static async favorite(id_reward) {
    
    await axios.get(Config.API_ADDRESS + '/rewards/' + id_reward + '/favorite',{
      headers: GenericModel.insertAuthHeader()
    });
    
    return;
  }
}