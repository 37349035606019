import React from "react";
import { ButtonBack,ButtonNext } from 'pure-react-carousel';
import {IoIosArrowBack,IoIosArrowForward} from "react-icons/io";

export default (props) => {
  
  const style = {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.05)',
    color: '#503e89',
    border: 'none',
    borderRadius: 2,
    width: '34px',
    height: '34px',
    fontSize: 18,
    lineHeight: '34px',
    float: (props.back ? 'left' : 'right'),
    marginRight: (props.back ? '10px' : '0'),
    marginLeft: (props.back ? '0' : '10px')
  }
  
  return (props.back ?
      <ButtonBack style={style}><IoIosArrowBack style={{margin: '7px auto 0 auto'}}/></ButtonBack> :
      <ButtonNext style={style}><IoIosArrowForward style={{margin: '7px auto 0 auto'}}/></ButtonNext>
  )
}