// Libraries
import React, {useEffect, useRef, useState} from 'react';
import {Grid, Hidden} from '@material-ui/core';
import { PayPalButton  } from 'react-paypal-button-v2';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";

// Actions (REDUX)
import { updateUser } from "../../redux/actions";

// Components
import DonationTimerBox from "../../components/ContentBox/DonationTimerBox";
import MoneyTextBox from "../../components/Inputs/MoneyTextBox";
import SideContentBox from '../../components/ContentBox/WhiteContentBox';
import ImageContentBox from '../../components/ContentBox/ImageContentBox';
import Header from '../../components/Header/Header';
import TransitionsModal from "../../components/Modal/TransitionsModal";

// Images
import coinImg from '../../assets/100_coin.svg';
import SideIconTextBox from "../../components/ContentBox/SideIconTextBox";
import ProjectService from "../../services/ProjectService";
import Footer from "../../components/Footer/Footer";
import ReactHtmlParser from "react-html-parser";
import WhiteContentBox from "../../components/ContentBox/WhiteContentBox";
import {makeStyles} from "@material-ui/core/styles";
import bulletPoint from "../../assets/bullet-point.png";
import moment from "moment";
import {useTranslation} from "react-i18next";
import CustomSelector from "../../components/Inputs/CustomSelector";
import Utils from "../../helpers/utils";
import InviteService from "../../services/InviteService";

const useStyles = makeStyles(theme => ({
  project_container_mobile: {
    padding: '39px 20px',
  },
  project_container: {
    height: '70vh',
    width: '80vw',
    overflowY:'auto',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 'lighter',
    '&::-webkit-scrollbar': {
      webkitAppearance: 'none'
    },
    '&::-webkit-scrollbar:vertical': {
      width: '11px'
    },
    '&::-webkit-scrollbar:horizontal': {
      height: '11px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '2px solid white', /* should match background, can't be transparent */
      backgroundColor: 'rgba(0, 0, 0, .5)'
    },
    '& > h2': {
      color: '#503e89',
      fontWeight: 'normal',
      fontSize: 36,
      margin: 0
    },
    '& figure': {
      margin: '10px 0 10px 0',
      position: 'relative'
    },
    '& video': {
      width: '100%'
    },
    '& strong': {
      color: '#63a461',
      fontWeight: 'bold'
    },
    '& .image-style-align-right': {
      width: '50%',
      float: 'right'
    },
    '& .image-style-align-left': {
      width: '50%',
      float: 'left'
    },
    '& img': {
      width: '100%'
    },
    '& figcaption': {
      color: 'white',
      position: 'absolute',
      bottom: '0',
      left: '0',
      padding: '5px',
      fontSize: '15px',
      backgroundColor: 'rgba(0,0,0,0.6)'
    },
    '& ul': {
      listStyleImage: `url('${bulletPoint}')`
    },
    '& li': {
      paddingLeft: '15px',
      marginBottom: '20px'
    }
  }
}))

function useInterval(callback, delay) {
  const savedCallback = useRef();
  
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const FirstDonation = (props) => {
  
  const [t, i18n] = useTranslation('first-donation');
  
  const classes = useStyles();
  
  const [donation_value, setDonationValue] = useState('');
  const [isLoadingActive, setIsLoadingActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  
  const [listProjects, setListProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState({id: '', project_name: ''});
  const [searchProject, setSearchProject] = useState(0);
  if(!searchProject) {
    setSearchProject(1);
    ProjectService.query({is_active: 1}).then(projects => {
      setListProjects(projects);
      if(projects.length > 0) {
        setCurrentProject(projects[0]);
      }
    })
  }
  
  const paypalSuccessFunction = async (details, data) => {
    try {
      await ProjectService.donate(props.user.id, currentProject.id, donation_value, true)
      await InviteService.acceptInvite();
      setIsLoadingActive(false);
      props.updateUser();
    } catch(err) {
      setIsLoadingActive(false);
      console.error(err);
    }
  }
  
  useInterval(() => {
    if(props.user && props.user.donate_expiration) {
      let donateExpirationTime = moment(props.user.donate_expiration, 'YYYY-MM-DDTHH:mm:ss').valueOf();
      if(donateExpirationTime < (new Date()).getTime()) {
        props.history.push({
          pathname: '/time-over',
          state: {
            action: 'donate',
            hours: '2'
          }
        });
      }
    }
  }, 1000);
  
  return (
    <Grid container style={{height: '100vh'}} spacing={2}>
      {
        isLoadingActive ?
          <LoadingOverlay
            styles={{
              wrapper: {
                position: 'fixed',
                height: '100vh',
                width: '100vw',
                zIndex: 10000
            }
            }}
            active={isLoadingActive}
            spinner
            text={t('processing_payment')}
          >
          </LoadingOverlay> : null
      }
      
      <TransitionsModal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}>
        
        <WhiteContentBox className={`${classes.project_container} ${classes.project_container_mobile}`}>
          {ReactHtmlParser(currentProject[`project_text${Utils.getI18nSuffix(i18n)}`])}
        </WhiteContentBox>
        
      </TransitionsModal>
      
      <Grid item xs={12}>
        <Header
          history={props.history}
        />
      </Grid>
      {/*Project Information*/}
      <Hidden smDown>
        <Grid item md={3}>
          <Grid container>
            <SideIconTextBox item xs={12} style={{marginLeft: 50, marginBottom: 10}} img={coinImg}>
              {t('every_dollar')}
              <span style={{
                color: '#63a461',
                fontWeight: 'bold'
              }}>
                {t('100_points')}
              </span>
            </SideIconTextBox>
            <SideContentBox item xs={12} style={{marginLeft: 50}}>
              <Grid container>
                <Grid xs={12} style={{
                  textAlign: 'center',
                  fontSize: 20,
                  marginTop: 10,
                  padding: 26,
                  borderBottom: '1px dashed #b4b49a'
                }}>
                  {t('all_funds')}<span style={{
                  color: '#63a461',
                  fontWeight: 'bold'
                }}>
                    100%
                  </span>{t('funding_project')}
                </Grid>
                <Grid xs={12} style={{marginTop: '26px', marginLeft: '26px', marginRight: '26px'}}>
                  <CustomSelector
                    options={listProjects}
                    id_field={'id'}
                    name_field={`project_name${Utils.getI18nSuffix(i18n)}`}
                    value={currentProject}
                    onChange={setCurrentProject}
                  />
                </Grid>
                <Grid item  xs={12} style={{padding: '26px', cursor: 'pointer'}} onClick={() => {
                  setOpenModal(true);
                }}>
                  <ImageContentBox src={currentProject.project_image ? currentProject.project_image.url : null} minHeight={'170px'}/>
                </Grid>
              </Grid>
            </SideContentBox>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdDown>
        <Grid lg={1}/>
      </Hidden>
      {/*Donation*/}
      <Grid item sm={12} md={6} lg={4} style={{textAlign: 'center'}}>
        <Grid container style={{maxWidth: '80%', textAlign: 'center', margin: 'auto'}}>
          <Grid xs={12} style={{color: 'white', marginBottom: 12}}>
            <span style={{fontWeight: 'lighter', fontSize: 30}}>{t('welcome')}</span><span
            style={{fontWeight: 'bold', fontSize: 30}}>WildGoals</span>
          </Grid>
          <Grid xs={12} style={{marginBottom: 43}}>
            <span style={{fontWeight: 'lighter', color: '#63a461', fontSize: '30px'}}>
              "{props.user.user_full_name ? props.user.user_full_name.split(' ')[0] : props.user.username}"
            </span>
          </Grid>
          <Grid xs={12} style={{textAlign: 'center', alignContent: 'center', marginBottom: 42}}>
            <div style={{borderBottom: '1px solid rgba(255,255,255,0.13)', width: '50px', margin: 'auto'}}/>
          </Grid>
          <Grid xs={12} style={{textAlign: 'center', alignContent: 'center'}}>
            <span style={{color: '#e21818', fontSize: 36}}>{ReactHtmlParser(t('select_project'))}</span>
          </Grid>
          <Grid xs={12} style={{textAlign: 'center', alignContent: 'center'}}>
            <span style={{color: '#e21818', fontSize: 36}}>&</span>
          </Grid>
          <Grid xs={12} style={{textAlign: 'center', alignContent: 'center', marginBottom: 42}}>
            <span style={{color: 'white', fontSize: 36}}>{ReactHtmlParser(t('make_pledge'))}</span>
          </Grid>
          <Grid xs={12}>
            <MoneyTextBox
              value={donation_value}
              onChange={event => setDonationValue(event.target.value)}
            />
          </Grid>
          <Grid xs={12} style={{margin: '24px 0', display: (donation_value > 0 ? 'initial' : 'none')}}>
            <PayPalButton
              style={{
                size: 'responsive',
                shape: 'rect',
                color: 'silver',
                layout: 'vertical',
                label: 'paypal'
              }}
              amount={donation_value}
              onCancel={() => {
                alert(t('payment_cancelled'));
                setIsLoadingActive(false);
              }}
              onError={() => {
                alert(t('error_payment'));
                setIsLoadingActive(false);
              }}
              createOrder={(data, actions) => {
                setIsLoadingActive(true);
                return actions.order.create({
                  purchase_units: [{
                    amount: {
                      value: donation_value
                    }
                  }]
                });
              }}
              onSuccess={paypalSuccessFunction}
              options={{
                clientId: process.env.REACT_APP_PAYPAL_TOKEN,
                currency: process.env.REACT_APP_PAYPAL_CURRENCY
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Hidden mdDown>
        <Grid lg={1}/>
      </Hidden>
      {/*Timer information*/}
      <Hidden smDown>
        <Grid item xs={12} md={3}>
          <DonationTimerBox deadline={props.user.donate_expiration}
                            style={{height: '100%', marginRight: 50}}/>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12}>
          <DonationTimerBox deadline={props.user.donate_expiration}
                            style={{maxWidth: '80%', margin: 'auto'}}/>
        </Grid>
      </Hidden>
      {/*Project Information for Mobile*/}
      <Hidden mdUp>
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <Grid container style={{maxWidth: '80%', margin: 'auto'}}>
            <SideIconTextBox item xs={12} style={{marginBottom: 10}} img={coinImg}>
              {t('every_dollar')}
              <span style={{
                color: '#63a461',
                fontWeight: 'bold'
              }}>
                {t('100_points')}
              </span>
            </SideIconTextBox>
            <SideContentBox item xs={12}>
              <Grid container>
                <Grid xs={12} style={{
                  textAlign: 'center',
                  fontSize: 20,
                  marginTop: 10,
                  padding: 26,
                  borderBottom: '1px dashed #b4b49a'
                }}>
                  {t('all_funds')}<span style={{
                  color: '#63a461',
                  fontWeight: 'bold'
                }}>
                    100%
                  </span>{t('funding_project')}
                </Grid>
  
                <Grid xs={12} style={{marginTop: '26px', marginLeft: '26px', marginRight: '26px'}}>
                  <CustomSelector
                    options={listProjects}
                    id_field={'id'}
                    name_field={`project_name${Utils.getI18nSuffix(i18n)}`}
                    value={currentProject}
                    onChange={setCurrentProject}
                  />
                </Grid>
                <Grid xs={12} style={{padding: '26px', cursor: 'pointer'}} onClick={() => {
                  setOpenModal(true);
                }}>
                  <ImageContentBox src={currentProject.project_image ? currentProject.project_image.url : null} minHeight={'170px'}/>
                </Grid>
              </Grid>
            </SideContentBox>
          </Grid>
        </Grid>
      </Hidden>
      <Grid item xs={12} style={{minHeight: '100px'}}/>
      <Grid item xs={12}>
        <Footer history={props.history} just_copyright={true}/>
      </Grid>
    </Grid>
  );
}

export default connect(
  state => {
    return state.user
  },
  { updateUser }
)(FirstDonation);