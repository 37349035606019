import React from "react";
import Slider from '@material-ui/core/Slider';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF'
    }
  }
});

export default (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Slider {...props}/>
    </ThemeProvider>
  );
}