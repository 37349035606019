import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import ShippingTextBox from "./ShippingTextBox";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '&.Mui-error > input': {
      backgroundColor: '#ffcacf !important',
      '&::placeholder': {
        color: '#FF0000 !important',
        opacity: 1
      },
    },
    '& > div': {
      color: '#857d9c',
      padding: '17px 0px 18px 10px',
      borderRadius: 2,
      position: 'relative',
      border: '1px solid #857d9c',
      fontSize: 16,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderColor: theme.palette.secondary.main,
      }
    }
  },
  selectorPlaceholder: {
    position: 'absolute',
    zIndex: 1000,
    lineHeight: '54px',
    left: 20,
    color: '#c4c4c4'
  }
}));

export default function CustomSelector(props) {
  
  const classes = useStyles();
  
  const id_field = props.id_field ? props.id_field : 'id';
  const name_field = props.name_field ? props.name_field : 'name';
  
  const handleChange = (event) => {
    console.log(event.target.value);
    setIdSelected(event.target.value);
    if(props.onChange) {
      let selectedEntity = undefined;
      let list = props.options.filter(obj => obj[id_field] === event.target.value);
      if(list.length > 0) {
        selectedEntity = list[0];
      }
      props.onChange(selectedEntity);
    }
  }
  
  const [idSelected, setIdSelected] = useState('');
  
  if(props.value && idSelected !== props.value[id_field]) {
    setIdSelected(props.value[id_field])
  }
  
  return (
    <div style={{position: 'relative'}}>
      <Select
        className={classes.root}
        value={idSelected}
        onChange={handleChange}
        variant="outlined"
        input={<ShippingTextBox/>}
      >
        {props.placeholder ?
          <option value="" disabled>
            {props.placeholder}
          </option> :
          null
        }
        {props.options && props.options.map ? props.options.map(obj => (
          <MenuItem value={obj[id_field]} selected={idSelected === obj[id_field]}>
            {obj[name_field]}
          </MenuItem>
        )) : null
        }
      </Select>
    </div>
  )
}