import React, {useState, useEffect} from "react";
import {Grid, Hidden} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ButtonFilter from "../Buttons/ButtonFilter";
import WhiteSlider from "./WhiteSlider";
import ColorPicker from "./ColorPicker";
import SearchTextBox from "./SearchTextBox";
import {RiTruckLine} from "react-icons/ri";
import PromotionMessage from "../Displays/PromotionMessage";
import Expand from "react-expand-animated";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 2,
    overflow: "hidden"
  },
  filtersContainer: {
    backgroundColor: '#5a4896',
    display: 'flex',
    flexDirection: 'row'
  },
  filtersContainerMobile: {
    backgroundColor: '#5a4896',
    display: 'flex',
    flexDirection: 'column'
  },
  filterObject: {
    padding: 28,
    flex: 1
  },
  title: {
    fontSize: 18,
    color: 'white',
    marginBottom: 20,
    position: "relative",
    height: 24
  },
  divider: {
    borderRight:'1px solid rgba(255,255,255,0.2)',
    height: '140px'
  },
  dividerHorizontal: {
    borderBottom:'1px solid rgba(255,255,255,0.2)',
    width: '100%',
    height: '1px'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  buttonFilter: {
    flex: 1
  }
}));

const GENDER = {
  UNISEX: 'unisex',
  MEN: 'men',
  LADIES: 'ladies'
}

let timer = null;

export default (props) => {
  
  const [t, i18n] = useTranslation('search-filters');
  
  const classes = useStyles();
  
  const [[sliderLow, sliderHigh], setSliderValues] = useState([0, 100000000000]);
  const [chosenColor, setChosenColor] = useState(undefined);
  const [gender, setGender] = useState(undefined);
  const [searchText, setSearchText] = useState(undefined);
  const [openFilter, setOpenFilter] = useState(false);
  const transitions = ["height", "opacity", "background"];
  
  useEffect(() => {
    if(timer) clearTimeout(timer);
    timer = setTimeout(() => {
      onChangeFilters();
    }, 1000)
  },[searchText, sliderLow, sliderHigh, chosenColor, gender]);
  
  function valuetext(value) {
    return `${value} Points`;
  }
  
  const onChangeFilters = props.onChangeFilters ? () => {
    const values = {
      searchText,
      sliderLow,
      sliderHigh,
      chosenColor,
      gender
    }
    
    props.onChangeFilters(values);
  } : () => {};
  
  const onChangeFilter = (setFunction, value) => {
    setFunction(value);
  }
  
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <SearchTextBox
          placeholder={t('search')}
          value={searchText}
          onChange={event => {
            setSearchText(event.target.value);
          }}
          openFilter={() => {
            setOpenFilter(!openFilter)
          }}
          clearFilter={() => {
            setSliderValues([props.minValuePoints, props.maxValuePoints]);
            setChosenColor(undefined);
            setGender(undefined);
            setSearchText(undefined);
          }}
        />
      </Grid>
      <Expand
        open={openFilter}
        duration={300}
        transitions={transitions}
        styles={{open: {
            width: '100%'
          },close: {
            width: '100%'
          }}}
      >
        <Hidden smDown>
          <Grid item xs={12} className={classes.filtersContainer}>
            <Grid className={classes.filterObject}>
              <Grid item xs={12} className={classes.title}>
                <span style={{float: 'left'}}>{t('points_range')}</span>
                <span style={{float: 'right', fontSize: 14, paddingTop: 4}}>{sliderLow} - {sliderHigh}
                  <span style={{
                    color: '#8576b6',
                    marginLeft: '8px'
                  }}>
                  {t('points')}
                </span>
              </span>
              </Grid>
        
              <Grid item xs={12}>
                <WhiteSlider
                  min={props.minValuePoints || 0}
                  max={props.maxValuePoints || 10000}
                  value={[sliderLow, sliderHigh]}
                  onChange={(event, newValue) => onChangeFilter(setSliderValues,newValue)}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={valuetext}
                />
              </Grid>
            </Grid>
            <div className={classes.divider}/>
            <Grid className={classes.filterObject}>
              <Grid item xs={12} className={classes.title}>
                {t('color')}
              </Grid>
              <Grid item xs={12}>
                <ColorPicker
                  chosenColor={chosenColor}
                  onColorChange={(color) => onChangeFilter(setChosenColor,color)}
                />
              </Grid>
            </Grid>
            <div className={classes.divider}/>
            <Grid className={classes.filterObject}>
              <Grid container>
                <Grid item xs={12} className={classes.title}>
                  {t('gender')}
                </Grid>
                <Grid item xs={12} className={classes.buttonsContainer}>
                  <div className={classes.buttonFilter} style={{marginRight: 4}}>
                    <ButtonFilter
                      active={(gender === GENDER.UNISEX)}
                      onClick={() => onChangeFilter(setGender,GENDER.UNISEX)}
                    >{t('unisex')}</ButtonFilter>
                  </div>
                  <div className={classes.buttonFilter} style={{marginLeft: 4, marginRight: 4}}>
                    <ButtonFilter
                      active={(gender === GENDER.MEN)}
                      onClick={() => onChangeFilter(setGender,GENDER.MEN)}
                    >{t('men')}</ButtonFilter>
                  </div>
                  <div className={classes.buttonFilter} style={{marginLeft: 4}}>
                    <ButtonFilter
                      active={(gender === GENDER.LADIES)}
                      onClick={() => onChangeFilter(setGender,GENDER.LADIES)}
                    >{t('ladies')}</ButtonFilter>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12} className={classes.filtersContainerMobile}>
            <Grid className={classes.filterObject}>
              <Grid item xs={12} className={classes.title}>
                <span style={{float: 'left'}}>{t('points_range')}</span>
                <span style={{float: 'right', fontSize: 14, paddingTop: 4}}>{sliderLow} - {sliderHigh}
                  <span style={{
                    color: '#8576b6',
                    marginLeft: '8px'
                  }}>
                  {t('points')}
                </span>
              </span>
              </Grid>
        
              <Grid item xs={12}>
                <WhiteSlider
                  min={props.minValuePoints || 0}
                  max={props.maxValuePoints || 10000}
                  value={[sliderLow, sliderHigh]}
                  onChange={(event, newValue) => onChangeFilter(setSliderValues,newValue)}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={valuetext}
                />
              </Grid>
            </Grid>
            <div className={classes.dividerHorizontal}/>
            <Grid className={classes.filterObject}>
              <Grid item xs={12} className={classes.title}>
                {t('color')}
              </Grid>
              <Grid item xs={12}>
                <ColorPicker
                  chosenColor={chosenColor}
                  onColorChange={(color) => onChangeFilter(setChosenColor,color)}
                />
              </Grid>
            </Grid>
            <div className={classes.dividerHorizontal}/>
            <Grid className={classes.filterObject}>
              <Grid container>
                <Grid item xs={12} className={classes.title}>
                  {t('gender')}
                </Grid>
                <Grid item xs={12} className={classes.buttonsContainer}>
                  <div className={classes.buttonFilter} style={{marginRight: 4}}>
                    <ButtonFilter
                      active={(gender === GENDER.UNISEX)}
                      onClick={() => onChangeFilter(setGender,GENDER.UNISEX)}
                    >{t('unisex')}</ButtonFilter>
                  </div>
                  <div className={classes.buttonFilter} style={{marginLeft: 4, marginRight: 4}}>
                    <ButtonFilter
                      active={(gender === GENDER.MEN)}
                      onClick={() => onChangeFilter(setGender,GENDER.MEN)}
                    >{t('men')}</ButtonFilter>
                  </div>
                  <div className={classes.buttonFilter} style={{marginLeft: 4}}>
                    <ButtonFilter
                      active={(gender === GENDER.LADIES)}
                      onClick={() => onChangeFilter(setGender,GENDER.LADIES)}
                    >{t('ladies')}</ButtonFilter>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Expand>
      <Grid item xs={12}>
        <PromotionMessage icon={RiTruckLine} text={t('free_shipping')}/>
      </Grid>
    </Grid>
  );
}