// Libraries
import React, {useState} from 'react';
import {Grid, Hidden} from '@material-ui/core';
import Carousel from "react-elastic-carousel";

// Components
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ProjectsBox from "../../components/ContentBox/ProjectsBox";
import Top5Box from "../../components/ContentBox/Top5Box";
import RecentActivitiesTable from "../../components/Tables/RecentActivitiesTable";

// Services
import UserService from "../../services/UserService";
import ActivityService from "../../services/ActivityService";
import ProjectService from "../../services/ProjectService";
import RewardService from "../../services/RewardService";
import {useTranslation} from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import Utils from "../../helpers/utils";


// Firebase
import firebase from 'firebase';
import makeStyles from "@material-ui/core/styles/makeStyles";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_SENDER_ID}`,
  appID: `${process.env.REACT_APP_FIREBASE_APP_ID}`
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const realtime_db = firebase.database();
const ref = realtime_db.ref('activities');

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 439, itemsToShow: 2 },
  { width: 900, itemsToShow: 3 }
];

const useStyles = makeStyles(theme => ({
  tablesContainer: {
    position: 'relative',
    zIndex: 100,
    maxWidth: '90%',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%'
    }
  }
}))

export default function Projects(props) {
  
  const [t, i18n] = useTranslation('projects');
  const classes = useStyles();
  
  // Loads Projects Information
  const [projectsList, setProjectsList] = useState([]);
  const [projectsSearch, setProjectsSearch] = useState(0);
  const loadProjects = () => {
    ProjectService.query().then(setProjectsList).catch(err => {
      console.error(err);
    });
  }
  
  if (!projectsSearch) {
    setProjectsSearch(1);
    loadProjects();
  }
  
  const activityToTable = (obj) => ({
    profile_image: obj.user && obj.user.profile_image ? obj.user.profile_image.url : null,
    username: obj.user ? obj.user.username : '',
    points: obj.activity_points,
    activity: obj.activity_type,
    value: obj.activity_value/100,
    project: (obj.project ? obj.project[`project_name${Utils.getI18nSuffix(i18n)}`] : null)
  });
  
  // Loads Recent Activities Information
  const [activityElements, setActivityElements] = useState([]);
  const [activitySearch, setActivitySearch] = useState(0);
  if (!activitySearch) {
    setActivitySearch(1);
    
    // Clear all event callbacks before attaching a new Callback
    try {
      ref.off('value');
    } catch (err) {
      console.warn(err);
    }
    
    ref.orderByChild('created_at').limitToLast(50).on('value', snapshot => {
      let arrayObj = snapshot.val();
      let array = [];
      for (let param in arrayObj) {
        if(arrayObj.hasOwnProperty(param)) {
          array.push(arrayObj[param]);
        }
      }
      array.sort((a,b) => b.created_at - a.created_at);
      setActivityElements(array.map(activityToTable));
    });
    // ActivityService.query({'_sort':'created_at:DESC','_limit':10}).then((activities) => {
    //   let activityElements = activities ? activities.map(activityToTable) : []
    //
    //   setActivityElements(activityElements);
    //
    //
    // }).catch(err => {
    //   console.error(err);
    // });
  }
  
  // Loads Top 5 Users
  const [userElements, setUserElements] = useState([]);
  const [top5UserSearch, setTop5UserSearch] = useState(0);
  if (!top5UserSearch) {
    setTop5UserSearch(1);
    UserService.top5Users().then((top5Users) => {
      let userElements = top5Users.map(obj => ({
        profile_image: obj.profile_image ? obj.profile_image.url : null,
        username: obj.username,
        value: obj.total_value/100
      }));
      setUserElements(userElements);
    }).catch(err => {
      console.error(err);
    });
  }
  
  // Loads Top 5 Networks
  const [networkElements, setNetworkElements] = useState([]);
  const [networkSearch, setNetworkSearch] = useState(0);
  if (!networkSearch) {
    setNetworkSearch(1);
    UserService.top5Networks().then((top5Networks) => {
      let networkElements = top5Networks.map(obj => ({
        profile_image: obj.profile_image ? obj.profile_image.url : null,
        username: obj.username,
        points: obj.count_users
      }));
      setNetworkElements(networkElements);
    }).catch(err => {
      console.error(err);
    });
  }
  
  const onFavorite = (id_project) => {
    ProjectService.favorite(id_project).then(() => {
      loadProjects();
    }).catch(err => {
      console.error(err);
    });
  }
  
  const redirectToProjectPage = (id_project) => {
    props.history.push('/projects/' + id_project);
  }
  
  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header history={props.history}/>
      </Grid>
  
      <Grid item xs={12} style={{fontSize: 36, color: 'white', textAlign: 'center', marginBottom: 44}}>
        {t('title')}
      </Grid>
      
      <Grid item xs={12} style={{position: 'relative', marginBottom: 150}}>
  
        <Grid container spacing={3} style={{position: 'relative', zIndex: 100, width: '100%', margin: 'auto'}}>
          <Grid item xs={12}>
            <Carousel breakPoints={breakPoints}>
              {projectsList.map(obj => (
                <ProjectsBox
                  style={{margin: 16}}
                  projectImg={obj.project_image ? obj.project_image.url : null}
                  projectName={obj[`project_name${Utils.getI18nSuffix(i18n)}`]}
                  is_favorite={obj.is_favorite}
                  completed={obj.value_raised/obj.project_goal}
                  funders={obj.funders}
                  blocked={obj.project_blocked}
                  id_project={obj.id}
                  onFavorite={onFavorite}
                  onClickProject={redirectToProjectPage}
                />
              ))}
            </Carousel>
          </Grid>
        </Grid>
        
        <Grid container spacing={3} className={classes.tablesContainer}>
          <Hidden smDown>
            <Grid item xs={12} style={{fontSize: 26, color: "white", fontWeight: 'lighter'}}>
              {ReactHtmlParser(t('recent_activities'))}
            </Grid>
            <Grid item xs={8}>
              <RecentActivitiesTable elements={activityElements}/>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Top5Box entity={t('users')} elements={userElements}/>
                </Grid>
                <Grid item xs={12}>
                  <Top5Box entity={t('network')} elements={networkElements}/>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={12} style={{fontSize: 26, color: "white", fontWeight: 'lighter'}}>
              {ReactHtmlParser(t('recent_activities'))}
            </Grid>
            <Grid item xs={12}>
              <RecentActivitiesTable elements={activityElements} mobile/>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Top5Box entity={t('users')} elements={userElements} mobile/>
                </Grid>
                <Grid item xs={12}>
                  <Top5Box entity={t('network')} elements={networkElements} mobile/>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        
        {/*PURPLE BACKGROUND*/}
        <div style={{height: '100%', width: '100%', position: 'absolute', top: 150, backgroundColor: '#463871', zIndex: 1}}/>
      </Grid>
      
      <Grid item xs={12}>
        <Footer history={props.history}/>
      </Grid>
    </Grid>
  );
}