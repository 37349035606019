import Utils from '../../helpers/utils';
import moment from 'moment';
import axios from 'axios';
import Config from '../../config/config.js';

class GenericModel {

  constructor(object, API) {
    for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
        this[prop] = object[prop];
      }
    }
    this.API = API;
  }

  static insertAuthHeader = (headerObj) => {
    try {
      if (!headerObj) {
        headerObj = {};
      }
      const userToken = localStorage.getItem('Authorization');
      if(userToken) {
        headerObj['Authorization'] = userToken;
      }
      return headerObj;
    } catch (err) {
      throw Error(err);
    }
  };

  static get = async function (id, query) {

    try {

      let queryString = Utils.serialize(query);

      let authHeader = await GenericModel.insertAuthHeader();
      let apiAddr = Config.API_ADDRESS + this.API + '/' + id;

      const response = await fetch(apiAddr + '?' + queryString, {
        headers: authHeader
      });

      const body = await response.json();

      if (response.status >= 400) throw new Error(body.message);

      return new GenericModel(body, this.API);
    } catch (err) {
      throw Error(err.message);
    }
  };
  
  static query = async function (query) {
    try {
      let queryString = Utils.serialize(query);
      
      let authHeader = await GenericModel.insertAuthHeader();
      
      const response = await fetch(Config.API_ADDRESS + this.API + '?' + queryString, {
        headers: authHeader
      });
      
      if (response.status >= 400) {
        let body2 = await response.text();
        throw Error(body2);
      }
      
      const body = await response.json();
      
      let ans = {};
      
      if (body instanceof Array) {
        
        ans = [];
        
        for (let i = 0; i < body.length; i++) {
          ans.push(new GenericModel(body[i], this.API));
        }
      } else {
        ans = new GenericModel(body, this.API);
      }
      
      return ans;
    } catch (err) {
      throw Error(err.message);
    }
  };
  
  static count = async function (query) {
    try {
      let queryString = Utils.serialize(query);
      
      let authHeader = await GenericModel.insertAuthHeader();
      
      const response = await fetch(Config.API_ADDRESS + this.API + '/count?' + queryString, {
        headers: authHeader
      });
      
      if (response.status >= 400) {
        let body2 = await response.text();
        throw Error(body2);
      }
      
      const body = await response.json();
      
      return body;
    } catch (err) {
      throw Error(err.message);
    }
  };
  
  save = async function () {

    let that = this;

    let authHeader = {};

    authHeader = await GenericModel.insertAuthHeader({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    });

    let response = null;

    let objToSend = {};
    for (let param in this) {
      if(this.hasOwnProperty(param) && param !== 'API') {
        objToSend[param] = this[param];
      }
    }
    
    response = await axios({
      url: Config.API_ADDRESS + that.API,
      method: 'POST',
      headers: authHeader,
      data: JSON.stringify(objToSend)
    });

    return new GenericModel(response.data, that.API);
  };

  remove = function () {
    return new Promise(async (resolve, reject) => {
      this.deleted_date = moment().format('YYYYMMDDHHmmss');

      let authHeader = await GenericModel.insertAuthHeader({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      });

      fetch(Config.API_ADDRESS + this.API, {
        method: 'POST',
        headers: authHeader,
        body: JSON.stringify(this)
      }).then(async function () {
        return resolve();
      }).catch(function (err) {
        return reject(err.message);
      });
    });
  };
}

export default GenericModel;