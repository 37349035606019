import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {AiOutlineMenu} from "react-icons/ai";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.19)',
    color: '#FFFFFF',
    textTransform: 'none',
    borderRadius: 2,
    fontSize: 16,
    width: 54,
    minWidth: 54,
    maxWidth: 54,
    height: 56,
    float: 'right',
    padding: '15px 0px',
    zIndex: 3000,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '& > span > div': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    }
  },
  icon: {
    fontSize: 26
  }
}))

export default (props) => {
  
  const classes = useStyles();
  
  return (
    <Button className={classes.root} {...props}>
      <AiOutlineMenu className={classes.icon}/>
    </Button>
  )
}