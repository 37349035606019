import { UPDATE_INVITED_MEMBERS, UPDATE_INVITED_MEMBERS_ERROR, UPDATE_USER, UPDATE_USER_ERROR } from "./actionTypes";

import ActivityService from "../services/ActivityService";
import InviteService from "../services/InviteService";
import UserService from '../services/UserService';

import store from './store';

export const updateInvitedMembers = () => ((dispatch) => {
  
  if(!UserService.isUserLoggedIn()) {
    dispatch({ type: UPDATE_INVITED_MEMBERS, payload: {
      invited_list: [],
      invited_total: 0,
    }});
    return {
      invited_list: [],
      invited_total: 0,
    };
  }
  
  const userObj = store.getState().user.user ? store.getState().user.user : {};
  
  const inviteRequest = InviteService.query({'user.id': userObj.id, 'invite_accepted_null':true});
  
  function onSuccess(success) {
    dispatch({ type: UPDATE_INVITED_MEMBERS, payload: {
        invited_list: success,
        invited_total: success.length,
    }});
    return {
      invited_list: success,
      invited_total: success.length,
    };
  }
  function onError(error) {
    dispatch({ type: UPDATE_INVITED_MEMBERS_ERROR, error });
    return error;
  }
  inviteRequest.then(onSuccess, onError);
});

export const updateUser = () => ((dispatch) => {
  
  if(!UserService.isUserLoggedIn()) {
    dispatch({ type: UPDATE_USER, payload: {} });
    return {};
  }
  
  function onSuccess(success) {
    dispatch({ type: UPDATE_USER, payload: success });
    return success;
  }
  
  function onError(error) {
    dispatch({ type: UPDATE_USER_ERROR, error });
    return error;
  }
  
  UserService.me().then(onSuccess).catch(onError);
});