import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 2,
    boxShadow: '0 6px 15px 0 rgba(0, 0, 0, 0.32)',
    backgroundImage: 'linear-gradient(335deg, #487a46 37%, #89bc65)',
    textAlign: 'center',
    color: 'white'
  }
}));

export default (props) => {
  
  const classes = useStyles();
  
  return (
    <Grid className={classes.root} {...props}>
      {props.children}
    </Grid>
  )
}