import GenericModel from './generic_model/GenericModel';
import axios from "axios";
import Config from "../config/config";
import Utils from "../helpers/utils";
import ProjectService from "./ProjectService";

export default class RewardService extends GenericModel {
  API = '/reward-purchases';
  static API = '/reward-purchases';
  
  constructor(props) {
    super(props);
  }
  
  static async purchaseReward(id_reward, id_user, info) {
    
    let rewardPurchaseObj = {
      ...info,
      user: id_user,
      reward: id_reward
    }
  
    await axios.post(Config.API_ADDRESS + '/reward-purchases/', rewardPurchaseObj, {
      headers: GenericModel.insertAuthHeader()
    });
    
    return;
  }
}