import React, {useState} from 'react';
import ButtonConfirm from '../../components/Buttons/ButtonConfirm';
import ContentBox from '../../components/ContentBox/MainContentBox';
import TextBox from '../../components/Inputs/TextBox';
import Header from '../../components/Header/Header';
import { Grid } from '@material-ui/core';
import hands from '../../assets/hands_reference_code.png';
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import qs from "qs";
import {connect} from "react-redux";
import {updateUser} from "../../redux/actions";
import {useTranslation} from "react-i18next";

const ResetPassword = (props) => {
  
  const [t, i18n] = useTranslation('reset-password');
  
  const code = qs.parse(props.location.search, { ignoreQueryPrefix: true }).code;
  
  // ERROR HANDLING
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error_message, setErrorMessage] = useState('');
  
  const sendResetPassword = async (event) => {
    
    event.preventDefault();
  
    const {data : {
      jwt
    }} = await axios.post(`${process.env.REACT_APP_API_ADDRESS}/auth/reset-password`,{
      code,
      password,
      passwordConfirmation
    });
    
    if(jwt !== undefined) {
      localStorage.setItem('Authorization','Bearer ' + jwt);
    }
    
    props.updateUser();
  }
  
  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header
          history={props.history}
          enableLogin
        />
      </Grid>
      <Grid item xs={false} sm={2} md={3}/>
      <ContentBox item xs={12} sm={8} md={6} style={{
        margin: '0px 15px 100px 15px',
        padding: '26px'
      }}>
        <Grid container>
          <Grid item xs={12} style={{marginBottom: 23, textAlign: 'center'}}>
            <img src={hands} style={{height: '80px'}}/>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 30, lineHeight: '50px', color: '#857d9c', fontWeight: 'lighter'}}>
              {t('choose_new_password')}
            </span>
          </Grid>
          <Grid item xs={12} style={{marginBottom: 20, textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 20, color: '#FF0000'}}>
              {error_message}
            </span>
          </Grid>
          <form onSubmit={sendResetPassword} style={{width: '100%'}}>
            <Grid item xs={12} style={{marginBottom: 20}}>
              <TextBox
                required
                placeholder={t('password_placeholder')}
                type={'password'}
                tabIndex={1}
                value={password}
                onChange={event => {
                  setPassword(event.target.value);
                }}/>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 20}}>
              <TextBox
                required
                placeholder={t('confirm_password_placeholder')}
                type={'password'}
                tabIndex={1}
                value={passwordConfirmation}
                onChange={event => {
                  setPasswordConfirmation(event.target.value);
                }}/>
            </Grid>
            <Grid item xs={12}>
              <ButtonConfirm
                style={{width: '100%'}}
                type={'submit'}
              >
                {t('button_confirm')}
              </ButtonConfirm>
            </Grid>
          </form>
        </Grid>
      </ContentBox>
      <Grid item xs={false} sm={2} md={3}/>
      <Grid item xs={12}/>
      <Grid item xs={12}>
        <Footer history={props.history} just_copyright={true}/>
      </Grid>
    </Grid>
  );
}

export default connect(
  null,
  { updateUser }
)(ResetPassword);