import React from "react";
import { Grid } from '@material-ui/core';

import WhiteContentBox from "./WhiteContentBox";
import {makeStyles} from "@material-ui/core/styles";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import ImageContentBox from "./ImageContentBox";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  cardContainer: {
    padding: 20,
    '&:hover': {
      backgroundColor: 'rgba(70,56,113,0.2)',
      cursor: 'pointer'
    }
  },
  titleGrid: {
    marginTop: '4px',
    marginBottom: '20px',
    fontSize: '18px',
    color: '#503e89',
    fontWeight: 'bold'
  },
  imageGrid: {
    textAlign: 'center',
    marginBottom: '17px',
    height: '160px',
    lineHeight: '160px',
    display: 'table-cell',
    verticalAlign: 'middle',
    '& > img': {
      margin: '0 auto',
      maxWidth: '80%',
      maxHeight: '80%',
      contentFit: 'contain'
    }
  },
  fundersGrid: {
    textAlign: 'center',
    marginBottom: '5px',
    fontSize: '22px'
  },
  fundersTitleGrid: {
    textAlign: 'center',
    fontSize: '16px',
    color: '#9b9b9b'
  },
  greenSpan: {
    color: '#63a461'
  },
  favoriteHeart: {
    position: 'absolute',
    top: '9px',
    right: '9px',
    textAlign: 'center',
    fontSize: '25px',
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    },
    '&.favorited': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  }
}));

export default (props) => {
  
  const [t, i18n] = useTranslation('projects-profile-box');
  
  const classes = useStyles();
  
  const onClickProject = props.onClickProject ? props.onClickProject : () => {};
  
  return (
    <WhiteContentBox className={classes.root} {...props} onClick={onClickProject}>
      <Grid container className={classes.cardContainer}>
        <Grid item xs={12} className={classes.titleGrid}>
          {props.project_name}
        </Grid>
        <Grid item xs={12} className={classes.imageGrid}>
          <ImageContentBox src={props.project_image} minHeight={'170px'}/>
        </Grid>
        <Grid item xs={12} className={classes.fundersGrid}>
          <span className={classes.greenSpan}>{props.funders}</span>
        </Grid>
        <Grid item xs={12} className={classes.fundersTitleGrid}>
          {t('funders')}
        </Grid>
      </Grid>
      {
        props.onFavorite ?
          <div
            className={`${classes.favoriteHeart} ${props.is_favorite ? 'favorited' : null}`}
            onClick={() => {
              props.onFavorite(props.id_project);
            }}
          >
            {props.is_favorite ? <AiFillHeart/> : <AiOutlineHeart/>}
          </div> :
          null
      }
    </WhiteContentBox>
  )
}