import React, {useState} from 'react';
import ButtonConfirm from '../../components/Buttons/ButtonConfirm';
import ContentBox from '../../components/ContentBox/MainContentBox';
import TextBox from '../../components/Inputs/TextBox';
import Header from '../../components/Header/Header';
import { Grid } from '@material-ui/core';
import UserService from '../../services/UserService';
import hands from '../../assets/hands_reference_code.png'
import InviteService from "../../services/InviteService";
import Footer from "../../components/Footer/Footer";
import axios from "axios"
import {useTranslation} from "react-i18next";

export default function ForgotPassword(props) {
  
  const [t, i18n] = useTranslation('forgot-password');

  // ERROR HANDLING
  const [email, setEmail] = useState('');
  const [error_message, setErrorMessage] = useState('');
  
  const sendForgotPasswordEmail = async (event) => {
    
    event.preventDefault();
    
    await axios.post(`${process.env.REACT_APP_API_ADDRESS}/auth/forgot-password`,{
      email
    });
    
    alert(t('successfull_message'));
    setEmail('');
  }
  
  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header
          history={props.history}
          enableLogin
        />
      </Grid>
      <Grid item xs={false} sm={2} md={3}/>
      <ContentBox item xs={12} sm={8} md={6} style={{
        margin: '0px 15px 100px 15px',
        padding: '26px'
      }}>
        <Grid container>
          <Grid item xs={12} style={{marginBottom: 23, textAlign: 'center'}}>
            <img src={hands} style={{height: '80px'}}/>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 30, lineHeight: '50px', color: '#857d9c', fontWeight: 'lighter'}}>
              {t('message')}
            </span>
          </Grid>
          <Grid item xs={12} style={{marginBottom: 20, textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 20, color: '#FF0000'}}>
              {error_message}
            </span>
          </Grid>
          <form onSubmit={sendForgotPasswordEmail} style={{width: '100%'}}>
            <Grid item xs={12} style={{marginBottom: 20}}>
              <TextBox
                placeholder={'E-mail'}
                required
                tabIndex={1}
                value={email}
                onChange={event => {
                  setEmail(event.target.value);
                }}/>
            </Grid>
            <Grid item xs={12}>
              <ButtonConfirm
                style={{width: '100%'}}
                type={'submit'}
              >
                {t('button_confirm')}
              </ButtonConfirm>
            </Grid>
          </form>
        </Grid>
      </ContentBox>
      <Grid item xs={false} sm={2} md={3}/>
      <Grid item xs={12}/>
      <Grid item xs={12}>
        <Footer history={props.history} just_copyright={true}/>
      </Grid>
    </Grid>
  );
}