import React from 'react';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { Input } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '&.Mui-error > input': {
      backgroundColor: '#ffcacf !important',
      '&::placeholder': {
        color: '#FF0000 !important',
        opacity: 1
      },
    },
    '& > input': {
      backgroundColor: '#fdfcfc',
      color: '#503e89',
      boxShadow: '0 15px 20px 0 rgba(238, 90, 56, 0.04)',
      padding: '20px',
      borderRadius: 2,
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: 20,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      }
    }
  }
}));

export default function TextBox(props) {

  const classes = useStyles();

  return (
    <Input
      className={classes.root}
      {...props}
      placeholder={props.placeholder + (props.required ? ' *' : '')}
    />
  )
}