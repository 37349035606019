import React, {useRef, useState} from "react";
import { Grid } from '@material-ui/core';
import Expand from "react-expand-animated";

import WhiteContentBox from "./WhiteContentBox";
import YellowLoadingCircle from "../Displays/YellowLoadingCircle";
import {makeStyles} from "@material-ui/core/styles";
import ButtonRedFilledAction from "../Buttons/ButtonRedFilledAction";
import {PayPalButton} from "react-paypal-button-v2";
import ProjectService from "../../services/ProjectService";
import {connect} from "react-redux";
import {updateUser} from "../../redux/actions";
import MoneyTextBox from "../Inputs/MoneyTextBox";
import zebra from "../../assets/background-zebra.svg";
import {useTranslation} from "react-i18next";

let targetRef = null;

const useStyles = makeStyles(theme => ({
  favoriteHeart: {
    position: 'absolute',
      top: '9px',
      right: '9px',
      textAlign: 'center',
      fontSize: '25px',
      fontWeight: 'bold',
      '&:hover': {
      color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    '&.favorited': {
      color: theme.palette.primary.main,
        cursor: 'pointer'
    }
  }
}))

const transitions = ["height", "opacity", "background"];

const SingleProjectsBox = (props) => {
  
  const [t, i18n] = useTranslation('single-projects-box');
  
  const [donationClicked, setDonationClicked] = useState(false);
  const [donation_value, setDonationValue] = useState(0);
  
  const setIsLoadingActive = props.setIsLoadingActive ? props.setIsLoadingActive : () => {};
  
  targetRef = useRef();
  
  const FIXED_WIDTH = 250;
  
  const openPaypalOptions = () => {
    setDonationClicked(true);
  }
  
  const paypalSuccessFunction = (details, data) => {
    ProjectService.donate(props.user.id, props.id_project, donation_value).then(() => {
      setIsLoadingActive(false);
      props.updateUser();
      if(props.history) {
        props.history.push('/payment-completed')
      }
    }).catch(err => {
      setIsLoadingActive(false);
      console.error(err);
    })
  }
  
  return (
    <WhiteContentBox ref={targetRef} {...props}>
      <Grid container style={{position: 'relative'}}>
        <Grid item xs={12} style={{position: 'relative', height: '100px', marginTop: '20px'}}>
          <img src={props.projectImg} style={{
            maxWidth: '80%',
            maxHeight: '60%',
            objectFit: 'contain',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            filter: props.blocked ? 'grayscale(100%)' : undefined
          }}/>
        </Grid>
        <Grid item xs={12} style={{padding: '25px 0'}}>
          <div style={{
            backgroundColor: props.blocked ? '#d8d8d8' : '#503e89',
            width: 0.7 * FIXED_WIDTH,
            height: 0.7 * FIXED_WIDTH,
            borderRadius: '100%',
            margin: 'auto',
            position: 'relative',
            boxShadow: 'inset 0 0 8px 0 rgba(0, 0, 0, 0.5)'
          }}>
            {!props.blocked ?
              <YellowLoadingCircle
                centerX={0.35*FIXED_WIDTH}
                centerY={0.35*FIXED_WIDTH}
                radius={0.313*FIXED_WIDTH}
                progress={props.completed}
                width={7}
              />
               : null }
            <div style={{
              backgroundColor: 'white',
              boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.52)',
              width: 0.55 * FIXED_WIDTH,
              height: 0.55 * FIXED_WIDTH,
              borderRadius: '100%',
              position: 'absolute',
              top: 0.075 * FIXED_WIDTH,
              left: 0.075 * FIXED_WIDTH
            }}>
              <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                filter: props.blocked ? 'grayscale(100%)' : undefined,
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center'
              }}>
                <div style={{flex: 1, fontSize: 40, color: props.blocked ? '#9b9b9b' : '#503e89'}}>{Math.round(props.completed*1000)/10}%</div>
                <div style={{flex: 1, fontSize: 18, color: '#9b9b9b'}}>{t('completed')}</div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{paddingBottom: 24, textAlign: 'center'}}>
            <span style={{fontSize: 26, color: '#63a461'}}>{props.funders}<span style={{fontSize: 18, marginLeft: '5px', color: '#9b9b9b'}}>{t('funders')}</span></span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Expand
            open={!donationClicked}
            duration={300}
            transitions={transitions}
          >
          <ButtonRedFilledAction
            onClick={openPaypalOptions}
            style={{width: '100%'}}
          >
            {t('make_donation')}
          </ButtonRedFilledAction>
          </Expand>
        </Grid>
        <Grid item xs={12} style={{
          backgroundImage: `url('${zebra}')`,
          backgroundColor: '#18122b',
          color: 'white'
        }}>
          <Expand
            open={donationClicked}
            duration={300}
            transitions={transitions}
          >
            <Grid container style={{marginTop: 20}}>
              <Grid xs={12} style={{margin: 10}}>
                <MoneyTextBox
                  value={donation_value}
                  onChange={event => setDonationValue(event.target.value)}
                />
              </Grid>
              <Grid xs={12} style={{margin: 10}}>
                {donation_value ? <PayPalButton
                  style={{
                    size: 'responsive',
                    shape: 'rect',
                    color: 'silver',
                    layout: 'vertical',
                    label: 'paypal'
                  }}
                  amount={donation_value}
                  onCancel={() => {
                    alert(t('payment_cancelled'));
                  }}
                  onError={() => {
                    alert(t('error_payment'));
                  }}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [{
                        amount: {
                          value: donation_value
                        }
                      }]
                    });
                  }}
                  onSuccess={paypalSuccessFunction}
                  options={{
                    clientId: process.env.REACT_APP_PAYPAL_TOKEN,
                    currency: process.env.REACT_APP_PAYPAL_CURRENCY
                  }}
                /> : null}
              </Grid>
            </Grid>
          </Expand>
        </Grid>
      </Grid>
    </WhiteContentBox>
  )
}

export default connect(
  state => {
    return state.user
  },
  { updateUser }
)(SingleProjectsBox);
