import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    color: '#857d9c',
    border: '1px solid #857d9c',
    borderRadius: 2,
    backgroundColor: 'none',
    padding: '14px 5px',
    width: '90%',
    height: '56px',
    maxWidth: '150px',
    marginRight: 5,
    marginLeft: 5,
    '&:hover': {
      backgroundColor: 'rgba(181,172,205,0.2)',
      border: '1px solid #e7deff',
      color: '#e7deff',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'none',
      },
    }
  }
}))(Button)