import axios from 'axios';
import GenericModel from './generic_model/GenericModel';
import Config from '../config/config';

// Other Services
import ActivityService from "./ActivityService";
import RewardService from "./RewardService";
import RewardPurchaseService from "./RewardPurchaseService";

export default class UserService extends GenericModel {

  API = '/users';
  static API = '/users';

  constructor(props) {
    super(props);
  }

  static async Login(identifier, password) {
    const {data : {
      jwt
    }} = await axios.post(Config.API_ADDRESS + '/auth/local', {
      identifier,
      password
    });
    if(jwt !== undefined) {
      localStorage.setItem('Authorization','Bearer ' + jwt);
    }
  }

  static isUserLoggedIn() {
    return (
      localStorage.getItem('Authorization') !== undefined &&
      localStorage.getItem('Authorization') !== null
    );
  }
  
  static Logout() {
    localStorage.removeItem('Authorization');
  }

  static async SignUp(userObj) {
    const {data} = await axios.post(Config.API_ADDRESS + '/auth/local/register', userObj);
    localStorage.setItem('Authorization','Bearer ' + data.jwt);
    return data.user;
  }

  static async me() {
    const {data} = await axios.get(Config.API_ADDRESS + '/users/me',{
      headers: GenericModel.insertAuthHeader()
    });
  
    try {
      const ans = await axios.get(Config.API_ADDRESS + '/profile_picture',{
        headers: GenericModel.insertAuthHeader()
      });
      data.profile_image = ans.data;
    } catch(err) {
      data.profile_image = undefined;
      console.warn(err);
    }
    
    try {
      let id_user = data.id;
  
      const activities = await ActivityService.query({'user.id': id_user});
  
      const reward_purchases = await RewardPurchaseService.query({'user.id': id_user, 'purchased_with_cash': false});
      const points_spent = reward_purchases.reduce((acc, val) => acc + (val.reward ? val.reward.reward_points : 0), 0)
      
      data.user_points = activities.reduce((acc, val) => acc + val.activity_points, 0);
      data.user_redeemable_points = data.user_points - points_spent;
      data.user_total_donations = activities.reduce((acc, val) => acc + (val.activity_value ? val.activity_value : 0), 0);
    } catch(err) {
      data.user_points = 0;
      data.user_redeemable_points = 0;
      data.user_total_donations = 0;
      console.warn(err);
    }

    return data;
  }
  
  static async update(id_user, fields) {
    
    const {data} = await axios.put(Config.API_ADDRESS + '/users/' + id_user,fields,{
      headers: GenericModel.insertAuthHeader()
    });
    
    return data;
  }
  
  static async top5Users() {
    
    const {data} = await axios.get(Config.API_ADDRESS + '/stats/top5users',{
      headers: GenericModel.insertAuthHeader()
    });
    
    return data;
  }
  
  static async top5Networks() {
    
    const {data} = await axios.get(Config.API_ADDRESS + '/stats/top5network',{
      headers: GenericModel.insertAuthHeader()
    });
    
    return data;
  }
  
  static async getUserNetwork(id_user) {
    
    const {data} = await axios.get(Config.API_ADDRESS + `/stats/my-network`,{
      headers: GenericModel.insertAuthHeader()
    });
    
    return data;
  }

  static async checkReferenceCode (id_user) {
    try {
      const { data } = await axios.get(Config.API_ADDRESS + '/signup/get-reference-code/' + id_user);
      return data;
    } catch (err) {
      if(err && err.response && err.response.data) {
        throw (err.response.data);
      } else {
        throw (err);
      }
    }
  }
  
  static async changeProfileImage (user, file) {
    const formData = new FormData();
    formData.append('files',file);
    const {data} = await axios.post(`${Config.API_ADDRESS}/upload`,formData, {
      headers: GenericModel.insertAuthHeader({
        'content-type': 'multipart/form-data'
      })
    });
    const image = data.pop();
    await axios.put(`${Config.API_ADDRESS}/users/${user.id}`,{profile_image: image.id}, {
      headers: GenericModel.insertAuthHeader()
    });
  }
}
