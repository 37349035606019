// Libraries
import React, {useState} from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Components
import ButtonSocial from "../Buttons/ButtonSocial";

// Images
import wildGoalsLogo from '../../assets/logo.png';
import {useTranslation} from "react-i18next";
import AboutUsService from "../../services/AboutUsService";
import SocialLinksService from "../../services/SocialLinksService";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    padding: 56,
    margin: '100px 0px 100px 0px'
  },
  divider: {
    width: '100%',
    borderBottom: '1px solid rgba(133,125,156,0.4)'
  }
}));

export default function Footer(props){
  
  const [t, i18n] = useTranslation('footer');
  
  const classes = useStyles();

  const justCopyright = props.just_copyright;
  
  const [socialLinksLoaded, setSocialLinksLoaded] = useState(false);
  const [socialLinks, setSocialLinks] = useState({});
  if(!socialLinksLoaded) {
    setSocialLinksLoaded(true);
    SocialLinksService.query().then(setSocialLinks);
  }
  
  const redirect = (link) => () => {
    window.open(link, '_blank');
  }
  
  return (
    <>
      <Hidden smDown>
        <Grid container xs={12} style={{padding: 40}}>
          <Grid xs={1}/>
          <Grid xs={justCopyright ? 10 : 3} style={{textAlign: justCopyright ? 'center' : 'left',display: 'flex', flexDirection: 'column'}}>
            <div style={{flex: 2}}/>
            <div style={{flex: 1, fontSize: 15, color: '#857d9c'}}>
              &copy; 2020 - WildGoals.com. {t('rights')}
            </div>
            <div style={{flex: 1, fontSize: 15}}>
              <a href={'/WILDGOALS%20TOS.pdf'} target={'_blank'} style={{color: 'white'}}>{t('toc')}</a>
              <a href={'/WILDGOALS%20PRIVACY%20POLICY.pdf'} target={'_blank'} style={{paddingLeft: '10px', color: 'white'}}>{t('pp')}</a>
              {justCopyright ?
                <a href={'#'} style={{paddingLeft: '10px', color: 'white'}} onClick={() => {
                  if(props.history) {
                    props.history.push('/about-us')
                  }
                }}>{t('about_us')}</a> : null
              }
            </div>
            <div style={{flex: 2}}/>
          </Grid>
          {!justCopyright ?
            <Grid item xs={4} style={{textAlign: 'center'}}>
              <img src={wildGoalsLogo} alt={'logo'}/>
            </Grid> : null
          }
          {!justCopyright ?
            <Grid item xs={3} style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{flex: 2, position: 'relative'}}>
                <div style={{
                  width: '100%',
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  marginTop: '-23px'
                }}>
                  {socialLinks.youtube_link ?
                    <ButtonSocial
                      onClick={redirect(socialLinks.youtube_link)}
                      style={{float: 'right', marginLeft: 10}}
                      type={'youtube'}
                    /> : null
                  }
                  {socialLinks.twitter_link ?
                    <ButtonSocial
                      onClick={redirect(socialLinks.twitter_link)}
                      style={{float: 'right', marginLeft: 10}}
                      type={'twitter'}
                    /> : null
                  }
                  {socialLinks.facebook_link ?
                    <ButtonSocial
                      onClick={redirect(socialLinks.facebook_link)}
                      style={{float: 'right', marginLeft: 10}}
                      type={'face'}
                    /> : null
                  }
                </div>
              </div>
              <div style={{flex: 2, fontSize: 16, position: 'relative'}}>
                <div style={{
                  width: '100%',
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  marginTop: '-11px'
                }}>
                  <a href={'#'} style={{paddingLeft: '20px', color: 'white', float: 'right'}} onClick={() => {
                    if(props.history) {
                      props.history.push('/contact-us')
                    }
                  }}>{t('contact_us')}</a>
                  <a href={'#'} style={{color: 'white', float: 'right'}} onClick={() => {
                    if(props.history) {
                      props.history.push('/about-us')
                    }
                  }}>{t('about_us')}</a>
                </div>
              </div>
            </Grid> : null
          }
          <Grid item xs={1}/>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container style={{padding: 40}}>
          {!justCopyright ?
            <Grid item xs={12} style={{textAlign: 'center', paddingBottom: '30px'}}>
              <img src={wildGoalsLogo} alt={'logo'} style={{width: '100px'}}/>
            </Grid> : null
          }
          {!justCopyright ?
            <Grid item xs={12} style={{textAlign: 'center', paddingBottom: '19px'}}>
              {socialLinks.facebook_link ?
                <ButtonSocial
                  onClick={redirect(socialLinks.facebook_link)}
                  type={'face'}
                /> : null
              }
              {socialLinks.twitter_link ?
                <ButtonSocial
                  onClick={redirect(socialLinks.twitter_link)}
                  style={{marginLeft: 10}}
                  type={'twitter'}
                /> : null
              }
              {socialLinks.youtube_link ?
                <ButtonSocial
                  onClick={redirect(socialLinks.youtube_link)}
                  style={{marginLeft: 10}}
                  type={'youtube'}
                /> : null
              }
            </Grid> : null
          }
          {!justCopyright ?
            <Grid item xs={12} style={{textAlign: 'center',fontSize: 16, paddingBottom: '18px'}}>
              <a href={'#'} style={{color: 'white'}} onClick={() => {
                if(props.history) {
                  props.history.push('/about-us')
                }
              }}>{t('about_us')}</a>
              <a href={'#'} style={{paddingLeft: '20px', color: 'white'}} onClick={() => {
                if(props.history) {
                  props.history.push('/contact-us')
                }
              }}>{t('contact_us')}</a>
            </Grid> : null
          }
          {!justCopyright ?
            <div className={classes.divider}/>
            : null
          }
          
          <Grid item xs={12} style={{fontSize: 15, color: '#857d9c', textAlign: 'center', paddingTop: '18px', paddingBottom: '10px'}}>
            &copy; 2020 - WildGoals.com. {t('rights')}
          </Grid>
          <Grid item xs={12} style={{fontSize: 15, textAlign: 'center'}}>
            <a href={'/WILDGOALS%20TOS.pdf'} target={'_blank'} style={{color: 'white'}}>{t('toc')}</a>
            <a href={'/WILDGOALS%20PRIVACY%20POLICY.pdf'} target={'_blank'} style={{paddingLeft: '10px', color: 'white'}}>{t('pp')}</a>
            {justCopyright ?
              <a href={'#'} style={{paddingLeft: '10px', color: 'white'}} onClick={() => {
                if(props.history) {
                  props.history.push('/about-us')
                }
              }}>{t('about_us')}</a> : null
            }
          </Grid>
        </Grid>
      </Hidden>
    </>
  )

}