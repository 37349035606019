import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    color: '#f83131',
    border: '1px solid #f83131',
    borderRadius: 2,
    backgroundColor: 'none',
    textTransform: 'none',
    padding: '14px 5px',
    width: '90%',
    marginRight: 5,
    marginLeft: 5,
    '&:hover': {
      backgroundColor: 'rgba(248,49,49,0.2)',
      border: '1px solid #f83131',
      color: '#f83131',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'none',
      },
    },
    '&.Mui-disabled':{
      border: '1px solid rgba(0, 0, 0, 0.26)',
    }
  }
}))(Button)