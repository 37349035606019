import React from "react";
import { Button, Hidden } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import { RiRefreshLine } from 'react-icons/ri';
import InviteService from "../../services/InviteService";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: "column",
    borderRadius: 2,
    border: 'solid 1px rgba(255,255,255,0.2)',
    '&.accepted': {
      backgroundColor: '#63a461'
    }
  },
  emailBox: {
    flex: 5,
    padding: 19,
    color: '#857d9c',
    fontSize: 16,
    textAlign: 'center',
    '&.accepted': {
      color: 'white'
    }
  },
  divider: {
    borderLeft: 'solid 1px rgba(255,255,255,0.2)',
    height: 30,
    marginTop: 15,
  },
  iconButton: {
    fontSize: 22,
    flex: 1,
    color: '#857d9c',
    padding: 15,
    borderRadius: 0
  },
  bottomLeftButton: {
    borderTop: 'solid 1px rgba(255,255,255,0.2)',
    borderRight: 'solid 1px rgba(255,255,255,0.2)'
  },
  bottomRightButton: {
    borderTop: 'solid 1px rgba(255,255,255,0.2)'
  }
}))

export default (props) => {
  
  const classes = useStyles();
  
  const email = props.invite && props.invite.invite_email ? props.invite.invite_email : null;
  const phone = props.invite && props.invite.invite_phone_number ? props.invite.invite_phone_number : null;
  const accepted = props.invite && props.invite.invite_accepted ? true : false;
  
  const resendInvite = async () => {
    try {
      await InviteService.resendInvite(props.invite.id);
      alert('Invitation was sent again.')
    } catch (err) {
      alert('Invitation already sent. You need to wait before sending again.')
    }
  }
  
  return (
    <div className={`${classes.root} ${accepted ? 'accepted' : ''}`}>
      <div style={{flex: 1, display: 'flex', flexDirection: "row"}}>
        <div className={`${classes.emailBox} ${accepted ? 'accepted' : ''}`}>
          {accepted ? 'ACCEPTED: ' : ''}{email ? email : phone}
        </div>
        {!accepted ?
          <>
            <div className={classes.divider}/>
            <Button className={classes.iconButton} onClick={resendInvite}>
              <RiRefreshLine/>
            </Button>
          </> :
          null
        }
      </div>
    </div>
  );
}