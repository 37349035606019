import React from 'react';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { Input } from '@material-ui/core';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > input': {
      textAlign: 'center',
      backgroundColor: '#fdfcfc',
      color: '#4a4a4a',
      fontWeight: 'bold',
      boxShadow: '0 15px 20px 0 rgba(238, 90, 56, 0.04)',
      padding: '16px',
      borderRadius: 2,
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: 40,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      }
    }
  },
}));

function correctDecimalNumber(number, decimals) {
  if(number) {
    number = number.replace(/[^0-9.]/g,'');
    if(number.length < (1+decimals)) {
      for (let i = number.length; i < (1+decimals); i++) {
        number = '0' + number;
      }
    }
  }
  
  if(!number || number === '' || number === '0') {
    number = '0'.repeat(1+decimals);
  }
  
  const numberLenght = number.length;
  return Number(number.substring(0,numberLenght-decimals)) + (decimals > 0 ? '.' : '') + number.substring(numberLenght-decimals);
}

export default function MoneyTextBox(props) {
  
  const [t, i18n] = useTranslation('money-text-box');
  
  let correctDecimal = correctDecimalNumber(props.value, 0);
  let displayValue = `$ ${correctDecimal}`;
  const classes = useStyles();
  
  if(!correctDecimal || Number(correctDecimal) === 0) {
    displayValue = '';
  }
  
  return (
    <Input
      className={classes.root}
      {...props}
      placeholder={t('amount')}
      value={displayValue}
      onChange={event => {
        event.target.value = correctDecimalNumber(event.target.value)
        props.onChange(event);
      }}
    >
    </Input>
  )
}