import React from "react";
import {Grid} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: 'column',
    borderRadius: 2,
    border: 'solid 1px #e5e5e5',
    height: '85px',
    '&.highlight': {
      border: 'solid 1px #63a461',
    },
    '&.alert': {
      border: 'solid 1px #e21818',
      backgroundColor: 'rgba(226,24,24,0.1)'
    }
  },
  value: {
    color: '#20183a',
    fontSize: '30px',
    textAlign: 'center',
    '&.highlight': {
      color: '#63a461',
    },
    '&.alert': {
      color: '#e21818',
      fontWeight: 'bolder'
    }
  },
  title: {
    color: '#4a4a4a',
    fontSize: '30px',
    textAlign: 'center',
    '&.highlight': {
      color: '#63a461',
    },
    '&.alert': {
      color: '#e21818',
      fontWeight: 'bold'
    }
  },
  spacer: {
    flex: 1
  }
}))

export default (props) => {
  
  const classes = useStyles();
  
  const getStyleClass = () => {
    if(props.alert) return 'alert'
    if(props.highlight) return 'highlight'
    return undefined;
  }
  
  return (
    <Grid {...props}>
      <div className={`${classes.container} ${getStyleClass()}`}>
        <div className={classes.spacer}/>
        <div className={`${classes.title} ${getStyleClass()}`}>{props.title}</div>
        <div className={`${classes.value} ${getStyleClass()}`}>${props.value}</div>
        <div className={classes.spacer}/>
      </div>
    </Grid>
  )
}