import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import { Button, Grid } from '@material-ui/core';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import Expand from "react-expand-animated";

// Images
import noPicture from '../../assets/no-picture.png';
import zebra from '../../assets/background-zebra.svg';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url('${zebra}')`,
    backgroundColor: '#18122b',
    color: 'white',
    boxShadow: '0 3px 10px 0 rgba(7, 7, 7, 0.3)',
    borderRadius: 2
  },
  divider: {
    borderBottom: '1px solid rgba(255,255,255,0.2)',
    width: '100%',
    marginBottom: 16
  },
  listElement: {
    backgroundColor: '#503e89',
    borderRadius: 2,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.15)',
    margin: '10px 18px 0px 18px',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    '& > div': {
      width: 40,
      height: 40,
      margin: 5
    },
    '& > div > img': {
      borderRadius: '100%',
      width: 40,
      height: 40,
      objectFit: 'contain',
    }
  }
}))

export default (props) => {
  
  const [t, i18n] = useTranslation('top-5-box');
  
  const classes = useStyles();
  
  const [open, setOpen] = useState(true);
  const transitions = ["height", "opacity", "background"];
  
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} style={{fontSize:24, padding: props.mobile ? 21 : 26}}>
        <span style={{fontWeight: 'bolder'}}>{t('top_5')}</span> {props.entity}
        {
          props.mobile ?
            <Button style={{
              float: 'right',
              color: 'white',
              height: 42,
              maxWidth: 42,
              minWidth: 42,
              borderRadius: 2,
              boxShadow: '0 6px 15px 0 rgba(0, 0, 0, 0.32)',
              backgroundImage: 'linear-gradient(316deg, #487a46 30%, #89bc65)'
            }} onClick={() => {
              setOpen(!open);
            }}> <AiFillCaretUp style={{
              transition: `transform 0.3s linear`,
              transform: `rotate(${open ? 0 : 180}deg)`
            }} />
            </Button> : null
        }
      </Grid>
      <Expand
        open={open}
        duration={300}
        transitions={transitions}
      >
        <div className={classes.divider}></div>
        <Grid item xs={12}>
          <Grid container style={{marginBottom: 18}}>
            {props.elements ?
              props.elements.map(obj => (
                <Grid item xs={12} className={classes.listElement}>
                  <div style={{background: "transparent"}}>
                    <img src={obj.profile_image ? obj.profile_image : noPicture}/>
                  </div>
                  <div style={{flex: 3, lineHeight: '40px', fontSize: 15, fontWeight: 'bold'}}>
                    {obj.username}
                  </div>
                  <div style={{flex: 2, lineHeight: '40px', fontSize: 15, textAlign: 'right'}}>
                    {obj.points ? obj.points : obj.value ? `$${obj.value}` : 0}
                  </div>
                </Grid>
              )) : null
            }
          </Grid>
        </Grid>
      </Expand>
    </Grid>
  )
}