import React from 'react';
import { Grid } from '@material-ui/core';
import SideContentBox from "./WhiteContentBox";

export default (props) => {
  return (
    <SideContentBox {...props}>
      <Grid container style={{padding: 26}}>
        <Grid xs={12} style={{textAlign: 'center'}}>
          <img src={props.img}/>
        </Grid>
        <Grid xs={12} style={{textAlign: 'center', fontSize: 20, marginTop: 10}}>
          {props.children}
        </Grid>
      </Grid>
    </SideContentBox>
  )
}