// Libraries
import React, {useEffect, useRef} from 'react';
import {Grid, Hidden} from '@material-ui/core';
import { connect } from "react-redux";

// Actions (REDUX)
import { updateUser } from "../../redux/actions";

// Components
import Header from '../../components/Header/Header';
import SideIconTextBox from "../../components/ContentBox/SideIconTextBox";
import GreenContentBox from "../../components/ContentBox/GreenContentBox";
import GreenTimer from "../../components/Displays/GreenTimer";
import ButtonConfirm from "../../components/Buttons/ButtonConfirm";

// Images
import threeStars from '../../assets/threeStars.svg';
import coinWithStar from '../../assets/coinWithStar.svg';
import giftImage from '../../assets/giftImage.svg';
import Footer from "../../components/Footer/Footer";
import moment from "moment";
import {useTranslation} from "react-i18next";

function useInterval(callback, delay) {
  const savedCallback = useRef();
  
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const FirstIndication = (props) => {
  
  const [t, i18n] = useTranslation('first-indication');
  
  const confirmClicked = () => {
    props.history.push('/send-indication')
  }
  
  useInterval(() => {
    if(props.user && props.user.add_member_expiration) {
      let addExpirationTime = moment(props.user.add_member_expiration, 'YYYY-MM-DDTHH:mm:ss').valueOf();
      if(addExpirationTime < (new Date()).getTime()) {
        props.history.push({
          pathname: '/time-over',
          state: {
            action: t('add_new_member'),
            hours: '48'
          }
        });
      }
    }
  }, 1000);
  
  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header
          history={props.history}
        />
      </Grid>
      <Grid item xs={12} style={{textAlign: 'center', marginBottom: 37}}>
        <span style={{color: '#63a461',fontWeight: 'lighter', fontSize: 26}}>
          {t('dear')} {props.user.user_full_name}
        </span><br/>
        <span style={{color: 'white',fontWeight: 'lighter', fontSize: 26}}>
          {t('thanks_pledge')}
        </span>
      </Grid>
      <Hidden smDown>
        <Grid item xs={3}>
          <SideIconTextBox img={threeStars} style={{maxWidth: '80%', margin: 'auto'}}>
            {t('increase_rewards')} <span style={{
            color: '#63a461',
            fontWeight: 'bold'
          }}>
            48 {t('hrs')}
          </span> {t('after_donation')}
          </SideIconTextBox>
        </Grid>
      </Hidden>
      <Grid item xs={12} md={6}>
        <Grid container style={{textAlign: 'center'}}>
          <GreenContentBox item xs={12} style={{padding: 50, maxWidth: '80%', margin: '0 auto 40px auto'}}>
            <Grid container>
              <Grid item xs={12} style={{fontSize:36, fontWeight: 'lighter', paddingBottom: 21}}>
                {t('add_a')} <span style={{fontWeight: 'bolder'}}>{t('new_member')}</span> {t('in')}
              </Grid>
              <Grid item xs={12}>
                <GreenTimer deadline={props.user.add_member_expiration}/>
              </Grid>
            </Grid>
          </GreenContentBox>
          <Grid item xs={12} style={{
            textAlign: 'center',
            color: 'white',
            fontWeight: 'lighter',
            fontSize: 26,
            paddingBottom: 46,
            maxWidth: '90%',
            margin: 'auto'
          }}>
            {t('to_participate')} <span style={{fontWeight: 'bolder'}}>{t('rewards_program')}</span><br/>
            {t('begin_earning')}
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center', paddingBottom: 50}}>
            <ButtonConfirm style={{width: '80%', maxWidth:'300px'}} onClick={confirmClicked}>
              {t('start_now')}
            </ButtonConfirm>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container>
          <Hidden mdUp>
            <SideIconTextBox img={threeStars} style={{maxWidth: '80%', margin: '0 auto 10px auto'}}>
              {t('increase_rewards')} <span style={{
                color: '#63a461',
                fontWeight: 'bold'
              }}>
                48 {t('hrs')}
              </span> {t('after_donation')}
            </SideIconTextBox>
          </Hidden>
          <SideIconTextBox item xs={12} img={coinWithStar} style={{maxWidth: '80%', margin: '0 auto 10px auto'}}>
            {t('earn_points')}
          </SideIconTextBox>
          <SideIconTextBox item xs={12} img={giftImage} style={{maxWidth: '80%', margin: '0 auto 10px auto'}}>
            {t('use_points')}
          </SideIconTextBox>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer history={props.history} just_copyright={true}/>
      </Grid>
    </Grid>
  );
}

export default connect(
  state => {
    return {
      user: state.user.user
    }
  },
  { updateUser }
)(FirstIndication);