import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import { FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 45,
    minWidth: 45,
    height: 45,
    borderRadius: '100%',
    border: 'solid 2px #ffffff',
    color: 'white',
    fontSize: 21
  }
}))

export default (props) => {
  const classes = useStyles();
  
  return (
    <Button className={classes.root} {...props}>
      {props.type === 'youtube' ? <FaYoutube/> :
        props.type === 'twitter' ? <FaTwitter/> :
        <FaFacebookF/>
      }
    </Button>
  )
}