// Libraries
import React, {useState} from 'react';
import { FormControl, Grid } from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";
import qs from 'qs';
import moment from 'moment';
import ReactHtmlParser from "react-html-parser";
import {useTranslation} from "react-i18next";

// Components
import ButtonConfirm from '../../components/Buttons/ButtonConfirm';
import CheckboxCustom from '../../components/Inputs/CheckboxCustom';
import ContentBox from '../../components/ContentBox/MainContentBox';
import CustomPhoneInput from "../../components/Inputs/CustomPhoneInput";
import Footer from "../../components/Footer/Footer";
import Header from '../../components/Header/Header';
import TextBox from '../../components/Inputs/TextBox';

// Services
import AboutUsService from "../../services/AboutUsService";
import InviteService from "../../services/InviteService";
import UserService from '../../services/UserService';

// Helpers
import Utils from '../../helpers/utils';

// Images
import whiteHands from "../../assets/whiteHands.svg";

// Classes
const useStyles = makeStyles(theme => ({
  imgHeader: {
    position:"absolute",
      top: 0,
      right: 0,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      height: '100%'
    }
  },
  imgHeaderOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top:0,
    right: 0,
    zIndex: 5,
    color: 'rgba(73,56,127,0.2)',
    backgroundImage: 'linear-gradient(to right, #49387F 60%, rgba(73,56,127,0.2) 90%)',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'linear-gradient(rgba(73,56,127,0.2) 10%, #49387F 50%)',
    },
  },
  textOverHeader: {
    marginTop: 50,
      marginBottom: 50,
      zIndex: 100,
      [theme.breakpoints.down('sm')]: {
      marginTop: 180,
        marginBottom: 20,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 50,
      marginBottom: 20,
    },
  },
}));

export default function SignUp(props) {
  
  const classes = useStyles();
  const [t, i18n] = useTranslation('sign-up');

  // ERROR HANDLING
  const [error_message, setErrorMessage] = useState(undefined);
  const [error_required_missing, setErrorRequiredMissing] = useState(false);
  const [error_email_invalid, setErrorEmailInvalid] = useState(false);
  const [error_phone_invalid, setErrorPhoneInvalid] = useState(false);
  const [error_password_invalid, setErrorPasswordInvalid] = useState(false);
  const [error_password_not_match, setErrorPasswordNotMatch] = useState(false);

  const [username, setUserName] = useState('');
  const [user_full_name, setUserFullName] = useState('');
  const [email, setUserEmail] = useState('');
  const [user_phone, setUserPhone] = useState('');
  const [user_question_reference, setUserQuestionReference] = useState('');
  const [password, setUserPassword] = useState('');
  const [user_confirm_password, setUserConfirmPassword] = useState('');
  const [terms_accepted, setTermsAccepted] = useState('');

  const [id_user_reference, setIdUserReference] = useState('');
  const [user_reference_name, setUserReferenceName] = useState('');
  const [user_search_counter, setUserSearchCounter] = useState(0);
  
  const [aboutUsLoaded, setAboutUsLoaded] = useState(false);
  const [aboutUsContent, setAboutUsContent] = useState({});
  if(!aboutUsLoaded) {
    setAboutUsLoaded(true);
    AboutUsService.query().then(aboutUsObject => {
      setAboutUsContent(aboutUsObject);
    });
  }
  
  const reference_code = qs.parse(props.location.search, { ignoreQueryPrefix: true }).reference_code;

  // If no reference_code is provided, redirects to Reference Code page
  if(!reference_code) {
    props.history.push('/');
  }

  if(reference_code && !user_search_counter) {
    // Avoids multiple calling
    setUserSearchCounter(1);

    InviteService.query({invite_code:reference_code}).then(inviteList => {
      if(inviteList.length > 0) {
        let inviteObj = inviteList.pop();
        let userObj = inviteObj.user;
        setIdUserReference(userObj.id);
        setUserReferenceName(userObj.username);
      }
    }).catch(err => {
      console.error(err);
      // If no reference_code is provided, redirects to Reference Code page
      props.history.push('/');
    })
  }

  const signUpFunction = async (event) => {

    event.preventDefault();
    
    // Reset all errors before check form
    setErrorRequiredMissing(false);
    setErrorEmailInvalid(false);
    setErrorPhoneInvalid(false);
    setErrorPasswordInvalid(false);
    setErrorPasswordNotMatch(false);
    setErrorMessage(undefined);

    // check all fields are filled
    if(
      !username ||
      !user_full_name ||
      !email ||
      !user_phone ||
      !user_question_reference ||
      !password ||
      !user_confirm_password
    ) {
      setErrorRequiredMissing(true);
      return setErrorMessage(t('missing_required'));
    }

    // validate email
    if(!Utils.validateEmail(email)) {
      setErrorEmailInvalid(true);
      return setErrorMessage(t('invalid_email'));
    }

    // validate phone number (Doesn't work in several countries)
    // if(!isValidPhoneNumber(email)) {
    //   setErrorPhoneInvalid(true);
    //   return setErrorMessage('Phone Number is invalid');
    // }

    // validate if password is strong
    if(!Utils.validatePassword(password)) {
      setErrorPasswordInvalid(true);
      return setErrorMessage(t('invalid_password'));
    }

    // validade that passwords are the same
    if(password !== user_confirm_password) {
      setErrorPasswordNotMatch(true);
      return setErrorMessage(t('password_not_match'));
    }

    // validate that terms are accepted
    if(!terms_accepted) {
      return setErrorMessage(t('accept_toc'));
    }

    // Call API
    try {
      const userObj = await UserService.SignUp({
        username,
        user_full_name,
        email,
        user_phone,
        user_question_reference,
        password,
        terms_accepted,
        reference_code,
        donate_expiration: moment().add(120,'minutes').format('YYYY-MM-DDTHH:mm:ss.ZZZ'),
        user_inviter: {
          id: id_user_reference
        }
      });
    } catch (err) {
      setErrorMessage(t('error_sending_user'));
      // TODO Treat errors
      console.error(err);
      return;
    }

    // Redirects to Confirm E-mail page
    props.history.push("/confirm-email");
  };

  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header
          enableLogin
          history={props.history}
        />
      </Grid>
      <Grid item xs={false} sm={2}/>
      <ContentBox item xs={12} sm={8} style={{
        margin: '0px 15px 100px 15px',
        padding: 26
      }}>
        <Grid container>
          <Grid item xs={12} style={{marginTop: 30, marginBottom: 36, textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 30, color: '#FFFFFF'}}>
              {t('getting_started')}
            </span>
          </Grid>
          <Grid item xs={12} style={{
            position: "relative",
            overflow: "hidden"
          }}>
            <Grid container>
              <Grid item xs={1}/>
              <Grid item xs={10} md={6} lg={4} className={classes.textOverHeader}>
                <Grid xs={12} style={{textAlign: 'center', marginBottom: 30}}>
                  <img src={whiteHands}/>
                </Grid>
                <Grid xs={12} style={{
                  color: 'white',
                  textAlign: 'center',
                  fontSize: 22
                }}>
                  {ReactHtmlParser(aboutUsContent[`about_us_header${Utils.getI18nSuffix(i18n)}`])}
                </Grid>
              </Grid>
              <img
                src={aboutUsContent.about_us_header_photo ? aboutUsContent.about_us_header_photo.url : null}
                className={classes.imgHeader}
              />
              <div className={classes.imgHeaderOverlay}/>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginBottom: 20, textAlign: 'center'}}>
            <span style={{margin: 'auto', fontSize: 20, color: '#FF0000'}}>
              {error_message}
            </span>
          </Grid>
          <form onSubmit={signUpFunction} style={{width: '100%'}}>
            <Grid item xs={12} style={{marginBottom: 56}}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextBox
                    placeholder={t('username')}
                    required
                    error={(error_required_missing && (!username || username === ''))}
                    tabIndex={1}
                    value={username}
                    onChange={event => {
                      setUserName(event.target.value);
                    }}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextBox
                    placeholder={t('full_name')}
                    required
                    error={(error_required_missing && (!user_full_name || user_full_name === ''))}
                    tabIndex={2}
                    value={user_full_name}
                    onChange={event => {
                      setUserFullName(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomPhoneInput
                    placeholder={t('phone')}
                    required
                    error={(error_required_missing && (!user_phone || user_phone === '')) || error_phone_invalid}
                    value={user_phone}
                    onChange={setUserPhone}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextBox
                    placeholder={t('email')}
                    type={"email"}
                    tabIndex={4}
                    required
                    error={(error_required_missing && (!email || email === '')) || error_email_invalid}
                    value={email}
                    onChange={event => {
                      setUserEmail(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 56}}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextBox
                    placeholder={`${t('where_meet')} ${user_reference_name}?`}
                    tabIndex={6}
                    error={(error_required_missing && (!user_question_reference || user_question_reference === ''))}
                    value={user_question_reference}
                    onChange={event => {
                      setUserQuestionReference(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 20}}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextBox
                    placeholder={t('password')}
                    type={'password'}
                    tabIndex={7}
                    required
                    error={(error_required_missing && (!password || password === '')) || error_password_invalid || error_password_not_match}
                    value={password}
                    onChange={event => {
                      setUserPassword(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextBox
                    placeholder={t('confirm_password')}
                    type={'password'}
                    tabIndex={8}
                    required
                    error={error_password_invalid || error_password_not_match}
                    value={user_confirm_password}
                    onChange={event => {
                      setUserConfirmPassword(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} lg={6} style={{margin: 'auto', display: 'flex', flexDirection: 'row', marginBottom: 50}}>
              <div style={{flex: 1, textAlign: 'right'}}>
                <CheckboxCustom
                  id={'checkbox-id'}
                  style={{marginLeft: '5px', float: 'right'}}
                  labelStyle={{zIndex: 3}}
                  label={(
                    <label htmlFor={'checkbox-id'}>
                      {t('agree')}&nbsp;
                      <a href={'/WILDGOALS%20TOS.pdf'} target={'_blank'} style={{color: 'white'}}>{t('toc')}</a>{t('and')}&nbsp;
                      <a href={'/WILDGOALS%20PRIVACY%20POLICY.pdf'} target={'_blank'} style={{color: 'white'}}>{t('pp')}</a>
                      {/*{t('and')}&nbsp;<a href={'/Privacy%20Shield%20Privacy%20Policy.pdf'} target={'_blank'} style={{color: 'white'}}>{t('privacy_shield')}</a>*/}
                    </label>
                  )}
                  required
                  error={(error_required_missing && (!terms_accepted || terms_accepted === ''))}
                  checked={terms_accepted}
                  onChange={event => {
                    setTermsAccepted(event.target.checked);
                  }}
                />
              </div>
              {/*<div style={{lineHeight: '40px', flex: 4, fontSize: 17, color: 'white'}}>*/}
              {/*  &nbsp;*/}
              {/*  <br/>*/}
              {/*</div>*/}
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}}>
              <span style={{margin: 'auto', fontSize: 20, color: '#FF0000'}}>
                {error_message}
              </span>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 30}}>
              <ButtonConfirm
                style={{width: '100%'}}
                type={'submit'}>
                {t('join_now')}
              </ButtonConfirm>
            </Grid>
            <Grid item xs={12} className={classes.spaceFromTop} style={{
              position: "relative",
              overflow: 'hidden'
            }}>
              <Grid container>
                <Grid item xs={2}/>
                <Grid item xs={8} md={5} lg={3} className={classes.textOverHeader}>
                  <Grid xs={12} style={{
                    color: 'white',
                    textAlign: 'center',
                    fontSize: 22
                  }}>
                    {ReactHtmlParser(aboutUsContent[`about_us_footer${Utils.getI18nSuffix(i18n)}`])}
                  </Grid>
                </Grid>
              </Grid>
              <img src={aboutUsContent.about_us_footer_photo ? aboutUsContent.about_us_footer_photo.url : null} className={classes.imgHeader}/>
              <div className={classes.imgHeaderOverlay}/>
            </Grid>
          </form>
        </Grid>
      </ContentBox>
      <Grid item xs={false} sm={2}/>
      <Grid item xs={12}/>
      <Grid item xs={12}>
        <Footer history={props.history} just_copyright={true}/>
      </Grid>
    </Grid>
  );
}
