import React, {useState} from 'react';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, Grid, Input } from '@material-ui/core';
import { GoSearch } from 'react-icons/go';
import { RiCloseLine, RiFilter3Line } from 'react-icons/ri';
import { AiOutlineClear } from 'react-icons/ai';

const useStyles = makeStyles(theme => ({
  textInput: {
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '&.Mui-error > input': {
      backgroundColor: '#ffcacf !important',
      '&::placeholder': {
        color: '#FF0000 !important',
        opacity: 1
      },
    },
    '& > input': {
      backgroundColor: '#fdfcfc',
      color: '#503e89',
      boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.18)',
      padding: '20px',
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: 20,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      }
    }
  },
  container: {
    position: "relative"
  },
  searchIcon: {
    position: "absolute",
    top: 20,
    right: 145,
    width: 25,
    height: 25,
    color: '#f83131'
  },
  buttonFilter: {
    boxShadow: '0 6px 8px 0 rgba(0, 0, 0, 0.18)',
    backgroundImage: 'linear-gradient(328deg, #487a46 60%, #89bc65)',
    position: 'absolute',
    top: 0,
    right: 64,
    height: '100%',
    borderRadius: '0px',
    color: 'white',
    '&:hover': {
      backgroundImage: 'linear-gradient(328deg, #487a46 20%, #89bc65)',
    }
  },
  buttonClearFilter: {
    boxShadow: '0 6px 8px 0 rgba(0, 0, 0, 0.18)',
    backgroundImage: 'linear-gradient(328deg, #bd0008 60%, #f83031)',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    borderRadius: '0px',
    color: 'white',
    '&:hover': {
      backgroundImage: 'linear-gradient(328deg, #bd0008 0%, #f83031)',
    }
  },
  filterIcon: {
    fontSize: 30,
    color: 'white'
  }
}));

export default function TextBox(props) {

  const classes = useStyles();

  const [isOpened, setIsOpened] = useState(false);
  
  const openFilter = props.openFilter ? props.openFilter : () => {};
  const clearFilter = props.clearFilter ? props.clearFilter : () => {};
  
  return (
    <Grid class={classes.container}>
      <Input
        className={classes.textInput}
        {...props}
        placeholder={props.placeholder + (props.required ? ' *' : '')}
      />
      <GoSearch class={classes.searchIcon}/>
      <Button className={classes.buttonFilter} onClick={() => {
        setIsOpened(!isOpened);
        openFilter();
      }}>
        {isOpened ?
          <RiCloseLine className={classes.filterIcon}/> :
          <RiFilter3Line className={classes.filterIcon}/>
        }
      </Button>
      <Button className={classes.buttonClearFilter} onClick={() => {
        clearFilter();
      }}>
        <AiOutlineClear className={classes.filterIcon}/>
      </Button>
    </Grid>
  )
}