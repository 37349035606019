import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import {Rating} from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 2,
    border: 'solid 1px #e5e5e5',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '34px 50px'
  },
  user_image: {
    flex: 1,
    '& > img': {
      width: 60,
      height: 60,
      borderRadius: 30
    }
  },
  user_stars: {
    marginTop: 22,
    flex: 1
  },
  user_comment : {
    marginTop: 18,
    flex: 1
  },
  username: {
    marginTop: 18,
    flex: 1
  }
}));

export default (props) => {
  
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <div className={classes.user_image}>
        <img src={props.user_image}/>
      </div>
      <div className={classes.user_stars}>
        <Rating
          value={!isNaN(props.user_stars) ? Number(props.user_stars) : null}
          precision={0.5}
          emptyIcon={<StarBorderIcon fontSize="inherit"/>}
          readOnly
        />
      </div>
      <div className={classes.user_comment}>{props.user_comment}</div>
      <div className={classes.username}><b>{props.username}</b></div>
    </div>
  )
}