import React from "react";
import { Grid } from '@material-ui/core';

import WhiteContentBox from "./WhiteContentBox";
import {makeStyles} from "@material-ui/core/styles";
import ButtonRedAction from "../Buttons/ButtonRedAction";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  pointBall: {
    position: 'absolute',
    top: '13px',
    right: '13px',
    width: '37px',
    height: '37px',
    border: 'dotted 2px #bebebe',
    borderRadius: '100%',
    textAlign: 'center',
    lineHeight: '37px',
    fontSize: '15',
    color: '#63a461',
    fontWeight: 'bold'
  },
  imageGrid: {
    marginTop: '42px',
    textAlign: 'center',
    marginBottom: '40px',
    '& > img': {
      width: 67,
      height: 67,
      contentFit: 'contain'
    }
  },
  pointsGrid: {
    textAlign: 'center',
    marginBottom: '5px',
    fontSize: '26px',
    color: '#20183a'
  },
  titleGrid: {
    textAlign: 'center',
    marginBottom: '37px',
    fontSize: '16px',
    color: '#9b9b9b'
  },
  actionButtonGrid: {
    textAlign: 'center',
    padding: '20px 0px'
  },
  greenSpan: {
    color: '#63a461'
  },
  divider: {
    borderBottom: 'solid 1px #e5e5e5'
  }
}));

export default (props) => {
  
  const [t, i18n] = useTranslation('action-points-box');
  
  const classes = useStyles();
  
  return (
    <WhiteContentBox className={classes.root} {...props}>
      <Grid container>
        <Grid item xs={12} className={classes.imageGrid}>
          <img src={props.img}/>
        </Grid>
        <Grid item xs={12} className={classes.pointsGrid}>
          <span className={classes.greenSpan}>{props.customText ? props.customText : props.points}</span> {t('points')}
        </Grid>
        <Grid item xs={12} className={classes.titleGrid}>
          {props.title}
        </Grid>
        <Grid item xs={12} className={classes.divider}/>
        <Grid item xs={12} className={classes.actionButtonGrid}>
          <ButtonRedAction>
            {props.action}
          </ButtonRedAction>
        </Grid>
      </Grid>
      <div className={classes.pointBall}>
        {props.points}
      </div>
    </WhiteContentBox>
  )
}