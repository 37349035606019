import React, {useState} from "react";
import {Grid, Hidden} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import WhiteContentBox from "../../components/ContentBox/WhiteContentBox";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { useTranslation } from 'react-i18next';

import Utils from "../../helpers/utils";

// Images
import aboutUsHeader from '../../assets/about_us-header.jpeg';
import aboutUsFamily from '../../assets/about_us-1.jpeg';
import aboutUsHands from '../../assets/about_us-2.jpeg';
import aboutUsFooter from '../../assets/about-us-footer-only-photo.jpg';
import whiteHands from '../../assets/whiteHands.svg';
import ReactHtmlParser from "react-html-parser";
import AboutUsService from "../../services/AboutUsService";

const useStyles = makeStyles(theme => ({
  pageTitle: {
    fontSize: 36,
    color: 'white',
    textAlign: 'center',
    marginBottom: 44
  },
  pageContent: {
    position: 'relative',
    marginBottom: 150,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  whiteContentBox: {
    position: 'relative',
    zIndex: 100,
    width: '90%',
    margin: 'auto',
    padding: 46,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 20
    },
  },
  imgHeader: {
    position:"absolute",
    top: 0,
    right: 0,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      height: '100%'
    }
  },
  imgHeaderOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top:0,
    right: 0,
    zIndex: 5,
    backgroundImage: 'linear-gradient(to right, #1f183a 60%, rgba(31,24,58,0.2) 90%)',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'linear-gradient(rgba(31,24,58,0.2) 10%, #1f183a 70%)',
    },
  },
  imgFooterOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top:0,
    right: 0,
    backgroundImage: 'linear-gradient(to right, #37266a 60%, rgba(55,38,106,0.2) 90%)',
    zIndex: 5,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'linear-gradient(rgba(55,38,106,0.2) 10%, #37266a 70%)',
    },
  },
  textOverHeader: {
    marginTop: 50,
    marginBottom: 50,
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 180,
      marginBottom: 20,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
      marginBottom: 20,
    },
  },
  spaceFromTop: {
    marginTop: 60,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    },
  }
}));

export default withWidth()((props) => {
  
  const [t, i18n] = useTranslation('about-us');
  
  const classes = useStyles();
  
  const [aboutUsLoaded, setAboutUsLoaded] = useState(false);
  const [aboutUsContent, setAboutUsContent] = useState({});
  if(!aboutUsLoaded) {
    setAboutUsLoaded(true);
    AboutUsService.query().then(aboutUsObject => {
      setAboutUsContent(aboutUsObject);
    });
  }
  
  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header history={props.history}/>
      </Grid>
    
      <Grid item xs={12} className={classes.pageTitle}>
        {t('title')}
      </Grid>
    
      <Grid item xs={12} className={classes.pageContent}>
      
        <WhiteContentBox container className={classes.whiteContentBox}>
          <Grid item xs={12} style={{
            position: "relative",
            overflow: "hidden"
          }}>
            <Grid container>
              <Grid item xs={1}/>
              <Grid item xs={10} md={6} lg={4} className={classes.textOverHeader}>
                <Grid xs={12} style={{textAlign: 'center', marginBottom: 30}}>
                  <img src={whiteHands}/>
                </Grid>
                <Grid xs={12} style={{
                  color: 'white',
                  textAlign: 'center',
                  fontSize: 22
                }}>
                  {ReactHtmlParser(aboutUsContent[`about_us_header${Utils.getI18nSuffix(i18n)}`])}
                </Grid>
              </Grid>
              <img
                src={aboutUsContent.about_us_header_photo ? aboutUsContent.about_us_header_photo.url : null}
                className={classes.imgHeader}
              />
              <div className={classes.imgHeaderOverlay}/>
            </Grid>
          </Grid>
  
          <Grid item xs={12} className={classes.spaceFromTop}>
            <Grid container spacing={isWidthUp('md', props.width) ? 8 : 3}>
              <Grid item xs={12} md={6} style={{
                color: '#4a4a4a',
                fontSize: 16
              }}>
                {ReactHtmlParser(aboutUsContent[`about_us_body_1${Utils.getI18nSuffix(i18n)}`])}
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  src={aboutUsContent.about_us_body_1_photo ? aboutUsContent.about_us_body_1_photo.url : null}
                  style={{width: '100%'}}
                />
              </Grid>
            </Grid>
          </Grid>
  
          <Grid item xs={12} className={classes.spaceFromTop}>
            <Grid container spacing={isWidthUp('md', props.width) ? 8 : 3}>
              <Hidden smDown>
                <Grid item xs={6}>
                  <img
                    src={aboutUsContent.about_us_body_2_photo ? aboutUsContent.about_us_body_2_photo.url : null}
                    style={{width: '100%'}}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12} md={6} style={{
                color: '#4a4a4a',
                fontSize: 16
              }}>
                {ReactHtmlParser(aboutUsContent[`about_us_body_2${Utils.getI18nSuffix(i18n)}`])}
              </Grid>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <img
                    src={aboutUsContent.about_us_body_2_photo ? aboutUsContent.about_us_body_2_photo.url : null}
                    style={{width: '100%'}}
                  />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
  
          <Grid item xs={12} className={classes.spaceFromTop} style={{
            position: "relative",
            overflow: 'hidden'
          }}>
            <Grid container>
              <Grid item xs={2}/>
              <Grid item xs={8} md={5} lg={3} className={classes.textOverHeader}>
                <Grid xs={12} style={{
                  color: 'white',
                  textAlign: 'center',
                  fontSize: 22
                }}>
                  {ReactHtmlParser(aboutUsContent[`about_us_footer${Utils.getI18nSuffix(i18n)}`])}
                </Grid>
              </Grid>
            </Grid>
            <img src={aboutUsContent.about_us_footer_photo ? aboutUsContent.about_us_footer_photo.url : null} className={classes.imgHeader}/>
            <div className={classes.imgFooterOverlay}/>
          </Grid>
        </WhiteContentBox>
        
        {/*PURPLE BACKGROUND*/}
        <Hidden smDown>
          <div style={{height: '100%', width: '100%', position: 'absolute', top: 150, backgroundColor: '#463871', zIndex: 1}}/>
        </Hidden>
      </Grid>
    
      <Grid item xs={12}>
        <Footer history={props.history}/>
      </Grid>
    </Grid>
  );
})