import { UPDATE_INVITED_MEMBERS, UPDATE_INVITED_MEMBERS_ERROR } from "../actionTypes";

const initialState = {
  invited_list: [],
  invited_total: 0,
  invitedMembersError: undefined,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_INVITED_MEMBERS: {
      const invitedObj = action.payload;
      return {
        ...state,
        invited_list: invitedObj.invited_list,
        invited_total: invitedObj.invited_total
      };
    }
    case UPDATE_INVITED_MEMBERS_ERROR: {
      const err = action.error;
      return {
        ...state,
        invitedMembersError: err
      };
    }
    default:
      return state;
  }
}
