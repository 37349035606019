import React from "react";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    '& > input': {
      color: '#503e89',
      borderRadius: 2,
      boxShadow: '0 15px 20px 0 rgba(238, 90, 56, 0.04)',
      backgroundColor: '#fdfcfc',
      border: '1px solid #ced4da',
      padding: 18,
      fontSize: 20,
      transition: 'border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      '&::placeholder': {
        color: '#b5accb !important',
        opacity: 1
      }
    }
  }
}))

export default (props) => {
  
  const classes = useStyles();
  
  return (
    <PhoneInput className={classes.root} defaultCountry="US" {...props}/>
    //
  )
}