// Libraries
import React, {useState, useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {connect} from "react-redux";
import {updateInvitedMembers, updateUser} from "../../redux/actions";

// Components
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ProductBox from "../../components/ContentBox/ProductBox";
import SearchFilters from "../../components/Inputs/SearchFilters";

// Services
import RewardService from "../../services/RewardService";

// Images
import giftRewards from '../../assets/gift_rewards.svg';
import {useTranslation} from "react-i18next";
import Utils from "../../helpers/utils";

const Rewards = (props) => {
  
  const [t, i18n] = useTranslation('rewards');
  
  const [filters, setFilters] = useState({});
  const [rewardsList, setRewardsList] = useState([]);
  const [filterCalculated, setFilterCalculated] = useState(0);
  const [minValuePoints, setMinValuePoints] = useState(0);
  const [maxValuePoints, setMaxValuePoints] = useState(100000000000);
  const [searchRewards, setSearchRewards] = useState(false);
  
  const loadRewards = () => {
    let filterQuery = filters;
    Object.keys(filterQuery).forEach(key => filterQuery[key] === undefined ? delete filterQuery[key] : {});
    RewardService.query(filterQuery).then(rewardList => {
      setRewardsList(rewardList);
      if(!filterCalculated && rewardList.length > 0) {
        setFilterCalculated(1);
        let minPoints = 100000000000000;
        let maxPoints = 0;
  
        for (let i = 0; i < rewardList.length; i++) {
          let rewardObj = rewardList[i];
          if(rewardObj.reward_points < minPoints) {
            minPoints = rewardObj.reward_points;
          }
          if(rewardObj.reward_points > maxPoints) {
            maxPoints = rewardObj.reward_points;
          }
        }
        setMinValuePoints(minPoints);
        setMaxValuePoints(maxPoints);
      }
    }).catch(err => {
      console.error(err);
    });
  }
  
  if(!searchRewards) {
    setSearchRewards(true);
    loadRewards();
  }
  
  useEffect(() => {
    loadRewards();
  }, [filters])
  
  const onFavorite = (id_reward) => {
    RewardService.favorite(id_reward).then(() => {
      loadRewards();
    }).catch(err => {
      console.error(err);
    });
  }
  
  const redirectToRewardPage = (id_reward) => {
    props.history.push('/rewards/' + id_reward);
  }
  
  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header history={props.history}/>
      </Grid>
  
      <Grid item xs={12} style={{textAlign:'center', marginBottom: 10}}>
        <img src={giftRewards}/>
      </Grid>
  
      <Grid item xs={12} style={{fontSize:36, color: 'white', textAlign: 'center', marginBottom: 30}}>
        {t('title')}
      </Grid>
  
      <Grid item xs={12} style={{position: 'relative', marginBottom: 50}}>
        <Grid container style={{position: 'relative', zIndex: 100, width: '90%', margin: 'auto'}}>
          <Grid item xs={12} style={{marginBottom: 23}}>
            <SearchFilters
              minValuePoints={minValuePoints}
              maxValuePoints={maxValuePoints}
              onChangeFilters={({searchText, sliderLow, sliderHigh, gender, chosenColor}) => {
              let reward_name_contains = searchText;
              let reward_points_gte = sliderLow;
              let reward_points_lte = sliderHigh;
              let reward_gender = gender;
              let reward_color = chosenColor;
              
              setFilters({...filters, reward_name_contains, reward_points_lte, reward_points_gte, reward_gender, reward_color});
            }}/>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {rewardsList.map(obj => (
                <Grid item xs={12} sm={6} md={4}>
                  <ProductBox
                    is_favorite={obj.is_favorite}
                    id_reward={obj.id}
                    reward_image={obj.reward_image && obj.reward_image.length > 0 ? obj.reward_image[0].url : null}
                    reward_name={obj[`reward_name${Utils.getI18nSuffix(i18n)}`]}
                    reward_points={obj.reward_points}
                    disabled={obj.disabled}
                    reward_stars={obj.reward_stars}
                    reward_bought_number={obj.reward_bought_number}
                    onFavorite={onFavorite}
                    onClickReward={redirectToRewardPage}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
  
        <div style={{height: '100%', width: '100%', position: 'absolute', top: 50, backgroundColor: '#463871', zIndex: 1}}/>
      </Grid>
      
      <Grid item xs={12}>
        <Footer history={props.history}/>
      </Grid>
    </Grid>
  );
}

export default connect(
  state => {
    return {
      user: state.user.user,
      invited_list: state.invitedMembers.invited_list,
      invited_total: state.invitedMembers.invited_total
    }
  },
  { updateInvitedMembers, updateUser }
)(Rewards);