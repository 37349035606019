import React, {useState} from "react";
import {connect} from "react-redux";
import {updateUser} from "../../redux/actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Grid, Hidden} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import WhiteContentBox from "../../components/ContentBox/WhiteContentBox";
import TextArea from "../../components/Inputs/TextArea";
import ButtonConfirm from "../../components/Buttons/ButtonConfirm";
import {FaRegPaperPlane} from "react-icons/fa";
import ContactUsService from "../../services/ContactUsService";
import {useTranslation} from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import ButtonRedFilledAction from "../../components/Buttons/ButtonRedFilledAction";

const useStyles = makeStyles(theme => ({
  pageTitle: {
    fontSize: 36,
    color: 'white',
    textAlign: 'center',
    marginBottom: 44
  },
  pageContent: {
    position: 'relative',
    marginBottom: 70,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  whiteContentBox: {
    position: 'relative',
    zIndex: 100,
    width: '90%',
    margin: 'auto',
    padding: 46,
    backgroundColor: '#716891',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 20
    },
  },
  imgHeader: {
    position:"absolute",
    top: 0,
    right: 0,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      height: '100%'
    }
  },
  imgHeaderOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top:0,
    right: 0,
    zIndex: 5,
    backgroundImage: 'linear-gradient(to right, #1f183a 60%, rgba(31,24,58,0.2) 90%)',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'linear-gradient(rgba(31,24,58,0.2) 10%, #1f183a 70%)',
    },
  },
  imgFooterOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top:0,
    right: 0,
    backgroundImage: 'linear-gradient(to right, #37266a 60%, rgba(55,38,106,0.2) 90%)',
    zIndex: 5,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'linear-gradient(rgba(55,38,106,0.2) 10%, #37266a 70%)',
    },
  },
  textOverHeader: {
    marginTop: 50,
    marginBottom: 50,
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 180,
      marginBottom: 20,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
      marginBottom: 20,
    },
  },
  spaceFromTop: {
    marginTop: 60,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    },
  }
}));

const ContactUs = (props) => {
  
  const [t, i18n] = useTranslation('contact-us');
  
  const classes = useStyles();
  
  const [contact_us_message, setContactUsMessage] = useState('');
  
  const user = props.user ? props.user : {};
  const name = 'João';
  
  return (
    <Grid container style={{height: '100vh'}}>
      <Grid item xs={12}>
        <Header history={props.history}/>
      </Grid>
    
      <Grid item xs={12} className={classes.pageTitle}>
        {t('title')}
      </Grid>
    
      <Grid item xs={12} className={classes.pageContent}>
      
        <WhiteContentBox container className={classes.whiteContentBox}>
          <Grid item xs={12} style={{
            marginBottom: 50,
            color: 'white',
            fontSize: '20px',
            fontWeight: 'lighter'
          }}>
            {ReactHtmlParser(t('message',{username: (user.user_full_name ? user.user_full_name.split(' ')[0] : '')}))}
          </Grid>
  
          <Grid item xs={12} style={{marginBottom: 50}}>
            <TextArea
              placeholder={t('comments_placeholder')}
              value={contact_us_message}
              onChange={(event) => {
                setContactUsMessage(event.target.value);
              }}
            />
          </Grid>
  
          <Grid item xs={12} style={{marginBottom: 60, textAlign: 'center'}}>
            <ButtonRedFilledAction
              style={{width: '70%', minWidth: '270px', margin: 'auto'}}
              onClick={async () => {
                await (new ContactUsService({
                  user: props.user.id,
                  contact_us_message
                }).save());
                
                alert(t('successfull_message'));
                setContactUsMessage('');
              }}
            >
              {t('send_button')}
            </ButtonRedFilledAction>
          </Grid>
        </WhiteContentBox>
      
        {/*PURPLE BACKGROUND*/}
        <Hidden smDown>
          <div style={{height: '100%', width: '100%', position: 'absolute', top: 70, backgroundColor: '#463871', zIndex: 1}}/>
        </Hidden>
      </Grid>
    
      <Grid item xs={12}>
        <Footer history={props.history}/>
      </Grid>
    </Grid>
  )
}

export default connect(
  state => {
    return state.user
  },
  { updateUser }
)(ContactUs);