import React, {useState} from "react";
import { Button, Grid, Hidden } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

//Images
import noPicture from '../../assets/no-picture.png'
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root : {
    borderRadius: 2,
    boxShadow: '0 3px 10px 0 rgba(7, 7, 7, 0.3)',
    backgroundColor: 'white'
  },
  row : {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 15,
    borderRadius: 2,
    fontWeight: 'bold',
    borderBottom: '1px dashed #e5e5e5'
  },
  imgDiv: {
    position: 'relative',
    '& > img': {
      borderRadius: '100%',
      width: 40,
      height: 40,
      objectFit: 'contain',
      position: 'absolute',
      margin: 'auto',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  },
  header : {
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    backgroundImage: 'linear-gradient(275deg, #487a46 30%, #89bc65)'
  },
  tableHeader : {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
    color: 'white',
    backgroundImage: 'linear-gradient(275deg, #487a46 30%, #89bc65)',
    paddingBottom: '10px'
  },
  headerButton : {
    color: '#b2e19d',
    textTransform: 'none',
    padding: '18px 10px',
    '&.active': {
      color: 'white'
    }
  },
  divider: {
    borderRight:'1px solid #e5e5e5',
    height: '80%',
    margin: 'auto'
  }
}));

export default (props) => {
  
  const [t, i18n] = useTranslation('recent-activities-table');
  
  const classes = useStyles();
  
  const [typeFilter, setTypeFilter] = useState(undefined);
  
  // limits number of rows
  let rowsCounter = 0;
  
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.header}>
        <Button className={[classes.headerButton,(!typeFilter ? 'active' : undefined)]} onClick={() => {
          setTypeFilter(undefined);
        }}>
          {t('all_activities')}
        </Button>
        <Button className={[classes.headerButton,(typeFilter==='signup' ? 'active' : undefined)]} onClick={() => {
          setTypeFilter('signup');
        }}>
          {t('member_sign_up')}
        </Button>
        <Button className={[classes.headerButton,(typeFilter==='donation' ? 'active' : undefined)]} onClick={() => {
          setTypeFilter('donation');
        }}>
          {t('donations')}
        </Button>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} className={classes.tableHeader}>
          <div style={{flex:1}}/>
          <div style={{flex:4}}>{t('member')}</div>
          <div style={{flex:6}}>{t('activity')}</div>
          <div style={{flex:2, textAlign: 'center'}}>{t('action')}</div>
        </Grid>
      </Hidden>
      {props.elements ?
        props.elements.filter(obj => {
          if(!typeFilter || obj.activity === typeFilter) {
            rowsCounter++;
          }
          if(rowsCounter > 11) {
            return false;
          }
          return (!typeFilter || obj.activity === typeFilter)
        }).map(obj => (
          <>
            <Hidden smDown>
              <Grid item xs={12} className={classes.row}>
                <div style={{flex:1}} className={classes.imgDiv}>
                  <img src={obj.profile_image ? obj.profile_image : noPicture}/>
                </div>
                <div style={{flex:4, lineHeight: '72px', height: '72px', fontWeight: 'lighter'}}>
                  {obj.username && obj.username.substring(0,15)}{obj.username && obj.username.length > 15 ? '...' : ''}
                </div>
                <div style={{flex:6, lineHeight: '72px'}}>
                  {obj.activity==='signup' ? t('new_member_sign_up') :
                    obj.activity==='royalties' ? t('royalties') :
                      obj.activity==='donation' ? t('new_donation') + ': ' : null}
                  {obj.activity==='donation' ? <span style={{color: '#f83131'}}>
                    {obj.project}
                  </span> : null}
                </div>
                <div className={classes.divider}/>
                <div style={{
                  flex:2,
                  color: (obj.activity==='donation' ? '#503e89' : '#63a461'),
                  lineHeight: '72px',
                  textAlign: 'center'
                }}>
                  {obj.activity==='donation' ? `$${obj.value}` : `${obj.points} ${t('points')}`}
                </div>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={12} className={classes.row} style={{paddingTop: 10, paddingLeft: 10}}>
                <div style={{flex:2, display: 'flex', flexDirection: 'column'}}>
                  <div style={{flex:1}}>
                    {obj.activity==='signup' ? t('new_member_sign_up') :
                      obj.activity==='royalties' ? t('royalties') :
                        obj.activity==='donation' ? t('new_donation') + ': ' : null}
                    {obj.activity==='donation' ? <span style={{color: '#f83131'}}>
                    {obj.project}
                  </span> : null}
                  </div>
                  <div style={{flex: 1, display: 'flex', flexDirection: 'row', minHeight: '60px'}} >
                    <div style={{flex: 1, minHeight: '60px'}} className={classes.imgDiv}>
                      <img src={obj.profile_image ? obj.profile_image : noPicture}/>
                    </div>
                    <div style={{flex: 3, minHeight: '60px'}}>
                      <span style={{fontWeight: 'lighter', lineHeight: '60px'}}>
                        {obj.username && obj.username.substring(0,15)}{obj.username && obj.username.length > 15 ? '...' : ''}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={classes.divider}/>
                <div style={{flex:1, display: 'flex', flexDirection: 'column'}}>
                  <div style={{flex: 1}}/>
                  <div style={{
                    flex:1,
                    color: (obj.activity==='donation' ? '#503e89' : '#63a461'),
                    textAlign: 'center'
                  }}>
                    {obj.activity==='donation' ? `$${obj.value}` : `${obj.points} ${t('points')}`}
                  </div>
                  <div style={{flex: 1}}/>
                </div>
              </Grid>
            </Hidden>
          </>
        )) : null
      }
    </Grid>
  );
}
