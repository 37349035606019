import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';

import zebra from '../../assets/background-zebra.svg';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url('${zebra}')`,
    backgroundColor: '#18122b',
    color: 'white',
    boxShadow: '0 3px 10px 0 rgba(7, 7, 7, 0.3)',
    borderRadius: 2,
    padding: 25
  },
  myTotalDiv: {
    flex: 1,
    fontSize: 18,
    lineHeight: '30px'
  },
  donationsDiv: {
    flex: 1,
    fontSize: 24,
    lineHeight: '30px'
  },
  amountDiv: {
    flex: 1,
    fontSize: 30,
    lineHeight: '30px',
    textAlign: 'right'
  }
}))

export default (props) => {
  
  const [t, i18n] = useTranslation('total-donations-box');
  
  const classes = useStyles();
  
  return (
    <Grid container className={classes.root}>
      <Grid item xs={8} style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{flex: 1}}/>
        <div className={classes.myTotalDiv}>{t('my_total')}</div>
        <div className={classes.donationsDiv}>{t('donations')}</div>
        <div style={{flex: 1}}/>
      </Grid>
      <Grid item xs={4} style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{flex: 1}}/>
        <div className={classes.amountDiv}><span style={{color: 'rgba(255,255,255,0.3)'}}>$</span> {props.donations}</div>
        <div style={{flex: 1}}/>
      </Grid>
    </Grid>
  )
}