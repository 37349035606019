import {Grid} from "@material-ui/core";
import React, {useState} from "react";
import moment from "moment";

export default (props) => {
  
  const [timeleft, setTimeleft] = useState({hours: 0, minutes: 0, seconds: 0});
  
  let deadlineTime = props.deadline ? moment(props.deadline, 'YYYY-MM-DDTHH:mm:ss').valueOf() : 0;
  
  const putZero = (number) => {
    return (number < 10 ? '0' : '') + number;
  };
  
  setTimeout(() => {
    let nowTime = (new Date()).getTime();
    let diff = Math.max(deadlineTime - nowTime, 0);
  
    let newTimeleft = {
      hours: putZero(Math.floor(diff / (1000 * 60 * 60))),
      minutes: putZero(Math.floor(diff / (1000 * 60)) % 60),
      seconds: putZero(Math.floor(diff / 1000) % 60),
    }
  
    setTimeleft(newTimeleft);
  }, 1000)
  
  return (
    <Grid {...props}>
      <Grid style={{display: 'flex', flexDirection: 'row', paddingBottom: 8}}>
        <Grid style={{flex: 5}}>
          <span style={{fontSize: 16, opacity: 0.5}}>H</span>
        </Grid>
        <div style={{flex: 1}}>
        
        </div>
        <Grid style={{flex: 5}}>
          <span style={{fontSize: 16, opacity: 0.5}}>M</span>
        </Grid>
        <div style={{flex: 1}}>
        
        </div>
        <Grid style={{flex: 5}}>
          <span style={{fontSize: 16, opacity: 0.5}}>S</span>
        </Grid>
      </Grid>
      <Grid style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{flex: 5, height: '56px', borderRadius: 3, backgroundColor: '#89bc65'}}>
          <span style={{fontSize: '30px', lineHeight: '56px'}}>{timeleft.hours}</span>
        </div>
        <div style={{flex: 1}}>
          <span style={{fontSize: '30px', opacity: 0.5}}>:</span>
        </div>
        <div style={{flex: 5, height: '56px', borderRadius: 3, backgroundColor: '#89bc65'}}>
          <span style={{fontSize: '30px', lineHeight: '56px'}}>{timeleft.minutes}</span>
        </div>
        <div style={{flex: 1}}>
          <span style={{fontSize: '30px', opacity: 0.5}}>:</span>
        </div>
        <div style={{flex: 5, height: '56px', borderRadius: 3, backgroundColor: '#89bc65'}}>
          <span style={{fontSize: '30px', lineHeight: '56px'}}>{timeleft.seconds}</span>
        </div>
      </Grid>
    </Grid>
  )
}